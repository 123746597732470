// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// ** Icons Imports
import { HelpCircle, Link, MoreVertical } from "react-feather";

// ** User Components
import {
  Bookmarks,
  BuildingFill,
  ListNested,
  PeopleFill,
} from "react-bootstrap-icons";
import AgreementsTab from "./Tabs/AgreementsTab";
import TenantsTab from "./Tabs/TenanstTab";
import UnitsTab from "./Tabs/UnitsTab";
import Overview from "./Tabs/Overiew";
import Settings from "./Tabs/Settings";

const PropertyTabs = ({ active, toggleTab, property }) => {
  // themeConfig.serverUrl + row.photo

  return (
    <Fragment>
      <Nav tabs className="mb-2 bg-white">
        <NavItem>
          <NavLink active={active === "1"} onClick={() => toggleTab("1")}>
            <HelpCircle className="font-medium-1 me-50" />
            <span className="fw-bold">Overview</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "2"} onClick={() => toggleTab("2")}>
            <BuildingFill className="font-medium-1 me-50" />
            <span className="fw-bold">Units</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink active={active === "3"} onClick={() => toggleTab("3")}>
            <PeopleFill className="font-medium-1 me-50" />
            <span className="fw-bold">Tenants</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink active={active === "4"} onClick={() => toggleTab("4")}>
            <Link className="font-medium-1 me-50" />
            <span className="fw-bold">Agreements</span>
          </NavLink>
        </NavItem>

        {/* <NavItem>
          <NavLink active={active === "5"} onClick={() => toggleTab("5")}>
            <Bookmarks className="font-medium-1 me-50" />
            <span className="fw-bold">Bills</span>
          </NavLink>
        </NavItem> */}
        {/* <NavItem>
          <NavLink active={active === "6"} onClick={() => toggleTab("6")}>
            <ListNested className="font-medium-1 me-50" />
            <span className="fw-bold">Reports</span>
          </NavLink>
        </NavItem> */}

        <NavItem>
          <NavLink active={active === "7"} onClick={() => toggleTab("7")}>
            <MoreVertical className="font-medium-1 me-50" />
            <span className="fw-bold">Setting</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={active} className="bg-white">
        <TabPane tabId="1">
          <Overview />
        </TabPane>
        <TabPane tabId="2">
          <UnitsTab property={property} />
        </TabPane>
        <TabPane tabId="3">
          <TenantsTab property={property} />
        </TabPane>
        <TabPane tabId="4">
          <AgreementsTab property={property} />
        </TabPane>
        <TabPane tabId="5">
          <h1>Bills</h1>
        </TabPane>
        <TabPane tabId="6">
          <h1>Reports</h1>
        </TabPane>
        <TabPane tabId="7">
          <Settings />
        </TabPane>
      </TabContent>
    </Fragment>
  );
};
export default PropertyTabs;
