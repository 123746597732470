import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const jobsApi = createApi({
  reducerPath: "jobs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["jobs", "jobsList"],
  endpoints: (builder) => ({
    // Read
    getJobs: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/jobs?${serializedParams}`;
      },
      providesTags: ["jobs"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getJobsList: builder.query({
      query: () => {
        return `/hrm/jobs/list`;
      },
      providesTags: ["jobsList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createJob: builder.mutation({
      query: (data) => ({
        url: "/hrm/jobs",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["jobs", "jobsList"],
    }),

    // Update
    updateJob: builder.mutation({
      query: ({ data: jobs, id }) => ({
        url: `/hrm/jobs/${id}`,
        method: "PATCH",
        body: jobs,
      }),
      invalidatesTags: ["jobs", "jobsList"],
    }),

    // Delete
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `/hrm/jobs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["jobs", "jobsList"],
    }),
  }),
});

export const {
  useGetJobsListQuery,
  useGetJobsQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} = jobsApi;
