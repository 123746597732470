// ** React Imports
import { useEffect } from "react";

// ** Reactstrap Imports
import { Row, Col, Table } from "reactstrap";

// ** Styles
import "@styles/base/pages/app-invoice-print.scss";

import Preview from "./preview";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getCustomerDetail,
  getCustomerStatement,
} from "../../../redux/customer";

const PrintStatement = () => {
  const store = useSelector((state) => state.customers.customerDetail);
  console.log(store);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ** Print on mount
  useEffect(() => {
    setTimeout(() => window.print(), 50);
  }, []);

  // const invoice = JSON.parse(localStorage.getItem("printInvoice"));
  const [searchParams] = useSearchParams();
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  const { id } = useParams();

  useEffect(() => {
    console.log(store);

    if (!store.data) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      {store.loading ? (
        <h1>Loading</h1>
      ) : (
        <Preview
          statement={store.data?.statement}
          customer={store.data.customerInfo}
          filterDate={{ start, end }}
          // statementRef={<div></div>}
        />
      )}
    </>
  );
};

export default PrintStatement;
