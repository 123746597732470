import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

// ** Third Party Components
import toast from "react-hot-toast";
import * as yup from "yup";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports

import {
  useGetWorkplaceQuery,
  // useUpdateWorkplaceMutation,
} from "../../../redux/workplace";
import {
  useCreateAllocationMutation,
  useUpdateAllocationMutation,
} from "../../../redux/allocation";
import CreatableSelect from "react-select/creatable";
import { useGetStaffsQuery } from "../../../redux/staff";

const schema = yup.object().shape({
  staff: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required("value is required"),
        label: yup.string().required("label is required"),
      })
    )
    .required("staff is required")
    .min(1, "One staff is required")
    .typeError("Type Error"),
  workplace: yup.string().required("Workplace is required"),
});

const defaultValues = {
  staff: "",
  workplace: "",
};

const AllocationModal = ({ show, setShow, selectedRow, setSelectedRow }) => {
  // ** state

  // ** RTK Query Mutations
  const [
    createAllocation,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateAllocationMutation();
  const [
    updateAllocation,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateAllocationMutation();

  // staffs
  const { data: staffs } = useGetStaffsQuery({});

  // workplace
  const { data: workplaces } = useGetWorkplaceQuery({});

  const workplaceOptions = workplaces?.docs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const jobsOptions = staffs?.docs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    data.staff = data.staff.map((item) => item.value);
    try {
      if (selectedRow) {
        const resData = await updateAllocation({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Allocation Updated successfully!");
          handleDiscard();
        }
      } else {
        const res = await createAllocation({
          ...data,
        }).unwrap();
        if (res) {
          toast.success("Allocation added successfully!");
          handleDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  // ** Handles error toast
  useEffect(() => {
    if (createError || updateError) {
      const message =
        createErrorMessage?.data?.message ||
        updateErrorMessage?.data?.message ||
        "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
        // staff: selectedRow.staff.
        staff: jobsOptions?.filter((item) =>
          selectedRow?.staff?._id.includes(item.value)
        ),
      });
    }
  }, [selectedRow, reset]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">
          {selectedRow ? "Edit Allocation" : "New Workplace Assign"}
        </span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm={12} md={12} className={"mb-2"}>
              <Label className="form-label" for="job">
                Staff <span className="text-danger"> * </span>
              </Label>
              <Controller
                name="staff"
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    id="staff"
                    isDisabled={selectedRow ? true : false}
                    className={`react-select ${
                      errors.staff ? "is-invalid" : ""
                    }`}
                    isMulti={true}
                    classNamePrefix="select"
                    aria-invalid={errors.staff && true}
                    placeholder="Choose Staff"
                    options={jobsOptions}
                    {...field}
                    value={field.value}
                    onChange={(selected) => field.onChange(selected)}
                  />
                )}
              />
              {errors.staff && (
                <FormFeedback>{errors.staff.message}</FormFeedback>
              )}
            </Col>
            <Col sm={12} md={12} className={"mb-2"}>
              <Label className="form-label" for="job">
                Workplace <span className="text-danger"> * </span>
              </Label>
              <Controller
                name="workplace"
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    id="workplace"
                    className={`react-select ${
                      errors.workplace ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.workplace && true}
                    placeholder="Choose Workplace"
                    options={workplaceOptions}
                    {...field}
                    value={workplaceOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selected) => field.onChange(selected.value)}
                  />
                )}
              />
              {errors.workplace && (
                <FormFeedback>{errors.workplace.message}</FormFeedback>
              )}
            </Col>

            <Col xs={12} className="mt-2">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {isCreating || isUpdating ? "Please, wait..." : "Save"}
              </Button>
              <Button
                className="me-1"
                color="primary"
                onClick={handleDiscard}
                disabled={isCreating || isUpdating}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AllocationModal;
