import React, { useRef } from "react";
import { FileExcel } from "react-bootstrap-icons";
import { Printer } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  UncontrolledCollapse,
  CardBody,
  CardText,
} from "reactstrap";
import Logo from "@src/assets/images/logo/logo-icon.png";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Summery from "./Summery";

const UnitsReport = () => {
  const { type } = useParams();

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Units Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const unitDataEntries = [
    { label: "Total Units", value: 20 },
    { label: "Occupied Units", value: 90 },
    { label: "Available Units", value: 10 },
    { label: "Booked Units", value: 13 },
  ];

  const financeDataEntries = [
    { label: "Total Rent Collected", value: "$90,000" },
    { label: "Total Rent Due", value: "$100,000" },
    { label: "Occupancy Rate", value: "90%" },
    { label: "Collection Rate", value: "90%" },
  ];

  return (
    <div>
      <Card className="invoice-preview-card" dir="ltr" innerRef={printRef}>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4> Units Report</h4>

          <CardHeader className="justify-content-end gap-1 mb-0 pb-0 no-print">
            <Printer
              size={23}
              className="cursor-pointer"
              title="Print"
              onClick={() => handlePrint()}
            />
            <FileExcel
              size={23}
              className="cursor-pointer"
              title="Export to Excell"
              onClick={() => {
                // beutify before print
                // downloadCSV(data)
              }}
            />
          </CardHeader>
        </CardHeader>

        <CardBody className="invoice-padding px-3 pt-2 pb-0 ">
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div>
              <div className="logo-wrapper d-flex align-items-center">
                <img src={Logo} alt="" width={50} />
                <h2 className="text-primary invoice-logo">Waafi Residences</h2>
              </div>
              <CardText className="mb-25">
                Opposite Makka Almukarama Hotel
              </CardText>
              <CardText className="mb-0">
                (+252) 7770223355 , (+252) 770223366
              </CardText>
              <CardText className="mb-25">
                www.alburuuj.so , Info@alburuuj.so
              </CardText>
            </div>

            <div className="mt-md-0 mt-2">
              <h4 className="invoice-title">
                {type} Units Report
                <span className="invoice-number"> </span>
              </h4>
              <div div className="invoice-date-wrapper d-flex gap-1">
                <p className="invoice-date-title">Date Issued:</p>
                <p className="invoice-date">{moment().format("YYYY-MM-DD")}</p>
              </div>
              <div div className="invoice-date-wrapper d-flex gap-1">
                <p className="invoice-date-title">Property Name(s):Name</p>
              </div>
            </div>
          </div>
        </CardBody>

        <hr />

        <CardBody className="invoice-padding px-3 pt-2 pb-0 ">
          {/* Header */}
          <Summery
            unitData={unitDataEntries}
            financeData={financeDataEntries}
          />
          {/* /Header */}
        </CardBody>
      </Card>
    </div>
  );
};

export default UnitsReport;
