// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllcategories = createAsyncThunk(
  "categories/getAllData",
  async () => {
    let params = {
      params: {
        options: {
          query: {},
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "_id username",
            },
          ],
        },
      },
    };

    const response = await axios.get("categories", params);
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk(
  "categories/getData",
  async (params) => {
    console.log(params);
    const response = await axios.get("categories", {
      params: { options: params },
    });
    return {
      params,
      data: response.data?.data?.docs,
    };
  }
);
export const updateCategory = createAsyncThunk(
  "categories/update",
  async ({ data, id }, { dispatch, getState }) => {
    try {
      var response = await axios.patch(`categories/${id}`, data);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/create",
  async (formdata, { dispatch, getState }) => {
    try {
      var response = await axios.post("categories", formdata);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/delete",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.delete(`categories/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const categorieslice = createSlice({
  name: "categories",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    selectedCategory: null,
    loading: false,
  },
  reducers: {
    selectCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    editCategory: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllcategories.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
      })
      .addCase(getAllcategories.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        console.log(payload);

        state.error = null;
        state.selectedCategory = null;
        state.allData.push(payload);
        state.data.push(payload);
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedCategory = null;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
        const deletedCategory = payload;
        state.data = state.data.filter((d) => d._id !== deletedCategory);
      });
  },
});
export const { addCategory, selectCategory, editCategory } =
  categorieslice.actions;
export default categorieslice.reducer;
