import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";

import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { usePlacesWidget } from "react-google-autocomplete";
import Select from "react-select";
import useGeolocation from "../../../../hooks/useGeolocation";

export default function PropertyAddress({
  stepper,
  control,
  register,
  errors,
  regions,
  cities,
  setValue,
}) {
  const { location, getGeolocation } = useGeolocation();

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyCgnWQH5St0k9FXSg02etIAmgBGUGd0iq8",
    language: "so",
    onPlaceSelected: (place) => {
      setValue("location.address", place.formatted_address);
      setValue("location.lat", place.geometry.location.lat());
      setValue("location.long", place.geometry.location.lng());
    },
    options: {
      types: [],
      componentRestrictions: { country: "so" },
    },
  });

  const nextClick = () => {
    stepper.next();
  };

  const fetchAddressFromCoords = (latitude, longitude) => {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.address) {
          const { country, state, city, road } = data.address;
          console.log(country, state, city, road);
          setValue("location.country", country);
          setValue("location.state", state);
          setValue("location.city", city);
          setValue("location.address", road);
        }
      })
      .catch((error) => console.error("Error fetching address:", error));
  };

  useEffect(() => {}, []);

  const getCurrentLocation = () => {
    getGeolocation();
    const { latitude, longitude } = location;
    if (latitude && longitude) {
      setValue("location.coordinates.longitude", longitude);
      setValue("location.coordinates.latitude", latitude);
      fetchAddressFromCoords(latitude, longitude);
    }
  };

  return (
    <>
      {/* <input type="search" ref={ref} className="form-control" /> */}
      <Row className="gy-1 gx-2">
        <Col xs={12} md={6}>
          <Label className="form-label" for="location.country">
            Country
          </Label>

          <Controller
            name="location.country"
            control={control}
            render={({ field }) => (
              <Controller
                name="location.country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="location.country"
                    placeholder="Somalia"
                    invalid={errors.location?.address && true}
                    {...field}
                  />
                )}
              />
            )}
          />
          {errors.location?.country && (
            <FormFeedback>{errors.location?.country?.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="location.state">
            State
          </Label>

          <Controller
            name="location.state"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Controller
                name="location.state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="location.state"
                    placeholder="Banadir"
                    invalid={errors.location?.address && true}
                    {...field}
                  />
                )}
              />
            )}
          />
          {errors.location?.state && (
            <FormFeedback>{errors.location?.state?.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="location.city">
            City
          </Label>

          <Controller
            name="location.city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Controller
                name="location.city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="location.city"
                    placeholder="Mogadishu"
                    invalid={errors.location?.address && true}
                    {...field}
                  />
                )}
              />
            )}
          />
          {errors?.location?.city && (
            <FormFeedback>{errors?.location?.address?.message}</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={6}>
          <Label className="form-label" for="location.address">
            Address
          </Label>
          <Controller
            name="location.address"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                id="location.address"
                placeholder="Talex, Hodan Mogadishu Somalia"
                invalid={errors.location?.address && true}
                {...field}
              />
            )}
          />
          {errors.location?.address && (
            <FormFeedback>{errors.location?.address.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="location.coordinates.latitude">
            Latitude
          </Label>
          <Controller
            name="location.coordinates.latitude"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                id="location.coordinates.latitude"
                placeholder="Latitude"
                invalid={errors.location?.address && true}
                {...field}
              />
            )}
          />
          {location?.coordinates?.latitude && (
            <FormFeedback>
              {location?.coordinates?.latitude.message}
            </FormFeedback>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="location.coordinates.longitude">
            Longitude
          </Label>
          <Controller
            name="location.coordinates.longitude"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                id="location.coordinates.longitude"
                placeholder="longitude"
                invalid={errors.location?.address && true}
                {...field}
              />
            )}
          />
          {location?.coordinates?.longitude && (
            <FormFeedback>
              {location?.coordinates?.longitude.message}
            </FormFeedback>
          )}
        </Col>

        {/* <Col xs={12}>
            <Label className="form-label">Select Property Location</Label>
            <div style={{ height: "350px" }}>
              <GoogleMap
                apiKey={""}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onClick={(e) => console.log(e)}
              />
            </div>
          </Col> */}
      </Row>
      <Button className="mt-2" color="primary" onClick={getCurrentLocation}>
        Get Current Location
      </Button>

      <div className="d-flex justify-content-between mt-4">
        <Button
          color="secondary"
          className="btn-prev"
          onClick={() => stepper.previous()}
        >
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
