import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

// ** Third Party Components
import toast from "react-hot-toast";
import * as yup from "yup";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports

import {
  useCreateWorkplaceMutation,
  useUpdateWorkplaceMutation,
} from "../../../redux/workplace";
import { Send } from "react-feather";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  address: yup.string().required("Address is required"),
  latitude: yup
    .number()
    .required("Latitude is required")
    .typeError("Latitude is required"),
  longitude: yup
    .number()
    .required("Longitude is required")
    .typeError("Longitude is required"),
  radius: yup
    .number()
    .required("Radius is required")
    .typeError("Radius is required"),
  isDefault: yup.bool(),
});

const defaultValues = {
  name: "",
  address: "",
  latitude: "",
  longitude: "",
  radius: "",
  isDefault: false,
};

const WorkplaceModal = ({ show, setShow, selectedRow, setSelectedRow }) => {
  // ** state

  // ** RTK Query Mutations
  const [
    createWorkplace,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateWorkplaceMutation();
  const [
    updateWorkplace,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateWorkplaceMutation();

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateWorkplace({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Workplace Updated successfully!");
          handleDiscard();
        }
      } else {
        const res = await createWorkplace({
          ...data,
        }).unwrap();
        if (res) {
          toast.success("Workplace added successfully!");
          handleDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  // ** Handles error toast
  useEffect(() => {
    if (createError || updateError) {
      const message =
        createErrorMessage?.data?.message ||
        updateErrorMessage?.data?.message ||
        "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
      });
    }
  }, [selectedRow, reset]);

  // ** get location
  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        reset({
          ...selectedRow,
          latitude,
          longitude,
        });
      });
    }
  };

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">
          {selectedRow ? "Edit Workplace" : "Add New Workplace"}
        </span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} className={"mb-1"}>
              <Label className="form-label" for="name">
                Name <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Type Name"
                    invalid={!!errors.name}
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>{errors.name.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className={"mb-1"}>
              <Label className="form-label" for="address">
                Workplace Address <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="3"
                    {...field}
                    placeholder="Enter Your Address"
                    invalid={!!errors.address}
                  />
                )}
              />
              {errors.address && (
                <FormFeedback>{errors.address.message}</FormFeedback>
              )}
            </Col>
            <Col xs={6} className={"mb-1"}>
              <Label className="form-label" for="name">
                Latitude <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="latitude"
                render={({ field }) => (
                  <Input {...field} invalid={!!errors.latitude} />
                )}
              />
              {errors.latitude && (
                <FormFeedback>{errors.latitude.message}</FormFeedback>
              )}
            </Col>
            <Col xs={6} className={"mb-1"}>
              <Label className="form-label" for="name">
                Longitude <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="longitude"
                render={({ field }) => (
                  <Input {...field} invalid={!!errors.longitude} />
                )}
              />
              {errors.longitude && (
                <FormFeedback>{errors.longitude.message}</FormFeedback>
              )}
            </Col>
            <Col xs={6} className={"mb-1"}>
              <Label className="form-label" for="name">
                Radius (m) <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="radius"
                render={({ field }) => (
                  <Input {...field} invalid={!!errors.radius} />
                )}
              />
              {errors.radius && (
                <FormFeedback>{errors.radius.message}</FormFeedback>
              )}
            </Col>
            <Col xs={6} className="mb-1 ">
              <Label className="form-label mb-50" for="isDefault">
                Set default
              </Label>
              <Controller
                control={control}
                name="isDefault"
                render={({ field }) => (
                  <div className="form-switch form-check-success d-flex align-items-center">
                    <Input
                      type="switch"
                      id="isDefault"
                      {...field}
                      checked={field.value}
                      invalid={!!errors.isDefault}
                      className="cursor-pointer"
                    />
                  </div>
                )}
              />
            </Col>

            <Col xs={12} className="mt-2">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {isCreating || isUpdating ? "Please, wait..." : "Save"}
              </Button>
              <Button
                className="me-1"
                color="primary"
                onClick={handleDiscard}
                disabled={isCreating || isUpdating}
              >
                Cancel
              </Button>
              <Button
                className="me-1"
                outline
                onClick={handleLocation}
                disabled={isCreating || isUpdating}
              >
                <Send width={17} height={17} className="me-50" />
                My Location
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default WorkplaceModal;
