// ** Invoice Add Components
import AddCard from "./AddCard";
import AddActions from "./AddActions";

// ** Reactstrap Imports
import { Row, Col } from "reactstrap";

// ** Styles
import "@styles/react/libs/flatpickr/flatpickr.scss";
// import "@styles/base/pages/app-invoice.scss";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { createInvoice } from "../../../redux/invoice";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useState } from "react";

const InvoiceAdd = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setSaveBtnLoading(true);
    let total = data.items.reduce(
      (prev, curr) => prev + (Number(curr.amount) - Number(curr.discount)),
      0
    );

    let discounts = data.items.reduce(
      (prev, curr) => prev + Number(curr.discount),
      0
    );

    data.grandTotal = total;
    data.discount = discounts;
    data.total = total + discounts;
    data.balance = total;
    data.type = appView;

    try {
      data.date = data.date ? data.date[0] : new Date();
      data.customer = data.customer.value;
      var result = await HandleDispatch(dispatch, createInvoice(data));
      if (result) {
        toast.success("Invoice Created Created Successfully");
        reset(initialValues);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaveBtnLoading(false);
    }
  };

  var initialValues = {
    date: "",
    type: appView,
    customer: "",
    grandTotal: 0,
    discount: 0,
    total: 0,
    balance: 0,
    remarks:
      "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
    items: [
      {
        amount: 0,
        description: "",
        discount: 0,
        total: 0,
      },
    ],
  };

  const {
    reset,
    control,
    register,
    clearErrors,
    getValues,
    setValue,
    setError,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm(initialValues);

  const formRef = useRef(null);

  console.log(errors);

  return (
    <div className="invoice-add-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="invoice-add">
          <Col xl={9} md={8} sm={12}>
            <AddCard
              {...{
                reset,
                control,
                register,
                clearErrors,
                getValues,
                setValue,
                trigger,
                setError,
                errors,
              }}
            />
          </Col>
          <Col xl={3} md={4} sm={12}>
            <AddActions
              formRef={formRef}
              btnLoading={saveBtnLoading}
              setValue={setValue}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default InvoiceAdd;
