import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
} from "reactstrap";

// ** Third Party Components
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreatePayrollMutation,
  useGeneratePayrollMutation,
  useUpdatePayrollMutation,
} from "../../../redux/payroll";
import { useGetStaffsListQuery } from "../../../redux/staff";
import moment from "moment";
import { ExtractError } from "../../../common/utils/Error";

const defaultValues = {
  name: "",
  staffs: [],
  exceptStaff: [],
  payMonth: "",
  payPeriodStartDate: "",
  payPeriodEndDate: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Name required"),
  staffs: yup.array().required("Staff is required"),
  exceptStaff: yup.array().nullable(),
  payMonth: yup.string().required("Pay Month is required"),
  payPeriodStartDate: yup
    .string()
    .required("Pay Period Start Date is required"),
  payPeriodEndDate: yup.string().required("Pay Period End Date is required"),
});

const PayrollForm = ({
  show,
  setShow,
  selectedRow,
  setSelectedRow,
  setData,
}) => {
  // const [generatePayroll, { isLoading }] = useGeneratePayrollMutation();
  const [createPayroll, { isLoading: isCreating }] = useCreatePayrollMutation();
  const [updatePayroll, { isLoading: isUpdating }] = useUpdatePayrollMutation();

  const { data: staffs, isFetching: StaffLoading } = useGetStaffsListQuery();
  const staffOptions = staffs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  // ** Handles form submission
  const onSubmit = async (values) => {
    try {
      if (selectedRow) {
        const { data, error } = await updatePayroll({
          id: selectedRow._id,
          data: values,
        });

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }

        toast.success("Payroll Updated Successfully");
        handleDiscard();
      } else {
        const { data, error } = await createPayroll(values);

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }

        toast.success("Payroll Saved Successfully");
        handleDiscard();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
        payPeriodStartDate: new Date(selectedRow.payPeriodStartDate)
          .toISOString()
          .slice(0, 10),
        payPeriodEndDate: new Date(selectedRow.payPeriodEndDate)
          .toISOString()
          .slice(0, 10),
      });
    }
  }, [selectedRow, reset]);

  useEffect(() => {
    if (watch("payMonth")) {
      const startDate = moment(watch("payMonth"))
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(watch("payMonth"))
        .endOf("month")
        .format("YYYY-MM-DD");

      setValue("payPeriodStartDate", startDate);
      setValue("payPeriodEndDate", endDate);
    }
  }, [watch("payMonth")]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">
          {selectedRow ? "Edit Payroll" : "Add New Payroll"}
        </span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} className="mb-1">
              <Label className="form-label" for="name">
                Name
              </Label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    invalid={!!errors.name}
                    placeholder="Name"
                  />
                )}
              />

              {errors.name && (
                <FormFeedback>{errors.name.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mb-1">
              <Label className="form-label" for="staffs">
                Staffs
              </Label>
              <Controller
                name="staffs"
                control={control}
                render={({ field }) => (
                  <Select
                    id="staffs"
                    className={`react-select ${
                      errors.staffs ? "is-invalid" : ""
                    }`}
                    isMulti={true}
                    classNamePrefix="select text-secondary"
                    aria-invalid={errors.staffs && true}
                    placeholder="Choose Staffs"
                    options={staffOptions}
                    onChange={(selected) => {
                      const selectedValues = selected.map(
                        (option) => option.value
                      );
                      field.onChange(selectedValues);
                    }}
                    isLoading={StaffLoading}
                  />
                )}
              />

              {errors.staffs && (
                <FormFeedback>{errors.staffs.message}</FormFeedback>
              )}
            </Col>
            {/* <Col xs={12} className="mb-1">
              <Label className="form-label" for="exceptStaff">
                Except for Staff
              </Label>
              <Controller
                name="exceptStaff"
                control={control}
                render={({ field }) => (
                  <Select
                    id="exceptStaff"
                    className={`react-select ${
                      errors.exceptStaff ? "is-invalid" : ""
                    }`}
                    isMulti={true}
                    classNamePrefix="select text-secondary"
                    aria-invalid={errors.exceptStaff && true}
                    placeholder="Except for Staff"
                    options={staffOptions}
                    {...field}
                    value={
                      field.value && field.value.length > 0
                        ? field.value.map((val) =>
                            staffOptions.find(
                              (option) => option.value === val.value
                            )
                          )
                        : []
                    }
                    onChange={(selected) => field.onChange(selected)}
                    isLoading={StaffLoading}
                  />
                )}
              />

              {errors.exceptStaff && (
                <FormFeedback>{errors.exceptStaff.message}</FormFeedback>
              )}
            </Col> */}
            <Col xs={12} className="mb-1">
              <Label className="form-label" for="payMonth">
                Pay Month
              </Label>
              <Controller
                control={control}
                name="payMonth"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="month"
                    placeholder="Pay Month"
                    invalid={!!errors.payMonth}
                  />
                )}
              />
              {errors.payMonth && (
                <FormFeedback>{errors.payMonth.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mb-1">
              <Label className="form-label" for="payPeriodStartDate">
                Pay Period Start Date
              </Label>
              <Controller
                control={control}
                name="payPeriodStartDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    invalid={!!errors.payPeriodStartDate}
                  />
                )}
              />
              {errors.payPeriodStartDate && (
                <FormFeedback>{errors.payPeriodStartDate.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mb-1">
              <Label className="form-label" for="payPeriodEndDate">
                Pay Period End Date
              </Label>
              <Controller
                control={control}
                name="payPeriodEndDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    invalid={!!errors.payPeriodEndDate}
                  />
                )}
              />
              {errors.payPeriodEndDate && (
                <FormFeedback>{errors.payPeriodEndDate.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-2">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {isCreating || isUpdating ? "Please, wait..." : "Save"}
              </Button>
              <Button
                outline
                onClick={handleDiscard}
                disabled={isCreating || isUpdating}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default PayrollForm;
