// ** React Imports
import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import axios from "axios";

import { ErrorHandler } from "../../../common/utils/Error";
import moment from "moment";
import { useEffect } from "react";
import Select from "react-select";

const RefundForm = ({ show, onClose, invoice }) => {
  const [loading, setLoading] = useState(false);
  const [customerInvoices, setcustomerInvoices] = useState([]);
  const [customerLeases, setcustomerLeases] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [CustomerInvoicesloading, setInvoicesloading] = useState(false);

  const getCustomerLeases = async (idx) => {
    setInvoicesloading(true);
    try {
      var { status, data } = await axios.get(`/agreements/customer/${idx}`);
      if (status == 200) {
        if (data.data?.length) {
          setcustomerLeases(data.data);
        } else {
          setcustomerLeases([]);
        }
      } else {
        setcustomerInvoices([]);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesloading(false);
    }
  };

  const defaultValues = {
    refundType: { label: "Full", value: "full" },
    amount: "",
    account: "",
    date: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    setError,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.account = data.account.value;
      data.refundType = data.refundType.value;

      data.invoice = invoice;

      var inv = await axios.post("/accountings/invoices/make-refund", data);
      if (inv.status == 200) {
        toast.success(`Refund Saved Successfully`);
        reset(defaultValues);
        onClose();
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setLoading(false);
  };

  const onDiscard = () => {
    clearErrors();
    reset(defaultValues);
    onClose();
  };
  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/type/64089ad59d122d411c67abae"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  let refundOptions = [
    { label: "Full", value: "full" },
    { label: "Partial", value: "partial" },
  ];

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={onClose} className="">
        <ModalHeader toggle={onClose} className="bg-white">
          Refund Information
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="date">
                  Date
                </Label>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.date && true}
                      {...field}
                    />
                  )}
                />
                {errors.date && (
                  <FormFeedback>Please enter Valid Date</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={12}>
                <Label className="form-label" for="refundType">
                  Refund Type
                </Label>
                <Controller
                  name="refundType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="refundType"
                      className="react-select w-100"
                      classNamePrefix="select"
                      invalid={errors.refundType && true}
                      {...field}
                      placeholder={"Select Refund Type"}
                      options={refundOptions}
                      onChange={(value) => {
                        field.onChange(value);
                        trigger("refundType");
                      }}
                    />
                  )}
                />
                {errors.refundType && (
                  <FormFeedback>
                    {JSON.stringify(errors.refundType)}
                  </FormFeedback>
                )}
              </Col>
              {getValues().refundType &&
              getValues().refundType?.value == "partial" ? (
                <Col xs={12}>
                  <Label className="form-label" for="amount">
                    Refund Amount
                  </Label>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="amount"
                        type="number"
                        invalid={errors.amount && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.amount && (
                    <FormFeedback>Please enter Invoice Date</FormFeedback>
                  )}
                </Col>
              ) : null}

              <Col xs={12} md={12}>
                <Label className="form-label" for="accountID">
                  Credited From
                </Label>
                <Controller
                  name="account"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="accountID"
                      className="react-select w-100"
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...field}
                      placeholder={"Select Account"}
                      options={
                        accounts.map((acc) => ({
                          value: acc._id,
                          label: acc.name,
                        })) ?? []
                      }
                    />
                  )}
                />
                {errors.account && (
                  <FormFeedback>{JSON.stringify(errors.account)}</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default RefundForm;
