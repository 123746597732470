// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { shiftApi } from "./shifts";
import { sectionalApi } from "./sectionals";
import { vendorApi } from "./vendors";
import { sectionalCategoryApi } from "./sectionalCategory";
import { contractTypesApi } from "./contractTypes";
import { contractApi } from "./contracts";
import { staffApi } from "./staff";
import { jobsApi } from "./jobs";
import { departmentsApi } from "./departments";
import { salaryAllowanceTypesApi } from "./salaryAllowanceTypes";
import { workplaceApi } from "./workplace";
import { buildingApi } from "./building";
import { deductionListApi } from "./deductionList";

import { deductionsApi } from "./deduction";
import { payrollApi } from "./payroll";

import { allocationApi } from "./allocation";
import { attendanceApi } from "./attendance";

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: true,
    })
      .concat(shiftApi.middleware)
      .concat(sectionalApi.middleware)
      .concat(vendorApi.middleware)
      .concat(sectionalCategoryApi.middleware)
      .concat(contractTypesApi.middleware)
      .concat(contractApi.middleware)
      .concat(staffApi.middleware)
      .concat(jobsApi.middleware)
      .concat(departmentsApi.middleware)
      .concat(salaryAllowanceTypesApi.middleware)
      .concat(workplaceApi.middleware)
      .concat(buildingApi.middleware)
      .concat(deductionListApi.middleware)
      .concat(deductionsApi.middleware)
      .concat(allocationApi.middleware)
      .concat(attendanceApi.middleware)
      .concat(payrollApi.middleware)
  },
});

export { store };
