import React from 'react'
import { StarFill,Star } from 'react-bootstrap-icons'

import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'

export default function ReportLink({link,title,isFavorite}) {
  return (
    <Col md={3} className="mb-1">
      <Link
        to={link}
        className="p-1 text-dark  rounded-2 d-flex align-items-center border border-light "
      >
        <h5 className="align-middle mb-0 fw-bolder">
          <span className="me-1">
            {isFavorite ? (
              <StarFill className="text-warning " size={17} />
            ) : (
              <Star className="text-warning" size={17} />
            )}
          </span>
          {title}
        </h5>
      </Link>
    </Col>
  );
}
