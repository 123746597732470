import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { createQueryString, getToken } from "../utility/helper";

export const deductionListApi = createApi({
  reducerPath: "deductionList",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["deductionList", "deductionListsList"],
  endpoints: (builder) => ({
    // Read
    getDeductionList: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/deduction-list?${serializedParams}`;
      },
      providesTags: ["deductionList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getDeductionListList: builder.query({
      query: () => {
        return `/hrm/deduction-list/list`;
      },
      providesTags: ["deductionListsList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createDeductionList: builder.mutation({
      query: (data) => ({
        url: "/hrm/deduction-list",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deductionList", "deductionListsList"],
    }),

    // Update
    updateDeductionList: builder.mutation({
      query: ({ data: deductionList, id }) => ({
        url: `/hrm/deduction-list/${id}`,
        method: "PATCH",
        body: deductionList,
      }),
      invalidatesTags: ["deductionList", "deductionListsList"],
    }),

    // Delete
    deleteDeductionList: builder.mutation({
      query: (id) => ({
        url: `/hrm/deduction-list/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["deductionList", "deductionListsList"],
    }),
  }),
});

export const {
  useGetDeductionListQuery,
  useGetDeductionListListQuery,
  useCreateDeductionListMutation,
  useUpdateDeductionListMutation,
  useDeleteDeductionListMutation,
} = deductionListApi;
