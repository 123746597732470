// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import authentication from "./authentication";
import users from "./users";
import buildings, { buildingApi } from "./building";
import CurrentBuilding from "./home";
import bookings from "./booking";
import customers from "./customer";

import sectionals, { sectionalApi } from "./sectionals";
import expenses from "./expenses";
import accounts from "./chartOfAccount";
import payments from "./payments";
import categories from "./category";
import amenities from "./amenities";
import invoices from "./invoice";
import receipts from "./receipt";
import agreements from "./agreements";
import owners from "./owner";
import staff from "./staff_old";
import leaves from "./leaves";

import departments, { departmentsApi } from "./departments";
import roles from "./roles";
import utilityType from "./utilityTypes";
import utilities from "./utilities";
import accountBook from "./accountBook";
import dashboard from "./dashboard";
// rtk query api import
import { shiftApi } from "./shifts";
import { vendorApi } from "./vendors";
import { sectionalCategoryApi } from "./sectionalCategory";
import { contractTypesApi } from "./contractTypes";
import { contractApi } from "./contracts";

import { jobsApi } from "./jobs";
import { salaryAllowanceTypesApi } from "./salaryAllowanceTypes";
import { workplaceApi } from "./workplace";
import { staffApi } from "./staff";
import { deductionListApi } from "./deductionList";

import { deductionsApi } from "./deduction";

import { allocationApi } from "./allocation";
import { attendanceApi } from "./attendance";
import { payrollApi } from "./payroll";


const rootReducer = {
  navbar,
  layout,
  authentication,
  users,
  // buildings,
  sectionals,
  expenses,
  accounts,
  payments,
  categories,
  amenities,
  CurrentBuilding,
  bookings,
  customers,
  invoices,
  receipts,
  agreements,
  owners,
  // staff,
  leaves,
  roles,
  utilityType,
  utilities,
  accountBook,
  dashboard,

  //rtk query
  [shiftApi.reducerPath]: shiftApi.reducer,
  [sectionalApi.reducerPath]: sectionalApi.reducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
  [sectionalCategoryApi.reducerPath]: sectionalCategoryApi.reducer,
  [contractTypesApi.reducerPath]: contractTypesApi.reducer,
  [contractApi.reducerPath]: contractApi.reducer,
  [staffApi.reducerPath]: staffApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [departmentsApi.reducerPath]: departmentsApi.reducer,
  [salaryAllowanceTypesApi.reducerPath]: salaryAllowanceTypesApi.reducer,
  [workplaceApi.reducerPath]: workplaceApi.reducer,
  [deductionListApi.reducerPath]: deductionListApi.reducer,

  [deductionsApi.reducerPath]: deductionsApi.reducer,
  [payrollApi.reducerPath]: payrollApi.reducer,

  [buildingApi.reducerPath]: buildingApi.reducer,
  [allocationApi.reducerPath]: allocationApi.reducer,
  [attendanceApi.reducerPath]: attendanceApi.reducer,
};

export default rootReducer;
