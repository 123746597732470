// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import { Badge, Button, Card, CardBody } from "reactstrap";

// ** Third Party Components
import { Briefcase, Check } from "react-feather";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// ** Custom Components
import Avatar from "@components/avatar";
import themeConfig from "../../../../configs/themeConfig.js";

// ** Utils

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { Building } from "react-bootstrap-icons";

const roleColors = {
  editor: "light-info",
  admin: "light-danger",
  author: "light-warning",
  maintainer: "light-success",
  subscriber: "light-primary",
};

const statusColors = {
  active: "light-success",
  pending: "light-warning",
  inactive: "light-secondary",
};

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "suspended", label: "Suspended" },
];

const countryOptions = [
  { value: "uk", label: "UK" },
  { value: "usa", label: "USA" },
  { value: "france", label: "France" },
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
];

const languageOptions = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "dutch", label: "Dutch" },
];

const MySwal = withReactContent(Swal);

const StaffInfo = ({ staff }) => {
  // ** State
  const [show, setShow] = useState(false);

  // ** render user img
  const renderUserImg = () => {
    if (staff !== null && staff?.photo !== "no-image") {
      return (
        <img
          height="110"
          width="110"
          alt="user-avatar"
          src={`${themeConfig.serverUrl}uploads/${staff?.photo}`}
          className="img-fluid rounded mt-3 mb-2"
        />
      );
    } else {
      return (
        <Avatar
          initials
          color={"light-primary"}
          className="rounded mt-3 mb-2"
          content={staff?.name}
          contentStyles={{
            borderRadius: 0,
            fontSize: "calc(48px)",
            width: "100%",
            height: "100%",
          }}
          style={{
            height: "110px",
            width: "110px",
          }}
        />
      );
    }
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              {renderUserImg()}
              <div className="d-flex flex-column align-items-center text-center">
                <div className="user-info">
                  <h4>{staff !== null ? staff?.name : "No Name"}</h4>
                  {staff !== null ? (
                    <Badge
                      color={roleColors[staff?.role]}
                      className="text-capitalize"
                    >
                      {staff?.role || "staff"}
                    </Badge>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-around my-2 pt-75">
            <div className="d-flex align-items-start me-2">
              <Badge color="light-primary" className="rounded p-75">
                <Building className="font-medium-2" />
              </Badge>
              <div className="ms-75">
                <h4 className="mb-0">10</h4>
                <small>Total Properties</small>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <Badge color="light-primary" className="rounded p-75">
                <Briefcase className="font-medium-2" />
              </Badge>
              <div className="ms-75">
                <h4 className="mb-0">568</h4>
                <small>Projects Done</small>
              </div>
            </div>
          </div> */}
          <h4 className="fw-bolder border-bottom pb-50 mb-1">Details</h4>
          <div className="info-container">
            {staff !== null ? (
              <ul className="list-unstyled">
                <li className="mb-75">
                  <span className="fw-bolder me-25">Username:</span>
                  <span>{staff?.username}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Email:</span>
                  <span>{staff?.email}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Status:</span>
                  <Badge
                    className="text-capitalize"
                    color={statusColors[staff?.status]}
                  >
                    {staff?.status}
                  </Badge>
                </li>
                {/* <li className="mb-75">
                  <span className="fw-bolder me-25">Role:</span>
                  <span className="text-capitalize">{staff?.role}</span>
                </li> */}

                <li className="mb-75">
                  <span className="fw-bolder me-25">Contact:</span>
                  <span>{staff?.phone}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Gender:</span>
                  <span>{staff?.gender}</span>
                </li>
              </ul>
            ) : null}
          </div>
          {/* <div className="d-flex justify-content-center pt-2">
            <Button color="primary" onClick={() => setShow(true)}>
              Edit
            </Button>
            <Button className="ms-1" color="danger" outline onClick={() => {}}>
              Suspended
            </Button>
          </div> */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default StaffInfo;
