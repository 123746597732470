import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

// ** Third Party Components
import toast from "react-hot-toast";
import * as yup from "yup";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports

import {
  useCreateSalaryAllowanceTypeMutation,
  useUpdateSalaryAllowanceTypesMutation,
} from "../../../redux/salaryAllowanceTypes";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  amount: yup
    .number()
    .required("Amount is required")
    .min(1, "Amount cannot less than 0")
    .typeError("Amount is required"),
});

const defaultValues = {
  name: "",
  amount: "",
};

const SalaryTypeModal = ({ show, setShow, selectedRow, setSelectedRow }) => {
  // ** RTK Query Mutations
  const [
    createSalaryTypes,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateSalaryAllowanceTypeMutation();

  const [
    updateSalaryTypes,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateSalaryAllowanceTypesMutation();

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateSalaryTypes({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Type Updated successfully!");
          handleDiscard();
        }
      } else {
        const res = await createSalaryTypes({
          ...data,
          type: "salary",
        }).unwrap();
        if (res) {
          toast.success("Type added successfully!");
          handleDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  // ** Handles error toast
  useEffect(() => {
    if (createError || updateError) {
      const message =
        createErrorMessage?.data?.message ||
        updateErrorMessage?.data?.message ||
        "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
      });
    }
  }, [selectedRow, reset]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">{selectedRow ? "Edit Type" : "Add New Type"}</span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              <Label className="form-label" for="name">
                Type Name
              </Label>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Type Name"
                    invalid={!!errors.name}
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>{errors.name.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="my-2">
              <Label className="form-label" for="amount">
                Amount
              </Label>
              <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                  <Input
                    type="number"
                    {...field}
                    placeholder="Amount"
                    invalid={!!errors.amount}
                  />
                )}
              />
              {errors.amount && (
                <FormFeedback>{errors.amount.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-2">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {isCreating || isUpdating ? "Please, wait..." : "Save"}
              </Button>
              <Button
                outline
                onClick={handleDiscard}
                disabled={isCreating || isUpdating}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default SalaryTypeModal;
