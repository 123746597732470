import { createMongoAbility } from "@casl/ability";
import { initialAbility } from "./initialAbility";
import { updateAbility } from "../../utility/context/Can";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem("userData"));
const existingAbility = userData ? updateAbility(userData.role.ability) : null;

export default new createMongoAbility(existingAbility || initialAbility);
