import React from "react";
import DeductionsTable from "./DeductionsTable";
import { useGetEmployeeDeductionsQuery } from "../../../redux/deduction";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";

const DeductionList = () => {
  const { data, isLoading } = useGetEmployeeDeductionsQuery();

  console.log(data);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Card className="p-2">
      <CardHeader className="h3">Salary deductions</CardHeader>
      <CardBody>
        <DeductionsTable data={data} />
      </CardBody>
    </Card>
  );
};

export default DeductionList;
