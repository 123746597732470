import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const workplaceApi = createApi({
  reducerPath: "workplace",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Workplace"],
  endpoints: (builder) => ({
    // Read
    getWorkplace: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/workplace?${serializedParams}`;
      },
      providesTags: ["Workplace"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // create
    createWorkplace: builder.mutation({
      query: (body) => ({
        url: "/workplace",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Workplace"],
    }),

    // update
    updateWorkplace: builder.mutation({
      query: ({ data, id }) => ({
        url: `/workplace/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Workplace"],
    }),

    // Delete
    deleteWorkplace: builder.mutation({
      query: (id) => ({
        url: `/workplace/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Workplace"],
    }),
  }),
});

export const {
  useGetWorkplaceQuery,
  useCreateWorkplaceMutation,
  useUpdateWorkplaceMutation,
  useDeleteWorkplaceMutation,
} = workplaceApi;
