// ** React Imports
import { Suspense, useContext } from "react";
import { Navigate } from "react-router-dom";

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../../utility/context/Can";

const PublicRoute = ({ children, route }) => {
  if (route) {
    const user = getUserData();
    const ability = useContext(AbilityContext);

    const restrictedRoute = route.meta && route.meta.restricted;

    if (user && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(user.role, ability)} />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PublicRoute;
