import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";

const Summery = () => {
  // Data for Occupied and Available Units
  const unitsData = {
    labels: ["Occupied Units", "Available Units"],
    datasets: [
      {
        label: "Units",
        data: [75, 25], // replace these numbers with your actual data
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Building Expenses and Income
  const financeData = {
    labels: ["Building Expenses", "Building Income"],
    datasets: [
      {
        label: "Amount in USD",
        data: [2000, 5000], // replace these numbers with your actual data
        backgroundColor: ["rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="mb-5">
      <h1>Building Report</h1>
      <div style={{ height: "500px", width: "600px" }}>
        <h2>Units Report</h2>
        <Bar
          data={unitsData}
          options={{ scales: { y: { beginAtZero: true } } }}
        />
      </div>
      <div style={{ height: "500px", width: "600px" }}>
        <h2>Financial Report</h2>
        <Doughnut data={financeData} />
      </div>
    </div>
  );
};

export default Summery;
