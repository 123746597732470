export default {
  tenant: [
    {
      link: "/reports/tenants?type=current",
      title: "Current Tenants",
      isFavorite: false,
    },
    // {
    //   link: "/reports/tenants/statement",
    //   title: "Tenant Statement",
    //   isFavorite: true,
    // },
    {
      link: "/reports/tenants?type=all",
      title: "All Tenants",
      isFavorite: false,
    },
  ],
  property: [
    {
      link: "/reports/properties/rent_roll",
      title: "Rent Rolls",
      isFavorite: false,
    },
  ],

  units: [
    {
      link: "/reports/units/Summery",
      title: "Summery Report",
      isFavorite: false,
    },
  ],

  accounting: [
    {
      link: "/accountings/account-statement",
      title: "Account Statement",
      isFavorite: false,
    },
    {
      link: "/accountings/trial-balance",
      title: "Trial Balance",
      isFavorite: false,
    },
    {
      link: "/accountings/income-statement",
      title: "Income Statement",
      isFavorite: false,
    },
    {
      link: "/accountings/balance-sheet",
      title: "Balance Sheet",
      isFavorite: false,
    },
  ],
  owner: [
    // {
    //     link: "/reports/owners/ownership",
    //     title: "Property Ownership",
    //     isFavorite: false,
    //   },
    {
      link: "/reports/owners?type=all",
      title: "All Owners",
      isFavorite: false,
    },
  ],
  lease: [
    {
      link: "/reports/leases?type=Active",
      title: "Active Leases",
      isFavorite: true,
    },
    {
      link: "/reports/leases?type=Terminated",
      title: "Terminated Leases",
      isFavorite: false,
    },
    {
      link: "/reports/leases?type=all",
      title: "All Leases",
      isFavorite: true,
    },
    {
      link: "/reports/leases?type=move-ins",
      title: "Upcoming Move-ins",
      isFavorite: true,
    },
    {
      link: "/reports/leases?type=move-outs",
      title: "Expiring Leases",
      isFavorite: true,
    },
  ],
  service: [
    {
      link: "/reports/services?type=salesAndBroker",
      title: "Sales And Broker",
      isFavorite: true,
    },
    {
      link: "/reports/services?type=consultation",
      title: "Consultation",
      isFavorite: false,
    },
    {
      link: "/reports/services?type=maintainance",
      title: "Maintainance",
      isFavorite: false,
    },
  ],
};
