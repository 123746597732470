// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { Controller, useForm } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useEffect } from "react";
import { toast } from "react-hot-toast";

import {
  useCreateJobMutation,
  useUpdateJobMutation,
} from "../../../../redux/jobs";

const defaultValues = {
  name: "",
};

const JobModel = ({ show, ShowModal, selectedRow, setSelectedRow }) => {
  const [
    createJob,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateJobMutation();

  const [
    updateJob,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateJobMutation();

  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateJob({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Job Updated successfully!");
          onDiscard();
        }
      } else {
        const res = await createJob(data).unwrap();
        if (res) {
          toast.success("Job added successfully!");
          onDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  useEffect(() => {
    reset({ ...selectedRow });
  }, [selectedRow]);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onDiscard}
        className=""
        modalClassName="modal-dark"
      >
        <ModalHeader toggle={onDiscard} className="bg-white">
          {selectedRow ? "Update Job" : "Add New Job"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            <Row className="gy-1 gx-2 flex-column">
              <Col xs={12}>
                <Label className="form-label" for="name">
                  Job Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="job name"
                      {...register(
                        "name",
                        { required: true },
                        "Job Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={isCreating || isUpdating}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!isCreating || !isUpdating}
              />
              {isCreating || isUpdating ? "Submiting" : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default JobModel;
