// ** React Imports
import { Outlet, useSearchParams } from "react-router-dom";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import navigation from "@src/navigation/vertical";

const VerticalLayout = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterMenu = () => {
    let appView = searchParams.get("view");
    let currentNav = appView
      ? navigation.filter((nav) => nav.view?.includes(appView))
      : navigation;
    if (!appView) {
      currentNav = currentNav.filter((nav) => !nav.view?.includes("!default"));
    }

    return currentNav ?? [];
  };

  return (
    <Layout menuData={filterMenu()} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
