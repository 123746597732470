// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllagreements = createAsyncThunk(
  "agreements/getAllData",
  async ({ building, status }) => {
    let bodyContent = {
      params: {
        query: { building, status },
        options: {
          populate: [
            {
              dir: "customers",
              path: "user",
              select: "name email",
            },
            {
              dir: "buildings",
              path: "building",
              select: "name",
            },
            {
              dir: "sectionals",
              path: "unit",
              select: "_id name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/agreements", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk(
  "agreements/getData",
  async (params) => {
    const response = await axios.get("/agreements", {
      params: {
        query: { building: params.building },
        options: {
          ...params,
          populate: [
            {
              dir: "customers",
              path: "user",
              select: "name email",
            },
            {
              dir: "buildings",
              path: "building",
              select: "name",
            },
            {
              dir: "sectionals",
              path: "unit",
              select: "_id name",
            },
          ],
        },
      },
    });
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return {
      params,
      data: response.data?.data?.docs,
    };
  }
);

export const createAgreement = createAsyncThunk(
  "agreements/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      var response = await axios.post("/agreements", formdata);

      if (response.status != 200) {
        await dispatch(getAllagreements());
        return rejectWithValue(response.data.message);
      }
      return response.data;
    } catch (error) {
      rejectWithValue(response.data.message);
    }
  }
);

export const terminateAgreement = createAsyncThunk(
  "agreements/terminate",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post("/agreements/terminate", data);
      if (response.status != 204) {
        return rejectWithValue(response);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const renewAgreement = createAsyncThunk(
  "agreements/renew",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("/agreements/renew", data);

      await dispatch(getAllagreements());
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      rejectWithValue(response.data.message);
    }
  }
);

export const updateAgreement = createAsyncThunk(
  "agreements/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.patch(`/agreements/${id}`, data);
      await dispatch(getAllagreements());
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAgreement = createAsyncThunk(
  "agreements/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/agreements/${id}`);
      console.log(response.data);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      await dispatch(getAllagreements());
      return response.status;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementslice = createSlice({
  name: "agreements",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    selectedAgreement: null,
  },
  reducers: {
    addAgreement: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectAgreement: (state, { payload }) => {
      state.selectedAgreement = payload;
    },
    editAgreement: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedAgreement = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllagreements.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllagreements.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllagreements.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(createAgreement.fulfilled, (state, { payload }) => {
        state.error = null;
        state.selectedAgreement = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(createAgreement.rejected, (state, { payload, error }) => {
        state.error = payload;
        state.selectedAgreement = null;
      })
      .addCase(updateAgreement.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedAgreement = null;
      })
      .addCase(updateAgreement.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      // .addCase(terminateAgreement.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = null;
      //   state.data = state.data.map((d) => {
      //     if (d?._id == payload?._id) {
      //       return { ...d, status: payload.status };
      //     }
      //     return d;
      //   });
      //   state.allData = state.allData.map((da) => {
      //     if (da?._id == payload?._id) {
      //       return { ...da, status: payload.status };
      //     }
      //     return da;
      //   });
      //   state.selectedAgreement = null;
      // })
      .addCase(renewAgreement.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return { ...d, status: payload.status };
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return { ...da, status: payload.status };
          }
          return da;
        });
        state.selectedAgreement = null;
      });
  },
});

export const { selectAgreement, searchFilter } = agreementslice.actions;
export default agreementslice.reducer;
