// // ** React Imports
// import { Fragment } from "react";
// // ** Reactstrap Imports
// import { Card, CardHeader, CardTitle } from "reactstrap";

// // ** Table Import
// import ShiftsTable from "./components/DataTable";

// const Permissions = () => {
//   return (
//     <Fragment>
//       <Card>
//         <CardHeader className="d-flex align-items-start">
//           <CardTitle tag="h4">Shifts Information</CardTitle>
//           {/* <DepartmentModel show={showmodal} ShowModal={ToggleModal} /> */}
//           {/* <Button
//             className=""
//             color="primary"
//             onClick={() => setModalShow(true)}
//           >
//             <BuildingAdd className="me-1" size={20} /> Add Department
//           </Button> */}
//         </CardHeader>
//         <div className="card-datatable app-user-list table-responsive">
//           <ShiftsTable />
//         </div>
//       </Card>
//     </Fragment>
//   );
// };

// export default Permissions;

import React, { useState } from "react";

import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";

import { Edit, Trash } from "react-feather";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { PencilFill } from "react-bootstrap-icons";
import CustomDataTable from "../../../common/CustomDataTable";
import {
  useDeleteShiftMutation,
  useGetShiftsQuery,
} from "../../../redux/shifts";
import ShiftModal from "./ShiftModel";

const MySwal = withReactContent(Swal);

const Jobs = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteShift] = useDeleteShiftMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Account ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteShift(id);
        if (responseStatus) {
          toast.success("Shift has been Deleted.");
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Name",
      minWidth: "250px",
      sortable: true,
      cell: ({ name }) => name,
      selector: (row) => row.name,
    },
    {
      sortable: true,
      minWidth: "250px",
      name: "User",
      cell: ({ createdBy }) => createdBy?.username,
    },
    {
      sortable: true,
      minWidth: "250px",
      name: "Created Date",
      selector: (row) => row.createdAt,
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY"),
      sortFunction: (rowA, rowB) => {
        return new Date(rowB.createdAt) - new Date(rowA.createdAt);
      },
    },
    {
      name: "Status",
      minWidth: "250px",
      sortable: true,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Shifts"
        fetchData={useGetShiftsQuery}
        searchFields={["name"]}
        populate={[
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"Add New Shift"}
        addButtonClick={() => setIsModalVisible(true)}
      />

      <ShiftModal
        show={isModalVisible}
        ShowModal={setIsModalVisible}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default Jobs;
