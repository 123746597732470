import React from "react";
import { Controller } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import FileUploaderSingle from "../../../../common/utils/components/SingleFileUploader";

export default function PropertyMedia({
  stepper,
  control,
  register,
  errors,
  files,
  setFiles,
  setThumbnail,
  thumbnail,
  loading,
}) {
  const nextClick = () => {
    // stepper.next()
  };

  return (
    <>
      <Row className="gy-1 gx-2">
        <Col xs={12} md={4}>
          <Label className="form-label" for="">
            Thumbnail
          </Label>
          <FileUploaderSingle files={thumbnail} setFiles={setThumbnail} />
        </Col>
        <Col xs={12} md={8}>
          <Label className="form-label" for="">
            Property images
          </Label>
          <MultipleImageUpload files={files} setFiles={setFiles} />
        </Col>
        <Col xs={12} md={12}>
          <Label className="form-label" for="videoUrl">
            Video Url
          </Label>

          <Controller
            name="videoUrl"
            control={control}
            render={({ field }) => (
              <Input id="videoUrl" type="text" placeholder="" {...field} />
            )}
          />
          {errors.videoUrl && (
            <FormFeedback>Please enter a valid area</FormFeedback>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button
          color="secondary"
          className="btn-prev"
          onClick={() => stepper.previous()}
        >
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button
          color="primary"
          type="submit"
          className="btn-next"
          disabled={loading}
        >
          <Spinner color="light" size="sm" className="me-1" hidden={!loading} />

          {loading ? (
            "Loading..."
          ) : (
            <>
              <span className="align-middle d-sm-inline-block d-none">
                Submit
              </span>
              <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
            </>
          )}
        </Button>
      </div>
    </>
  );
}
