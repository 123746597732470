// ** React Imports
import { Fragment, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// ** Reactstrap Imports
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// ** Third Party Components
import { Copy, Trash2 } from "react-feather";
import { useForm } from "react-hook-form";

// ** Custom Components
import AvatarGroup from "@components/avatar-group";
import { Pencil } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-hot-toast";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import RolesArr from "../../../data/permissions.json";
import { createRole, deleteRole, selectRole } from "../../../redux/roles";
import { Loading } from "./Loading";

import { Can } from "../../../utility/context/Can";
import Can2 from "../../../utility/Can";
import { ErrorHandler } from "../../../common/utils/Error";
import { getAllBuildings } from "../../../redux/building";
import { useEffect } from "react";

const MySwal = withReactContent(Swal);

const RoleCards = () => {
  // ** States
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState("insert");
  const [editRole, setEditRole] = useState();
  const [selectedRole, setSelectedRole] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const store = useSelector((state) => state.roles);

  const defaultValues = {
    name: "",
    ability:
      RolesArr?.map((role) => ({ subject: role.subject, action: [] })) ?? [],
  };

  const handleEdit = (role) => {
    var currentAbility = getValues().ability;

    var newAbility = currentAbility.map((cab) => {
      let newaction = role.ability.find((rab) => rab.subject == cab.subject);

      if (newaction) {
        cab.action = newaction.action;
      }

      return cab;
    });
    reset({
      name: role.name,
      ability: newAbility,
    });

    setEditRole(role);
    setShow(true);
  };

  // ** Hooks
  const {
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const onSubmit = async (data) => {
    if (data.name.length) {
      var values = data.ability.filter(
        (ability) => ability.action && ability.action?.length !== 0
      );
      data.ability = values;

      if (editRole) {
        //update
        toast.error("Edit Role");
      } else {
        const response = await HandleDispatch(dispatch, createRole(data));

        if (response) {
          toast.success("New Role Created");
          setShow(false);
        }
      }
    } else {
      setError("name", {
        type: "manual",
      });
    }
  };

  const handleModalClosed = () => {
    setSelectedRole(null);
  };

  const getInputValue = (subject, action) => {
    let values = getValues().ability;

    let indx = values.find(
      (ab) => ab.subject == subject && ab.action.find((ac) => ac == action)
    );

    return indx ? true : false;
  };

  const handleConfirmDelete = async (id, name) => {
    return MySwal.fire({
      title: `Delete Role ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        try {
          const deletedRole = await HandleDispatch(
            dispatch,
            deleteRole({ id })
          );
          if (deletedRole) {
            toast.success("Role has been Deleted.");
          }
        } catch (error) {
          ErrorHandler(error);
        }
      }
    });
  };

  return (
    <Fragment>
      <Row>
        {store?.loading ? (
          <Loading />
        ) : (
          store?.data?.map((item, index) => {
            return (
              <Col key={index} xl={4} md={6}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <span>{`Total ${item?.totalUsers ?? 0} users`}</span>
                      <AvatarGroup data={item?.users ?? []} />
                    </div>
                    <div className="d-flex justify-content-between align-items-end mt-1 pt-25">
                      <div className="role-heading">
                        <h4 className="fw-bolder">{item.name}</h4>

                        <Can2 I={"update"} a={"Role"} y={appView}>
                          <Link
                            to="/"
                            className="role-edit-modal "
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(selectRole(item));
                              navigate(
                                `/roles/form?action=edit&view=${appView}`
                              );
                            }}
                          >
                            <Pencil className="font-light-1" />
                            &nbsp;
                            <small className="fw-bolder">Edit Role</small>
                          </Link>
                        </Can2>
                      </div>
                      <div>
                        <Can2 I={"delete"} a={"Role"} y={appView}>
                          <Link
                            to=""
                            className="text-body"
                            onClick={(e) => {
                              e.preventDefault();
                              handleConfirmDelete(item._id, item.name);
                            }}
                          >
                            <Trash2 className="font-medium-3 me-1 text-danger" />
                          </Link>
                        </Can2>
                        <Can2 I={"create"} a={"Role"} y={appView}>
                          <Link
                            to=""
                            className="text-body"
                            onClick={(e) => {
                              e.preventDefault();

                              dispatch(selectRole(item));
                              navigate(
                                `/roles/form?action=duplicate&view=${appView}`
                              );
                            }}
                          >
                            <Copy className="font-medium-3" />
                          </Link>
                        </Can2>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })
        )}
        <Col xl={4} md={6}>
          <Card>
            <Row>
              <Col sm={4}>
                <div className="d-flex align-items-end justify-content-center h-100">
                  {/* <img className='img-fluid mt-2' src={illustration} alt='Image' width={85} /> */}
                </div>
              </Col>
              <Col sm={8}>
                <Can2 I={"create"} a={"Role"} y={appView}>
                  <CardBody className="text-sm-end text-center ps-sm-0">
                    <Button
                      color="primary"
                      className="text-nowrap mb-1"
                      onClick={() => {
                        navigate(`/roles/form?action=create&view=${appView}`);
                      }}
                    >
                      Add New Role
                    </Button>
                    <p className="mb-0">Add a new role, if it does not exist</p>
                  </CardBody>
                </Can2>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default RoleCards;
