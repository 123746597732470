import React from "react";
import { Edit, Trash, User } from "react-feather";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomDataTable from "../../../common/CustomDataTable";
import {
  useDeleteStaffMutation,
  useGetStaffsQuery,
} from "../../../redux/staff";
import Statistics from "./statistics";
import themeConfig from "../../../configs/themeConfig";

const MySwal = withReactContent(Swal);

const Staff = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const [deleteStaff] = useDeleteStaffMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Account ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteStaff(id);
        if (responseStatus) {
          toast.success("Staff has been Deleted.");
        }
      }
    });
  };

  const columns = [
    {
      name: "Name",
      minWidth: "20%",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            const url =
              `/hrm/staff/${row._id}` + (appView ? "?view=" + appView : "");
            navigate(url);
          }}
        >
          <div className="avatar rounded">
            <div className="avatar-content">
              {row.photo && row.photo !== "no-image" ? (
                <img
                  src={`${themeConfig.serverUrl}uploads/${row?.photo}`}
                  alt="User Avatar"
                />
              ) : (
                <User />
              )}
            </div>
          </div>
          <div className="ms-1 ">
            <div className="fw-bolder text-truncate">{row.name}</div>
            <div className="font-small-2 text-muted text-truncate">
              {row.email}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Title",
      sortable: true,
      cell: (row) => <div className="text-truncate">{row.job?.name ?? ""}</div>,
    },
    {
      name: "Phone",
      sortable: true,
      cell: (row) => <div>{row.contacts ?? ""}</div>,
    },
    {
      name: "Department",
      sortable: true,
      cell: (row) => <div>{row.department?.name ?? ""}</div>,
    },
    {
      name: "Emergency",
      sortable: true,
      minWidth: "15%",
      cell: (row) => (
        <div
          className="d-flex justify-content-left align-items-center"
          style={{ whiteSpace: "normal", minWidth: "150px" }}
        >
          <div className="d-flex flex-column">
            <span className="fw-bold text-truncate">
              {row.emergencyContactName}
            </span>
            <small
              className="text-truncate text-muted mb-0 text-truncate"
              style={{ wordWrap: "break-word", maxWidth: "100%" }}
            >
              {row.emergencyContact}
            </small>
          </div>
        </div>
      ),
    },

    {
      name: "Status",
      sortable: true,
      sortField: "status",
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() === "va" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
          >
            <Link
              to={`/hrm/staff/update/${row._id}`}
              size="sm"
              color="flat-primary"
            >
              <Edit className="font-medium-2" />
            </Link>
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>

          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Statistics />
      <CustomDataTable
        columns={columns}
        title="Staffs"
        fetchData={useGetStaffsQuery}
        searchFields={["name", "email", "contacts"]}
        populate={[
          {
            path: "department",
            dir: "hrm/departments",
            select: "name",
          },
          {
            path: "job",
            dir: "hrm/jobs",
            select: "name",
          },
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"Add New Staff"}
        addButtonClick={() => navigate("/hrm/staff/create")}
      />
    </div>
  );
};

export default Staff;
