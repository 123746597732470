import React, { useEffect, useState } from "react";
import {
  Pinterest,
  Reddit,
  Skype,
  Snapchat,
  Tiktok,
  Vimeo,
} from "react-bootstrap-icons";
import {
  Facebook,
  GitHub,
  Instagram,
  Linkedin,
  Plus,
  Trash,
  Twitter,
  Youtube,
} from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import FileUploaderSingle from "../../../common/utils/components/SingleFileUploader";
import { ErrorHandler } from "../../../common/utils/Error";
import axios from "axios";
import toast from "react-hot-toast";
import themeConfig from "../../../configs/themeConfig";
themeConfig;
const socialMedia = [
  {
    title: "Facebook",
    icon: <Facebook size={20} />,
    url: "https://www.facebook.com/",
  },
  {
    title: "Twitter",
    icon: <Twitter size={20} />,
    url: "https://twitter.com/",
  },
  {
    title: "Instagram",
    icon: <Instagram size={20} />,
    url: "https://www.instagram.com/",
  },
  {
    title: "LinkedIn",
    icon: <Linkedin size={20} />,
    url: "https://www.linkedin.com/in/",
  },
  { title: "GitHub", icon: <GitHub size={20} />, url: "https://github.com/" },
  {
    title: "YouTube",
    icon: <Youtube size={20} />,
    url: "https://www.youtube.com/",
  },
  { title: "Skype", icon: <Skype size={20} />, url: "https://www.skype.com/" },
  {
    title: "TikTok",
    icon: <Tiktok size={20} />,
    url: "https://www.tiktok.com/",
  },
  {
    title: "Pinterest",
    icon: <Pinterest size={20} />,
    url: "https://www.pinterest.com/",
  },
  {
    title: "Snapchat",
    icon: <Snapchat size={20} />,
    url: "https://www.snapchat.com/",
  },
  {
    title: "Reddit",
    icon: <Reddit size={20} />,
    url: "https://www.reddit.com/",
  },
  { title: "Vimeo", icon: <Vimeo size={20} />, url: "https://vimeo.com/" },
];

const WebSetting = ({ webSetting }) => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState([]);
  const [favicon, SetFavicon] = useState([]);
  const [options, setOptions] = useState(socialMedia);

  const defaultValues = {
    title: "",
    socialMedia: [],
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const {
    fields: items,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "socialMedia",
  });

  const onDiscard = () => {
    reset();
    setValue("socialMedia", []);
  };

  const [dropdownOpen, setDropdownOpen] = useState(
    Array(items.length).fill(false)
  );

  const toggleDropDown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      var formData = new FormData();

      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((element, index) => {
            formData.append(
              `${key}[${index}]`,
              JSON.stringify({
                title: element.title,
                url: element.url,
              })
            );
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      if (favicon.length && typeof favicon[0] !== "string")
        formData.append(`favicon`, favicon[0], favicon[0].name);

      if (logo.length && typeof logo[0] !== "string")
        formData.append(`logo`, logo[0], logo[0].name);

      //
      var { status, data } = await axios.post(
        "/settings/web-settings",
        formData
      );
      if (status == 200) {
        toast.success("Web Settings has being set");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(webSetting);

    setLogo([themeConfig.serverUrl + "uploads/" + webSetting.logo]);
    SetFavicon([themeConfig.serverUrl + "uploads/" + webSetting.favicon]);
  }, [webSetting]);

  const getIcon = (title) => {
    const icon = socialMedia.find((items) => items.title === title)?.icon;
    return icon;
  };

  return (
    <div>
      <h1>Web Settings</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <div className="mb-1">
          <Label className="form-label" for="title">
            Website Title
          </Label>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input
                id="title"
                placeholder="Title"
                {...register("title", { required: true }, "Title is required")}
                invalid={errors.title && true}
                {...field}
              />
            )}
          />
          {errors.title && (
            <FormFeedback>Please enter a valid Name</FormFeedback>
          )}
        </div>

        <div className="mb-1">
          <Label className="form-label" for="address">
            Address
          </Label>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input
                id="address"
                placeholder="Addess"
                {...register(
                  "address",
                  { required: true },
                  "Address is required"
                )}
                invalid={errors.address && true}
                {...field}
              />
            )}
          />
          {errors.address && (
            <FormFeedback>Please enter a valid Name</FormFeedback>
          )}
        </div>

        <div className="mb-1">
          <Label className="form-label" for="phone">
            Phone
          </Label>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                id="phone"
                placeholder="Phone"
                {...register("phone", { required: true }, "Phone is required")}
                invalid={errors.phone && true}
                {...field}
              />
            )}
          />
          {errors.phone && (
            <FormFeedback>Please enter a valid Name</FormFeedback>
          )}
        </div>

        <div className="mb-1">
          <Label className="form-label" for="email">
            E-Mail
          </Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                id="email"
                placeholder="E-Mail"
                {...register("email", { required: true }, "E-Mail is required")}
                invalid={errors.email && true}
                {...field}
              />
            )}
          />
          {errors.email && (
            <FormFeedback>Please enter a valid Name</FormFeedback>
          )}
        </div>

        <div className="mb-1">
          <Label className="form-label" for="aboutUs">
            About Us
          </Label>
          <Controller
            name="aboutUs"
            control={control}
            render={({ field }) => (
              <Input
                type="textarea"
                id="aboutUs"
                {...register(
                  "aboutUs",
                  { required: true },
                  "About Us is required"
                )}
                rows="3"
                placeholder="About Us"
                invalid={errors.aboutUs && true}
                {...field}
              />
            )}
          />
          {errors.aboutUs && (
            <FormFeedback>Please enter a valid About Us</FormFeedback>
          )}
        </div>

        <div className="mb-1">
          <Row style={{ width: "100%" }}>
            <Col md={6} sm={12}>
              <div className="mb-1">
                <Label className="form-label" for="email">
                  Social Media
                </Label>
                {items.map((item, index) => (
                  <Col md="12" className="mt-1">
                    <InputGroup>
                      <Dropdown
                        isOpen={dropdownOpen[index]}
                        toggle={() => toggleDropDown(index)}
                      >
                        <DropdownToggle color="primary" caret outline>
                          {getIcon(getValues(`socialMedia.${index}.title`))}
                        </DropdownToggle>
                        <DropdownMenu>
                          {options.map((element) => (
                            <DropdownItem
                              key={element.title}
                              className="w-100"
                              onClick={(e) => {
                                e.preventDefault();

                                //   setOptions(
                                //     options.filter(
                                //       (item) => item.title !== element.title
                                //     )
                                //   );

                                setValue(
                                  `socialMedia.${index}.url`,
                                  element.url
                                );
                                setValue(
                                  `socialMedia.${index}.title`,
                                  element.title
                                );
                              }}
                            >
                              {element.title}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>

                      <Controller
                        name={`socialMedia.${index}.url`}
                        control={control}
                        rules={{ required: true, min: 1 }}
                        render={({ field }) => (
                          <Input
                            type="text"
                            placeholder="url"
                            innerRef={field.ref}
                            {...field}
                            invalid={
                              errors.items?.length &&
                              errors?.items[index]?.url &&
                              true
                            }
                            onChange={(e) => {
                              const url = socialMedia.find((item) =>
                                getValues(
                                  `socialMedia.${index}.url`
                                ).startsWith(item.url)
                              )?.url;

                              if (e.target.value.startsWith(url)) {
                                field.onChange(e);
                              }
                            }}
                          />
                        )}
                      />

                      <Button
                        type="button"
                        color="danger"
                        outline
                        onClick={() => {
                          const selected = items.findIndex(
                            (field) => field.id == item.id
                          );

                          // const removedItem = items[selected];
                          // const i = socialMedia.find(
                          //   (item) => item.title == removedItem.title
                          // );
                          // console.log(removedItem);

                          // // if (selected.title !== "") {
                          // //   setOptions([...options, i]);
                          // // }
                          remove(selected);
                        }}
                      >
                        <Trash size={15} />
                      </Button>
                    </InputGroup>
                  </Col>
                ))}

                <Col sm="12" className="px-0 mt-2">
                  <Button
                    color="primary"
                    size="sm"
                    className="btn-add-new"
                    onClick={() =>
                      append({
                        title: "",
                        url: "",
                      })
                    }
                  >
                    <Plus size={14} className="me-25"></Plus>
                    <span className="align-middle">Add Item</span>
                  </Button>
                </Col>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <Row>
                <Col md={6}>
                  <Label className="form-label col-md-5" for="aboutUs">
                    Logo
                  </Label>
                  <FileUploaderSingle
                    files={logo}
                    setFiles={setLogo}
                    text="upload a logo of 100x100"
                  />
                </Col>
                <Col md={6}>
                  <Label className="form-label" for="aboutUs">
                    Fav Icon
                  </Label>
                  <FileUploaderSingle
                    files={favicon}
                    setFiles={SetFavicon}
                    text="upload a logo of 100x100"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/*  */}
        <div className="d-flex justify-content-center mt-4">
          <Button
            type="submit"
            className="me- btn-lg"
            color="primary"
            disabled={loading}
          >
            <Spinner
              color="light"
              size="sm"
              className="me-1"
              hidden={!loading}
            />
            {loading ? "Saving" : "Save"}
          </Button>
          {/* <Button
            type="reset"
            className=""
            color="dark"
            outline
            onClick={onDiscard}
          >
            Discard
          </Button> */}
        </div>
      </form>
    </div>
  );
};

export default WebSetting;
