import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  CardHeader,
  Card,
  CardTitle,
  CardBody,
  Table,
  FormFeedback,
  Spinner,
} from "reactstrap";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select/creatable";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateContractMutation,
  useGetContractSalaryAllowancesQuery,
  useGetSingleContractQuery,
  useUpdateContractMutation,
} from "../../../redux/contracts";
import { useGetContractTypesListQuery } from "../../../redux/contractTypes";
import { useGetStaffsListQuery } from "../../../redux/staff";
import { Trash2, PlusCircle } from "react-feather";
import DividerWithText from "../../../common/components/DividerWithText";
import moment from "moment";
import { useGetSalaryAllowanceTypesListQuery } from "../../../redux/salaryAllowanceTypes";
import { ExtractError } from "../../../common/utils/Error";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
  staff: yup.string().required("Staff is required"),
  contractType: yup.string().required("Contract Type is required"),
  effectiveDate: yup.string().required("Effective Date is required"),
  expireDate: yup.string().required("Expire Date is required"),
  status: yup.string().required("Status is required"),
  remunerationType: yup.string().required("Salary Type is required"),
});

const defaultValues = {
  staff: "",
  contractType: "",
  effectiveDate: "",
  expireDate: "",
  status: "Draft",
  remunerationType: "fixed",
  signatureDate: moment().format("YYYY-MM-DD"),
  salaryAllowance: [
    { id: null, type: "", amount: "", effectiveDate: "", note: "" },
  ],
};

const ContractForm = () => {
  const selectedRow = null;
  const navigate = useNavigate();

  //* Data fetching
  const { data: staffs, isLoading: StaffLoading } = useGetStaffsListQuery();
  const { data: contractTypes, isLoading: contractTypesLoading } =
    useGetContractTypesListQuery();
  const { data: remunerationTypes } = useGetSalaryAllowanceTypesListQuery();

  const staffOptions = staffs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const contractTypesOptions = contractTypes?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const [
    createContract,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateContractMutation();
  const [
    updateContract,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateContractMutation();

  //*
  const { id } = useParams();
  const {
    data: contractData,
    error,
    isLoading,
  } = useGetSingleContractQuery(id, {
    skip: !id, // Skip the query if id doesn't exist
  });
  const { data: salaryAllowances, isLoading: loading } =
    useGetContractSalaryAllowancesQuery(id, {
      skip: !id,
    });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "salaryAllowance",
  });

  const handleTypeChange = (index, type) => {
    const selectedType = remunerationTypes.find((item) => item.name === type);
    setValue(
      `salaryAllowance[${index}].amount`,
      selectedType ? selectedType.amount : ""
    );
    setValue(
      `salaryAllowance[${index}].id`,
      selectedType ? selectedType._id : ""
    );
    setValue(`salaryAllowance[${index}].type`, type);
    setValue(
      `salaryAllowance[${index}].effectiveDate`,
      selectedType ? moment().format("YYYY-MM-DD") : ""
    );
    setValue(`salaryAllowance[${index}].note`, "");
  };

  const handleAddRow = () => {
    append({ id: null, type: "", amount: "", effectiveDate: "", note: "" });
  };

  const handleDeleteRow = (index) => {
    remove(index);
  };

  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateContract({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Contract Updated successfully!");
          handleDiscard();
        }
      } else {
        const { data: redData, error } = await createContract(data);

        if (!error) {
          toast.success("Contract added successfully!");
          handleDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    navigate("/hrm/contracts");
  };

  const isTypeSelected = (id) => fields.some((row) => row.id === id);

  //* Set Values
  useEffect(() => {
    if (contractData) {
      reset({
        ...contractData,
        expireDate: moment(contractData?.expireDate).format("YYYY-MM-DD"),
        effectiveDate: moment(contractData?.effectiveDate).format("YYYY-MM-DD"),
        signatureDate: moment(contractData?.signatureDate).format("YYYY-MM-DD"),
      });
    }
  }, [contractData]);

  useEffect(() => {
    const data = salaryAllowances?.map((item) => ({
      id: item._id,
      type: item.type?.name,
      effectiveDate: moment(item.effectiveDate).format("YYYY-MM-DD"),
      amount: item.amount,
      notice: item.notice,
    }));
    data && reset((prevState) => ({ ...prevState, salaryAllowance: data }));
  }, [salaryAllowances]);

  if (isLoading || loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Card className="pb-4">
      <CardHeader>
        <CardTitle>{id ? "Update Contract" : "New Contract"}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <DividerWithText>General information</DividerWithText>
            <Col sm={12} md={6}>
              <Label className="form-label" for="job">
                Staff
              </Label>
              <Controller
                name="staff"
                control={control}
                render={({ field }) => (
                  <Select
                    id="staff"
                    className={`react-select ${
                      errors.staff ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.staff && true}
                    placeholder="Choose Staff"
                    options={staffOptions}
                    {...field}
                    value={staffOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selected) => field.onChange(selected.value)}
                    isLoading={StaffLoading}
                  />
                )}
              />
              {errors.staff && (
                <FormFeedback>{errors.staff.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="contractType">
                Contract Type
              </Label>
              <Controller
                name="contractType"
                control={control}
                render={({ field }) => (
                  <Select
                    id="contractType"
                    className={`react-select ${
                      errors.contractType ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.contractType && true}
                    placeholder="Choose Contract Type"
                    options={contractTypesOptions}
                    {...field}
                    value={contractTypesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selected) => field.onChange(selected.value)}
                    isLoading={contractTypesLoading}
                  />
                )}
              />
              {errors.contractType && (
                <FormFeedback>{errors.contractType.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6} className="my-1">
              <Label className="form-label" for="effectiveDate">
                Effective Date
              </Label>
              <Controller
                control={control}
                name="effectiveDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    placeholder="Effective Date"
                    invalid={!!errors.effectiveDate}
                  />
                )}
              />
              {errors.effectiveDate && (
                <FormFeedback>{errors.effectiveDate.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6} className="my-1">
              <Label className="form-label" for="expireDate">
                Expire Date
              </Label>
              <Controller
                control={control}
                name="expireDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    placeholder="Expire Date"
                    invalid={!!errors.expireDate}
                  />
                )}
              />
              {errors.expireDate && (
                <FormFeedback>{errors.expireDate.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6} className="my-1">
              <Label className="form-label" for="status">
                Status
              </Label>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Input
                    type="select"
                    {...field}
                    placeholder="Status"
                    invalid={!!errors.status}
                  >
                    <option value="Draft">Draft</option>
                    <option value="Valid">Valid</option>
                    <option value="Expired">Expired</option>
                    <option value="Finished">Finished</option>
                  </Input>
                )}
              />
              {errors.status && (
                <FormFeedback>{errors.status.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6} className="my-1">
              <Label className="form-label" for="remunerationType">
                Remuneration Type
              </Label>
              <Controller
                control={control}
                name="remunerationType"
                render={({ field }) => (
                  <Input
                    type="select"
                    {...field}
                    placeholder="Remuneration Type"
                    invalid={!!errors.remunerationType}
                  >
                    <option value="fixed">Fixed</option>
                    <option value="hour rate">Hour Rate</option>
                  </Input>
                )}
              />
              {errors.remunerationType && (
                <FormFeedback>{errors.remunerationType.message}</FormFeedback>
              )}
            </Col>
          </Row>

          <Row className="table-responsive my-2">
            <DividerWithText>Salary & allowances</DividerWithText>

            <Table bordered>
              <thead>
                <tr>
                  <th style={{ minWidth: "300px" }}>Salary/Allowance</th>
                  <th style={{ minWidth: "200px" }}>Amount</th>
                  <th style={{ minWidth: "200px" }}>Effective Date</th>
                  <th style={{ minWidth: "400px" }}>Note</th>
                  <th style={{ minWidth: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <Controller
                        name={`salaryAllowance[${index}].type`}
                        control={control}
                        defaultValue={item.type}
                        render={({ field }) => (
                          <Input
                            type="select"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              handleTypeChange(index, e.target.value);
                            }}
                          >
                            <option value="">Select Type</option>
                            {remunerationTypes?.map((item, idx) => (
                              <option
                                key={idx}
                                value={item.name}
                                disabled={isTypeSelected(item._id)}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`salaryAllowance[${index}].amount`}
                        control={control}
                        defaultValue={item.amount}
                        render={({ field }) => (
                          <Input
                            type="number"
                            {...field}
                            defaultValue={item.amount}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`salaryAllowance[${index}].effectiveDate`}
                        control={control}
                        defaultValue={item.effectiveDate}
                        render={({ field }) => (
                          <Input
                            type="date"
                            {...field}
                            defaultValue={item.effectiveDate}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`salaryAllowance[${index}].note`}
                        control={control}
                        defaultValue={item.note}
                        render={({ field }) => <Input type="text" {...field} />}
                      />
                    </td>
                    <td>
                      <Trash2
                        onClick={() => handleDeleteRow(index)}
                        className="text-danger"
                        type="button"
                        size={20}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="d-flex mt-2">
              <Button type="button" onClick={handleAddRow}>
                <PlusCircle size={20} />
                <span className="ms-1"> Add New</span>
              </Button>
            </div>
          </Row>

          <Row className="my-2">
            <DividerWithText>Signing information</DividerWithText>
            <Col xs={12} md={6}>
              <Label className="form-label" for="signatureDate">
                Signature Date
              </Label>
              <Controller
                control={control}
                name="signatureDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    placeholder="Effective Date"
                    invalid={!!errors.signatureDate}
                  />
                )}
              />
              {errors.signatureDate && (
                <FormFeedback>{errors.signatureDate.message}</FormFeedback>
              )}
            </Col>
            <Col sm={12} md={6}>
              <Label className="form-label" for="signedBy">
                Signed By
              </Label>
              <Controller
                name="signedBy"
                control={control}
                render={({ field }) => (
                  <Select
                    id="signedBy"
                    className={`react-select ${
                      errors.signedBy ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.signedBy && true}
                    placeholder="Choose Staff"
                    options={staffOptions}
                    {...field}
                    value={staffOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selected) => field.onChange(selected.value)}
                    isLoading={StaffLoading}
                  />
                )}
              />
              {errors.signedBy && (
                <FormFeedback>{errors.signedBy.message}</FormFeedback>
              )}
            </Col>
          </Row>

          <Col xs={12} className="mt-2">
            <Button
              className="me-1"
              color="primary"
              type="submit"
              disabled={isCreating || isUpdating}
            >
              {isCreating || isUpdating ? "Please, wait..." : "Save"}
            </Button>
            <Button
              outline
              onClick={handleDiscard}
              disabled={isCreating || isUpdating}
            >
              Cancel
            </Button>
          </Col>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ContractForm;
