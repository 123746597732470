import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const payrollApi = createApi({
  reducerPath: "payroll",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["payroll", "payroll-details"],
  endpoints: (builder) => ({
    // Read
    getAllPayrolls: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/payroll?${serializedParams}`;
      },
      providesTags: ["payroll"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    //single payroll
    getSinglePayroll: builder.query({
      query: ({ id, params }) => {
        const serializedParams = createQueryString(params);
        return `/hrm/payroll/${id}?${serializedParams}`;
      },
      transformResponse: (response) => {
        return response?.data;
      },
      keepUnusedDataFor: 0,
    }),

    // Create
    createPayroll: builder.mutation({
      query: (data) => ({
        url: "/hrm/payroll",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["payroll"],
    }),

    // Update
    updatePayroll: builder.mutation({
      query: ({ data: staffs, id }) => ({
        url: `/hrm/payroll/${id}`,
        method: "PATCH",
        body: staffs,
      }),
      invalidatesTags: ["payroll"],
    }),

    // Delete
    deletePayroll: builder.mutation({
      query: (id) => ({
        url: `/hrm/payroll/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["payroll"],
    }),

    // Generate Payroll Details
    getPayrollDetails: builder.query({
      query: (id) => `/hrm/payroll-details/${id}`,
      providesTags: ["payroll-details"],
    }),

    // Save Payroll Details
    savePayrollDetails: builder.mutation({
      query: (data) => ({
        url: `/hrm/payroll-details/save`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["payroll-details"],
    }),
  }),
});

export const {
  useGetAllPayrollsQuery,
  useGetSinglePayrollQuery,
  useCreatePayrollMutation,
  useUpdatePayrollMutation,
  useDeletePayrollMutation,

  useGetPayrollDetailsQuery,
  useSavePayrollDetailsMutation,
} = payrollApi;
