import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const contractApi = createApi({
  reducerPath: "contracts",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["contracts"],
  endpoints: (builder) => ({
    // Read
    getContracts: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/contracts?${serializedParams}`;
      },
      providesTags: ["contracts"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getSingleContract: builder.query({
      query: (id) => `/hrm/contracts/${id}`,
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getContractSalaryAllowances: builder.query({
      query: (id) => `/hrm/contracts/${id}/salary-allowance`,
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createContract: builder.mutation({
      query: (data) => ({
        url: "/hrm/contracts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contracts"],
    }),

    // Update
    updateContract: builder.mutation({
      query: ({ data: contracts, id }) => ({
        url: `/hrm/contracts/${id}`,
        method: "PATCH",
        body: contracts,
      }),
      invalidatesTags: ["contracts"],
    }),

    // Delete
    deleteContract: builder.mutation({
      query: (id) => ({
        url: `/hrm/contracts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["contracts"],
    }),
  }),
});

export const {
  useCreateContractMutation,
  useDeleteContractMutation,
  useUpdateContractMutation,
  useGetContractsQuery,
  useGetSingleContractQuery,
  useGetContractSalaryAllowancesQuery,
} = contractApi;
