import React, { forwardRef, Fragment, useEffect } from "react";
import { Card, CardBody, Input, Row, CardTitle, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import { ChevronDown } from "react-bootstrap-icons";
import CustomHeader from "./CustomHeader";
import CustomPagination from "./CustomPagination";
import Loading2 from "./Loading";
import useTableData from "./useTableData";
// Custom hook

const CustomDataTable = ({
  columns,
  title,
  fetchData,
  searchFields,
  query,
  populate,
  buttonLabel,
  addButtonClick,
  expandableRows = false,
  selectableRows = false,
}) => {
  const {
    data,
    isLoading,
    isFetching,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    setSortBy,
    search,
    setSearch,
  } = useTableData(fetchData, searchFields, query, populate);

  const BootstrapCheckbox = forwardRef((props, ref) => (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  return (
    <Fragment>
      <Row className="app-user-list">
        <Card className="overflow-hidden">
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <CardTitle tag="h4">{title}</CardTitle>
              <Button
                className="add-permission mt-sm-0 mt-1"
                color="primary"
                onClick={addButtonClick}
              >
                {buttonLabel}
              </Button>
            </div>
            <div className="react-dataTable table-responsive position-relative">
              <DataTable
                noHeader
                subHeader
                pagination
                paginationServer
                responsive
                highlightOnHover
                expandableRows={expandableRows}
                selectableRows={selectableRows}
                columns={columns}
                paginationPerPage={rowsPerPage}
                className="react-dataTable"
                selectableRowsComponent={BootstrapCheckbox}
                data={data?.docs || []}
                selectableRowsHighlight
                subHeaderComponent={
                  <CustomHeader
                    searchTerm={search}
                    setSearchTerm={setSearch}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                }
                paginationComponent={() => (
                  <CustomPagination
                    data={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
                sortServer
                sortIcon={<ChevronDown size={10} />}
                onSort={(column, direction) => {
                  if (column.sortField) {
                    setSortBy({ [column.sortField]: direction });
                  }
                }}
                progressPending={isLoading}
                progressComponent={<Loading2 cols={columns} />}
              />
              {isFetching && !isLoading && (
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Row>
    </Fragment>
  );
};

export default CustomDataTable;
