import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
} from "reactstrap";
import * as yup from "yup";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select/creatable";
import {
  useCreateDeductionListMutation,
  useUpdateDeductionListMutation,
} from "../../../redux/deductionList"; // Adjust import path as necessary
import { useGetSalaryAllowanceTypesListQuery } from "../../../redux/salaryAllowanceTypes";

const defaultValues = {
  description: "",
  amount: "",
  amountType: "fixed",
  basis: null,
  status: "active",
};

const DeductionListModal = ({ show, setShow, selectedRow, setSelectedRow }) => {
  const { data: options, isLoading: optionsLoading } =
    useGetSalaryAllowanceTypesListQuery();

  const basisOptions = [
    { label: "Gross", value: null },
    ...(options
      ? options.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      : []),
  ];

  const [
    createDeductionList,
    { isLoading: isCreating, isError: createError, error: createErrorMessage },
  ] = useCreateDeductionListMutation();
  const [
    updateDeductionList,
    { isLoading: isUpdating, isError: updateError, error: updateErrorMessage },
  ] = useUpdateDeductionListMutation();

  const schema = yup.object().shape({
    description: yup.string().required("Description is required"),
    amount: yup.number().required("Amount is required").positive(),
    amountType: yup
      .string()
      .oneOf(["fixed", "percentage"])
      .required("Amount Type is required"),
    basis: yup.string().nullable(),
    status: yup
      .string()
      .oneOf(["active", "inactive"])
      .required("Status is required"),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateDeductionList({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Deduction List Updated successfully!");
          handleDiscard();
        }
      } else {
        const res = await createDeductionList(data).unwrap();
        if (res) {
          toast.success("Deduction List added successfully!");
          handleDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  useEffect(() => {
    if (createError || updateError) {
      const message =
        createErrorMessage?.data?.message ||
        updateErrorMessage?.data?.message ||
        "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
      });
    }
  }, [selectedRow, reset]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">
          {selectedRow ? "Edit Deduction" : "Add New Deduction"}
        </span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              <Label className="form-label" for="description">
                Description
              </Label>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Description"
                    invalid={!!errors.description}
                  />
                )}
              />
              {errors.description && (
                <FormFeedback>{errors.description.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-1">
              <Label className="form-label" for="amount">
                Amount
              </Label>
              <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Amount"
                    type="number"
                    invalid={!!errors.amount}
                  />
                )}
              />
              {errors.amount && (
                <FormFeedback>{errors.amount.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-1">
              <Label className="form-label" for="amountType">
                Amount Type
              </Label>
              <Controller
                control={control}
                name="amountType"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="select"
                    placeholder="Amount Type"
                    invalid={!!errors.amountType}
                  >
                    <option value="fixed">Fixed</option>
                    <option value="percentage">Percentage</option>
                  </Input>
                )}
              />
              {errors.amountType && (
                <FormFeedback>{errors.amountType.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-1">
              <Label className="form-label" for="basis">
                Basis
              </Label>
              <Controller
                control={control}
                name="basis"
                render={({ field }) => (
                  <Select
                    id="basis"
                    className={`react-select ${
                      errors.basis ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.basis && true}
                    placeholder="Choose Basis"
                    options={basisOptions}
                    {...field}
                    value={basisOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selected) => field.onChange(selected.value)}
                    isLoading={optionsLoading}
                  />
                )}
              />
              {errors.basis && (
                <FormFeedback>{errors.basis.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-1">
              <Label className="form-label" for="status">
                Status
              </Label>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="select"
                    placeholder="Status"
                    invalid={!!errors.status}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                )}
              />
              {errors.status && (
                <FormFeedback>{errors.status.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="mt-2">
              <Button
                className="me-1"
                color="primary"
                type="submit"
                disabled={isCreating || isUpdating}
              >
                {isCreating || isUpdating ? "Please, wait..." : "Save"}
              </Button>
              <Button
                outline
                onClick={handleDiscard}
                disabled={isCreating || isUpdating}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DeductionListModal;
