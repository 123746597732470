// ** React Imports
import { Download, Paperclip, Printer, SendCheck } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import useStorage from "../../../utility/hooks/useStorage";
import { MoreVertical } from "react-feather";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const PreviewActions = ({
  id,
  setSendSidebarOpen,
  setAddPaymentOpen,
  data,
  handlePrint,
  handleToPDF,
}) => {
  const { setItem } = useStorage();
  return (
    <Card className="invoice-action-wrapper">
      <CardBody>
        {/* here */}
        <UncontrolledDropdown>
          {/* <DropdownToggle tag="div" className="mb-75 w-100">
            <Button
              color="primary"
              block
              className="mb-75"
              onClick={() => setSendSidebarOpen(true)}
            >
              <SendCheck className="me-1" />
              Send Invoice
            </Button>
          </DropdownToggle> */}
          <DropdownMenu className="w-100">
            <DropdownItem
              tag="a"
              href="/"
              className="w-100"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <WhatsappShareButton>
                <WhatsappIcon
                  className="me-1"
                  size={25}
                  url={"www.google.com"}
                  title={"whatsappMessage"}
                />
                Whatsapp
              </WhatsappShareButton>
            </DropdownItem>
            <DropdownItem
              tag="a"
              href="/"
              className="w-100"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <EmailIcon className="me-1" size={25} />
              Email
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <Button
          color="secondary"
          block
          outline
          className="mb-75"
          onClick={handleToPDF}
        >
          <Download className="me-1" />
          Download
        </Button>
        <Button
          to="print"
          block
          outline
          className="mb-75 btn btn-outline-secondary d-block"
          onClick={() => handlePrint()}
        >
          <Printer className="me-1" />
          Print
        </Button>
        {/* <Button tag={Link} to={`/apps/invoice/edit/${id}`} color='secondary' block outline className='mb-75'>
          Edit
        </Button> */}
        {/* {
          data.status.toLowerCase() != 'paid' ?
           <Button color="success" block onClick={() =>{}}>
         Make Payment
        </Button>
          :null
        } */}
      </CardBody>
    </Card>
  );
};

export default PreviewActions;
