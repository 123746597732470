// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { Controller, useForm } from "react-hook-form";

// ** Styles
import Select from "react-select";

import { ErrorHandler } from "../../../common/utils/Error";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CancelModel = ({
  show,
  onClose,
  application,
  submitHandler,
  cancelModelLoading,
  setCancelModelLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  const defaultValues = {
    date: new Date().now,
    bookingId: application?._id,
    account: "",
    amount: "",
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/hierarchy/64089b489d122d411c67abe4"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue("date", new Date().now);
    setValue("bookingId", application?._id);
    getAccounts();
  }, [show]);

  const onSubmit = async (data) => {
    try {
      setCancelModelLoading(true);
      if (data.account) {
        data.account = data.account.value;
      }
      if (data.refundType) {
        data.refundType = data.refundType.value ?? "full";
      }
      submitHandler(data);
    } catch (error) {
      ErrorHandler(error);
      setCancelModelLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        centered
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Make Refund
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="amount">
                  Refund Amount
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="amount"
                      type="number"
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter Invoice Date</FormFeedback>
                )}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="account">
                  Credited From
                </Label>
                <Controller
                  name="account"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="account"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.account && true}
                      {...register("account", { required: true })}
                      {...field}
                      options={accounts.map((ac) => ({
                        value: ac._id,
                        label: `${ac.name}`,
                        isDisabled: ac.systemAccount,
                      }))}
                    />
                  )}
                />
                {errors.account && (
                  <FormFeedback>Please enter account</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="date">
                  Date
                </Label>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.date && true}
                      {...field}
                    />
                  )}
                />
                {errors.date && (
                  <FormFeedback>Please enter Receipt Date</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              //   disabled={(!row && !rows.length) || loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!cancelModelLoading}
              />
              {cancelModelLoading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CancelModel;
