import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const departmentsApi = createApi({
  reducerPath: "departments",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["departments", "departmentsList"],
  endpoints: (builder) => ({
    // Read
    getDepartments: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/departments?${serializedParams}`;
      },
      providesTags: ["departments"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getDepartmentsList: builder.query({
      query: () => {
        return `/hrm/departments/list`;
      },
      providesTags: ["departmentsList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createDepartment: builder.mutation({
      query: (data) => ({
        url: "/hrm/departments",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["departments", "departmentsList"],
    }),

    // Update
    updateDepartment: builder.mutation({
      query: ({ data: departments, id }) => ({
        url: `/hrm/departments/${id}`,
        method: "PATCH",
        body: departments,
      }),
      invalidatesTags: ["departments", "departmentsList"],
    }),

    // Delete
    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: `/hrm/departments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["departments", "departmentsList"],
    }),
  }),
});

export const {
  useGetDepartmentsListQuery,
  useGetDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentsApi;
