import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
} from "reactstrap";
import customer, { updateCustomer } from "../../../redux/customer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";

const Detail = ({ store }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const defaultValues = {
    id: store?.data?.customerInfo?._id || "",
    name: store?.data?.customerInfo?.name || "",
    phone: store?.data?.customerInfo?.phone || "",
    email: store?.data?.customerInfo?.email || "",
    gender: store?.data?.customerInfo?.gender || "male",
    address: store?.data?.customerInfo?.address || "",
    status: store?.data?.customerInfo?.status || "",
  };

  const {
    register,
    reset,
    getValues,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (data.gender) data.gender = data.gender.value;
      if (data.status) data.status = data.status.value;

      const updateResponse = await HandleDispatch(
        dispatch,
        updateCustomer({ id: data.id, data })
      );

      if (updateResponse) {
        toast.success("Customer Information updated Successfully");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const genderMapping = {
      male: { label: "Male", value: "male" },
      female: { label: "Female", value: "female" },
    };

    const statusMapping = {
      active: { label: "Active", value: "active" },
      inactive: { label: "Inactive", value: "inactive" },
    };

    const mapValue = (sourceValue, mapping) => {
      return mapping[sourceValue] || {};
    };

    let values = {
      ...defaultValues,
      gender: mapValue(defaultValues?.gender, genderMapping),
      status: mapValue(defaultValues?.status, statusMapping),
    };

    reset(values);
  }, [store]);
  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">User's Projects List</CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 gx-2">
              <Col xs={12}>
                <Label className="form-label" for="name">
                  Customer Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Customer name"
                      {...register(
                        "name",
                        { required: true },
                        "Customer Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>

              <Col xs={6}>
                <Label className="form-label" for="gender">
                  Gender
                </Label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      id="gender"
                      {...register(
                        "gender",
                        { required: true },
                        "Gender is required"
                      )}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      invalid={errors.gender && true}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>

              <Col xs={6}>
                <Label className="form-label" for="status">
                  Status
                </Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      id="status"
                      {...register(
                        "status",
                        { required: true },
                        "Status is required"
                      )}
                      options={[
                        { label: "Active", value: "active" },
                        { label: "InActive", value: "inactive" },
                      ]}
                      invalid={errors.status && true}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>

              <Col xs={6}>
                <Label className="form-label" for="phone">
                  Phone
                </Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="phone"
                      type="tell"
                      placeholder="+2526XX"
                      {...register(
                        "phone",
                        { required: true },
                        "Phone Number is required"
                      )}
                      invalid={errors.phone && true}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col xs={6}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="email"
                      type="email"
                      placeholder="admin@rukun.so"
                      {...register("email")}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="address">
                  Address
                </Label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="address"
                      placeholder="Hodan"
                      {...register(
                        "address",
                        { required: true },
                        "Address is required"
                      )}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
            </Row>

            <div className="d-flex justify-content-center mt-2">
              <Button
                type="submit"
                className="me-1"
                color="primary"
                disabled={loading}
              >
                {loading ? "Updating" : "Update"}
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Detail;
