// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { Controller, useForm } from "react-hook-form";
import axios from "axios";
// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import moment from "moment";
import { useEffect } from "react";
import { ErrorHandler } from "../../../common/utils/Error";
import CustomDispatch from "../../../common/utils/HandledDispatch";
import { getAllagreements, renewAgreement } from "../../../redux/agreements";

const RenewAgreement = ({ show, onClose, row }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const defaultValues = {
    startDate: moment(row?.endDate).format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const {
    register,
    reset,
    control,
    setError,
    getValues,
    setFocus,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (row) {
      reset({
        startDate: moment(row?.endDate).format("YYYY-MM-DD"),
        endDate: "",
      });
    }
  }, [row]);

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      if (!row) return toast.error("Please Select Agreement");

      const formData = { ...data, agreement: row._id };

      const agr = await axios.post("/agreements/renew", {
        ...formData,
      });
      if (agr) {
        if (data.startDate != moment(agr.startDate).format("YYYY-MM-DD")) {
          toast.success(`Agreement (${row.agreementName}) Has been renewed `);
          reset();
          onClose();
        } else {
          toast.success(`Agreement (${row.agreementName}) Has been Extended `);
          reset();
          onClose();
        }

        dispatch(getAllagreements({ building: row?.building?._id }));
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ??
        error?.response?.data ??
        error.message ??
        "Something went wrong, please try again";

      // ErrorHandler(error);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }

    return;
    try {
      // wen you wanna renew a leases this function will terminate it...!
      if (row) {
        data = { ...data, agreement: row._id };
        var agr = await CustomDispatch(dispatch, renewAgreement(data));
        if (agr) {
          if (data.startDate != moment(agr.startDate).format("YYYY-MM-DD")) {
            toast.success(`Agreement (${row.agreementName}) Has been renewed `);
            reset();
            onClose();
          } else {
            toast.success(
              `Agreement (${row.agreementName}) Has been Extended `
            );
            reset();
            onClose();
          }
        }
      } else {
        toast.error("Please Select Agreement");
        reset();
        onClose();
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setLoading(false);
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        centered
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Extend Agreement
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}
            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row className="gy-1 gx-2 flex-column">
              {moment(row?.endDate).diff(moment(), "days") >= 1 ? (
                <>
                  <Col xs={12}>
                    <Label className="form-label" for="endDate">
                      Extend End Date
                    </Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="endDate"
                          type="date"
                          min={moment(row?.endDate).format("YYYY-MM-DD")}
                          invalid={errors.endDate && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.endDate && (
                      <FormFeedback>Please enter End Date</FormFeedback>
                    )}
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={12}>
                    <Label className="form-label" for="startDate">
                      New Start Date
                    </Label>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={moment(row?.endDate).format("YYYY-MM-DD")}
                      render={({ field }) => (
                        <Input
                          id="startDate"
                          type="date"
                          invalid={errors.startDate && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.startDate && (
                      <FormFeedback>Please enter Start Date</FormFeedback>
                    )}
                  </Col>

                  <Col xs={12}>
                    <Label className="form-label" for="endDate">
                      New End Date
                    </Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="startDate"
                          type="date"
                          min={moment(row?.endDate)
                            .add(1, "day")
                            .format("YYYY-MM-DD")}
                          invalid={errors.endDate && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.endDate && (
                      <FormFeedback>Please enter End Date</FormFeedback>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={!row || loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default RenewAgreement;
