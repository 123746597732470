import StatsVertical from "@components/widgets/stats/StatsVertical";
import classNames from "classnames";
import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { ThemeColors } from "@src/utility/context/ThemeColors";
import { useContext } from "react";
import {
  Building,
  CircleFill,
  EnvelopeAt,
  EnvelopeExclamation,
  Files,
  ReceiptCutoff,
} from "react-bootstrap-icons";
import { formatCurrency } from "../../utility/Utils";
import RevenueReport from "./revenueReport";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

export default function BuildingDashboard() {
  const context = useContext(ThemeColors);
  const navigate = useNavigate();
  const propertyDashboard = useSelector((state) => state.dashboard?.property);

  const options = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: propertyDashboard?.units?.unitsByStatus?.map((u) => u?._id) || [],
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      stroke: {
        width: 4,
      },
      colors: [
        context.colors.primary.main,
        context.colors.warning.main,
        context.colors.success.main,
      ],
    },
    series =
      propertyDashboard?.units?.unitsByStatus?.map((u) => u?.count) || [];

  const renderChartInfo = (data) => {
    return data?.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames("d-flex justify-content-between", {
            "mb-1": false, // index !== data.listData.length - 1
          })}
        >
          <div className="d-flex align-items-center">
            <CircleFill size={15} className="text-primary" />
            <span className="fw-bold ms-75">{item?._id}</span>
          </div>
          <span>{item?.count ?? 0}</span>
        </div>
      );
    });
  };

  const invoiceOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: ["All", "Unpaid"],
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      stroke: {
        width: 4,
      },
      colors: [context.colors.primary.main, context.colors.warning.main],
    },
    invoiceSeries = [
      propertyDashboard?.invoices?.all?.total ?? 0,
      propertyDashboard?.invoices?.unpaid?.total ?? 0,
    ];

  const leaseOptions = {
      chart: {
        toolbar: {
          show: true,
        },
      },
      labels:
        propertyDashboard?.leases?.leasesBystatus?.map((u) => u?._id) || [],
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      stroke: {
        width: 3,
      },
      colors: [context.colors.success.main, context.colors.danger.main],
    },
    leaseSeries = propertyDashboard?.leases?.leasesBystatus?.map(
      (u) => u?.count
    );

  const statusValue = (list, status) => {
    const result = list?.find((item) => item?._id === status);
    return result ? result?.count : 0;
  };

  if (!propertyDashboard) return <Loading />;

  return (
    <>
      <Row>
        <Col sm={12} md={8}>
          {" "}
          <Row>
            {/* Stats With Icons */}
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/units")}
            >
              <StatsVertical
                icon={<Building size={21} />}
                color="info"
                stats={propertyDashboard?.units?.all?.total ?? 0}
                statTitle="Units"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/units?status=Available")}
            >
              <StatsVertical
                icon={<Building size={21} />}
                color="success"
                stats={statusValue(
                  propertyDashboard?.units?.unitsByStatus,
                  "Available"
                )}
                statTitle="Available Units"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/units?status=Booked")}
            >
              <StatsVertical
                icon={<Building size={21} />}
                color="warning"
                stats={statusValue(
                  propertyDashboard?.units?.unitsByStatus,
                  "Booked"
                )}
                statTitle="Booked Units"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/units?status=Reserved")}
            >
              <StatsVertical
                icon={<Building size={21} />}
                color="danger"
                stats={statusValue(
                  propertyDashboard?.units?.unitsByStatus,
                  "Reserved"
                )}
                statTitle="Occupied Units"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/leases")}
            >
              <StatsVertical
                icon={<Files size={21} />}
                color="info"
                stats={propertyDashboard?.leases?.all?.total ?? 0}
                statTitle="All Leases"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/leases?status=Active")}
            >
              <StatsVertical
                icon={<Files size={21} />}
                color="success"
                stats={statusValue(
                  propertyDashboard?.leases?.leasesBystatus,
                  "Active"
                )}
                statTitle="Active Leases"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/leases?status=Active")}
            >
              <StatsVertical
                icon={<Files size={21} />}
                color="danger"
                stats={statusValue(
                  propertyDashboard?.leases?.leasesBystatus,
                  "Expired"
                )}
                statTitle="Expired Leases"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/rental_applications")}
            >
              <StatsVertical
                icon={<EnvelopeAt size={21} />}
                color="info"
                stats={propertyDashboard?.applications?.all?.total ?? 0}
                statTitle="All Rental Applications"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/rental_applications?status=pending")}
            >
              <StatsVertical
                icon={<EnvelopeExclamation size={21} />}
                color="danger"
                stats={propertyDashboard?.applications?.pending?.total ?? 0}
                statTitle="Pending Applications"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/invoices")}
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="info"
                stats={propertyDashboard?.invoices?.all?.total ?? 0}
                statTitle="Total Invoices"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/invoices?status=paid")}
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="success"
                stats={statusValue(
                  propertyDashboard?.invoices?.invoiceBystatus,
                  "paid"
                )}
                statTitle="Paid Invoices"
              />
            </Col>
            <Col
              md="4"
              sm="6"
              className="cursor-pointer"
              onClick={() => navigate("/invoices?status=unpaid")}
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="danger"
                stats={statusValue(
                  propertyDashboard?.invoices?.invoiceBystatus,
                  "unpaid"
                )}
                statTitle="Outstanding Invoices"
              />
            </Col>
          </Row>
          {propertyDashboard?.expenses?.length > 0 &&
            propertyDashboard?.invoices?.revenues?.length > 0 && (
              <Row>
                <Col md={12}>
                  <RevenueReport
                    revenues={propertyDashboard?.invoices?.revenues}
                    expenses={propertyDashboard?.expenses}
                    colors={[
                      context?.colors?.success?.main,
                      context?.colors?.warning?.main,
                    ]}
                  />
                </Col>
                <Col></Col>
              </Row>
            )}
          {propertyDashboard?.expenses?.length == 0 &&
            propertyDashboard?.invoices?.revenues?.length == 0 && (
              <Row>
                <Col md={12}>
                  <RevenueReport
                    revenues={[]}
                    expenses={[]}
                    colors={[
                      context?.colors?.success?.main,
                      context?.colors?.warning?.main,
                    ]}
                  />
                </Col>
                <Col></Col>
              </Row>
            )}
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <CardBody>
              <CardTitle className="mb-2">Rent Collection</CardTitle>
              <div className="d-flex gap-5 align-items-center">
                <div>
                  <div className="font-small-3">Today</div>
                  <h4 className="mb-2 mt-1 text-primary">
                    {formatCurrency(
                      propertyDashboard?.receipts?.today?.total ?? 0
                    )}
                  </h4>
                </div>

                <div>
                  <div className="font-small-3">This Month</div>
                  <h4 className="mb-2 mt-1 text-primary">
                    {formatCurrency(
                      propertyDashboard?.receipts?.thisMonth?.total ?? 0
                    )}
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col sm={12} md={12}>
              <Card>
                <CardHeader className="align-items-end">
                  <CardTitle tag="h4">Invoices</CardTitle>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={invoiceOptions}
                    series={invoiceSeries}
                    type="pie"
                    height={325}
                    width={"100%"}
                  />
                  <div className="pt-25">
                    {renderChartInfo([
                      {
                        _id: "All",
                        count: propertyDashboard?.invoices?.all?.total ?? 0,
                      },
                      {
                        _id: "Unpaid",
                        count: propertyDashboard?.invoices?.unpaid?.total ?? 0,
                      },
                    ])}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={12} md={12}>
              {series.length > 0 && (
                <Card>
                  <CardHeader className="align-items-end">
                    <CardTitle tag="h4">Units</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Chart
                      options={options}
                      series={series}
                      type="pie"
                      height={339}
                      width={"100%"}
                    />
                    <div className="pt-25">
                      {renderChartInfo(
                        propertyDashboard?.units?.unitsByStatus ?? []
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
