import React from "react";
import { Controller } from "react-hook-form";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";

import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import Select from "react-select";
import { slugify } from "../../../../utility/Utils";

export default function PropertyInformation({
  stepper,
  control,
  register,
  errors,
  categories,
  getValues,
  setValue,
  trigger,
  appView,
}) {
  var propertyStatus = [
    { value: "For Sale", label: "For Sale" },
    { value: "Broker Rent", label: "Broker Rent" },
    { value: "For Rent", label: "For Rent" },
  ];

  const categoriesOptions = categories?.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const nextClick = () => {
    stepper.next();
  };

  return (
    <>
      <Row className="gy-1 gx-2">
        <Col xs={12} md={6}>
          <Label className="form-label" for="name">
            Property Name
          </Label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                id="name"
                {...register("name", { required: true })}
                placeholder="Burj Tower"
                invalid={errors.name && true}
                {...field}
                onChange={(value) => {
                  setValue("slug", slugify(value.target.value));
                  trigger("slug");
                  field.onChange(value);
                }}
              />
            )}
          />
          <span className="text-success">slug: {getValues().slug}</span>
          {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="category">
            Property Type
          </Label>

          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                id="category"
                className={`react-select ${
                  errors.category ? "is-invalid" : ""
                }`}
                classNamePrefix="select"
                aria-invalid={errors.category && true}
                placeholder="Choose category"
                options={categoriesOptions}
                {...field}
                value={categoriesOptions?.find(
                  (option) => option.value === field.value
                )}
                onChange={(selected) => field.onChange(selected.value)}
              />
            )}
          />
          {errors.category && (
            <FormFeedback>{errors.category.message}</FormFeedback>
          )}
        </Col>

        {appView ? (
          <Col xs={12} md={3}>
            <Label className="form-label" for="propertyStatus">
              Property Purpose
            </Label>

            <Controller
              name="propertyStatus"
              control={control}
              render={({ field }) => (
                <Select
                  id="propertyStatus"
                  className={`react-select ${
                    errors.propertyStatus ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                  aria-invalid={errors.propertyStatus && true}
                  placeholder="Choose propertyStatus"
                  options={propertyStatus}
                  {...field}
                  value={propertyStatus?.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(selected) => field.onChange(selected.value)}
                />
              )}
            />
            {errors.propertyStatus && (
              <FormFeedback>{errors.propertyStatus.message}</FormFeedback>
            )}
          </Col>
        ) : null}

        <Col xs={12} md={3}>
          <Label className="form-label" for="contacts.phone">
            Contact Phone
          </Label>

          <Controller
            name="contacts.phone"
            control={control}
            render={({ field }) => (
              <Input
                id="contacts.phone"
                type="phone"
                {...register("contacts.phone", { required: true })}
                placeholder="25261...."
                invalid={errors?.contacts?.phone && true}
                {...field}
              />
            )}
          />
          {errors?.contacts?.phone && (
            <FormFeedback>{errors?.contacts?.phone.message}</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="contacts.email">
            Contact Email
          </Label>

          <Controller
            name="contacts.email"
            control={control}
            render={({ field }) => (
              <Input
                id="contacts.email"
                min={0}
                type="email"
                {...register("contacts.email", { required: true })}
                step={"any"}
                placeholder="admin@rukun.so"
                invalid={errors?.contacts?.email && true}
                {...field}
              />
            )}
          />
          {errors?.contacts?.email && (
            <FormFeedback>{errors?.contacts?.email.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="floors">
            Floors
          </Label>
          <Controller
            name="floors"
            control={control}
            render={({ field }) => (
              <Input
                id="floors"
                type="number"
                placeholder="4"
                {...register("floors", { required: true })}
                invalid={errors.floors && true}
                {...field}
              />
            )}
          />
          {errors.floors && (
            <FormFeedback>{errors.floors.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="landArea">
            Land Area <small> (in meter e.g 10x10)</small>
          </Label>

          <Controller
            name="landArea"
            control={control}
            render={({ field }) => (
              <Input
                id="landArea"
                type="text"
                {...register("landArea", { required: true })}
                placeholder=""
                invalid={errors.landArea && true}
                {...field}
              />
            )}
          />
          {errors.landArea && (
            <FormFeedback>{errors.landArea.message}</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="garages">
            Garages
          </Label>

          <Controller
            name="garages"
            control={control}
            render={({ field }) => (
              <Input
                id="garages"
                type="text"
                {...register("garages", { required: true })}
                placeholder=""
                invalid={errors.garages && true}
                {...field}
              />
            )}
          />
          {errors.garages && (
            <FormFeedback>{errors.garages.message}</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="garageSize">
            Garage Size
          </Label>

          <Controller
            name="garageSize"
            control={control}
            render={({ field }) => (
              <Input
                id="garageSize"
                type="text"
                {...register("garageSize", { required: true })}
                placeholder=""
                invalid={errors.garageSize && true}
                {...field}
              />
            )}
          />
          {errors.garageSize && (
            <FormFeedback>{errors.garageSize.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="yearBuilt">
            Year Built
          </Label>

          <Controller
            name="yearBuilt"
            control={control}
            render={({ field }) => (
              <Input
                id="yearBuilt"
                type="number"
                {...register("yearBuilt", { required: true })}
                placeholder=""
                invalid={errors.yearBuilt && true}
                {...field}
              />
            )}
          />
          {errors.yearBuilt && (
            <FormFeedback>{errors.yearBuilt.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="description">
            Description
          </Label>

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                id="description"
                type="textarea"
                invalid={errors.description && true}
                {...field}
              />
            )}
          />
          {errors.description && (
            <FormFeedback>{errors.description.message}</FormFeedback>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button
          color="secondary"
          className="btn-prev"
          onClick={() => stepper.previous()}
        >
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
