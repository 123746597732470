// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import {
  createBook,
  getAllBooks,
  updateBook,
} from "../../../redux/accountBook";
import HandleDispatch from "../../../common/utils/HandledDispatch";

const BookModal = ({ row, show, ShowModal }) => {
  const defaultValues = {
    name: row?.name ?? "",
    startDate: row?.startDate,
    endDate: row?.endDate,
    closedDate:''
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      if (row) {
        let response = await HandleDispatch(
          dispatch,
          updateBook({ id: row._id, data })
        );
        if (response) {
          toast.success("Book Information updated Succesfully");
          reset();
          ShowModal();
        }
      } else {
        let response = await HandleDispatch(dispatch, createBook(data));
        if (response) {
          toast.success("New Book Created Succesfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal();
    reset();
  };

  // const store = useSelector((state) => state.buildings);
  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={ShowModal} className="">
        <ModalHeader className="bg-white py-1" toggle={ShowModal}>
          {" "}
          {row ? "Edit Book" : "New Book"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5  mx-50">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2 flex-column">
              <Col xs={12}>
                <Label className="form-label" for="name">
                 Current Book Closed Date
                </Label>
               
                    <Controller
                      name="closedDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="closedDate"
                          type="date"
                          invalid={errors.closedDate && true}
                          {...field}
                        />
                      )}
                    />
               
              </Col>
              {/* <Col xs={12} className="mt-3">
                <Label className="form-label" for="name">
                New  Book Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="House"
                      {...register(
                        "name",
                        { required: true },
                        "Book Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
               
              </Col> */}
              <Col xs={12} className="mt-3">
                <Row>
                  <Col sm={12} md={6}>
                    <Label className="form-label" for="startDate">
                      New Fiscal Year Start Date
                    </Label>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="startDate"
                          type="date"
                          invalid={errors.startDate && true}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Label className="form-label" for="endDate">
                      New Fiscal Year End Date
                    </Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="endDate"
                          type="date"
                          invalid={errors.endDate && true}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button type="submit" className="me-1" color="primary">
              Submit
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default BookModal;
