import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createQueryString, getToken } from "../utility/helper";

export const allocationApi = createApi({
  reducerPath: "allocation",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["allocation"],
  endpoints: (builder) => ({
    // read
    getAllocations: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/allocation?${serializedParams}`;
      },
      providesTags: ["allocation"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // create
    createAllocation: builder.mutation({
      query: (body) => ({
        url: "/allocation",
        method: "POST",
        body,
      }),
      invalidatesTags: ["allocation"],
    }),

    // update
    updateAllocation: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/allocation/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["allocation"],
    }),

    // delete
    deleteAllocation: builder.mutation({
      query: (id) => ({
        url: `/allocation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["allocation"],
    }),
  }),
});

export const {
  useGetAllocationsQuery,
  useCreateAllocationMutation,
  useUpdateAllocationMutation,
  useDeleteAllocationMutation,
} = allocationApi;
