import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function TableLoading({ rows = 3, cols = 5 }) {
  var rowsArr = [...Array(rows).keys()];
  var colsArr = [...Array(cols).keys()];

  const GenerateCols = () => {
    return colsArr.map((dt, idx) => ( 
      <td key={idx} className=''>
        <Skeleton />
      </td>
    ));
  };
  const GenerateRows = () => {
    return rowsArr.map((dt, idx) => (
      <tr key={idx}>
        <GenerateCols cols={cols} />
      </tr>
    ));
  };

  return (
    <div className="w-auto">
      <SkeletonTheme highlightColor="#d3d3d3" baseColor="#f6f6f6" height="20px">
        <table className="w-100 skeleton-table">
          <tbody>
            <GenerateRows />
          </tbody>
        </table>
      </SkeletonTheme>
    </div>
  );
}
