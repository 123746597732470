// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Spinner,
} from "reactstrap";
import CreatableSelect from "react-select";
import { useForm, Controller } from "react-hook-form";
import { ErrorHandler } from "../../../../common/utils/Error";
import axios, { AxiosError } from "axios";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

import { createLeaveRequest, getAllLeaveRequests, updateLeaveRequest } from "../../../../redux/leaves";
import HandleDispatch from "../../../../common/utils/HandledDispatch";

const LeaveRequestModel = ({ show, ShowModal }) => {

  const selectedLeave = useSelector(
    (state) => state.leaves.selectedLeave
  );

  const [loading, setloading] = useState(false);

  const [staffs,setStaffs] = useState([{label:"",value:""}])



  const getAllStaffs = ()  => async () => {
    try {
      const { data, status } = await axios.get("/hrm/staff",{
        params :{
          options: {
            "select": "name"
          }
        },
      });

      if (status == 200) {
        var stafftList = data?.data?.docs?.map((staff) => ({
          label: staff.name,
          value: staff._id,
        }));
        setStaffs(stafftList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };


  const defaultValues = {
    staff: "",
    type: "",
    reason: "",
    startDate:"",
    endDate: "",
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    reset({ ...selectedLeave});
  }, [selectedLeave]);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setloading(true);
    try {
      data.staff = data.staff?.value;

      console.log('data = ', data)
      if (selectedLeave) {
        // setValue("startDate",moment(data.startDate).format("MM DD YYYY"))

        var updatedLeaveRequest = await HandleDispatch(
          dispatch,
          updateLeaveRequest({ id: selectedLeave._id, data })
        );
        if (updatedLeaveRequest) {
          toast.success("Leave Request Information updated Successfully");
          reset();
          ShowModal();
        }
      } else {
        var response = await HandleDispatch(dispatch, createLeaveRequest(data));
        if (response) {
          toast.success("New Leave Request Created Successfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setloading(false);
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset();
  };

  const formatDate = (stringDate) => { 
    const date = new Date(stringDate);
    date.setDate(date.getDate() + 1);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  }
  useEffect(() => {
    dispatch(getAllStaffs());
    dispatch(getAllLeaveRequests());
  }, []);
  // const store = useSelector((state) => state.buildings);
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        className=""
        modalClassName="modal-dark"
      >
        <ModalHeader toggle={ShowModal} className="bg-white">
          Add New Leave Request
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2">
              <Col xs={6}>
                <Label className="form-label" for="name">
                  Staff Name
                </Label>
                <Controller
                  name="staff"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="staff"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.staff && true}
                      placeholder="Choose staff"
                      options={staffs}
                     
                      {...field}     
                    />
                  )}
                />
              </Col>
              <Col xs={6}>
                <Label className="form-label" for="Type">
                  Type
                </Label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Type"
                      {...register(
                        "type",
                        { required: true },
                        "Type is required"
                      )}
                      invalid={errors.type && true}
                      {...field}
                    />
                  )}  
                />
                {errors.type && (
                  <FormFeedback>Please enter a valid Type</FormFeedback>
                )}
              </Col>
            </Row>
            <Row className="gy-1 gx-2">
              <Col xs={12}>
                <Label className="form-label" for="reason">
                  Description
                </Label>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="reason"
                      placeholder="description"
                      {...register(
                        "reason",
                        { required: true },
                        "Description is required"
                      )}
                      invalid={errors.reason && true}
                      {...field}
                    />
                  )}
                />
                {errors.reason && (
                  <FormFeedback>Please enter a valid Description</FormFeedback>
                )}
              </Col>

              <Col xs={6}>
                <Label className="form-label" for="startDate">
                  Start Date
                </Label>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="startDate"
                      type="date"
                      {...register(
                        "startDate",
                        { required: true },
                        "StartDate is required"
                      )}
                      invalid={errors.startDate && true}
                      {...field}
                    />
                  )}  
                />
                {errors.startDate && (
                  <FormFeedback>Please enter a valid Date</FormFeedback>
                )}
              </Col>
              <Col xs={6}>
                <Label className="form-label" for="endDate">
                  End Date
                </Label>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="endDate"
                      type="date"
                      min={watch('startDate') && formatDate(watch('startDate'))}
                      {...register(
                        "endDate",
                        { required: true },
                        "EndDate is required"
                      )}
                      invalid={errors.endDate && true}
                      {...field}
                    />
                  )}  
                />
                {errors.endDate && (
                  <FormFeedback>Please enter a valid Date</FormFeedback>
                )}
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button type="submit" className="me-1" color="primary" disabled={loading}>
            <Spinner color="light" size="sm" className="me-1" hidden={!loading} />
              {loading?"Submiting":"Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default LeaveRequestModel;
