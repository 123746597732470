import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// ** Third Party Components
import toast from "react-hot-toast";
import * as yup from "yup";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports
import {
  useCheckInMutation,
  useCheckOutMutation,
} from "../../../redux/attendance";
import CreatableSelect from "react-select/creatable";
import { useGetStaffsQuery } from "../../../redux/staff";

const AllocationModal = ({ show, setShow, selectedRow, setSelectedRow }) => {
  // ** state
  const [value, setValues] = useState(new Date());
  const [check_in, setCheck_in] = useState(false);
  const [checkout, setCheckout] = useState(false);

  // time staff checkIn
  const [checkInTime, setCheckInTime] = useState("");

  // ** RTK Query Mutations
  const [
    CheckIn,
    {
      isLoading: checkInLoading,
      isError: checkInError,
      error: checkInErrorMessage,
    },
  ] = useCheckInMutation();
  const [
    CheckOut,
    {
      isLoading: checkOutLoading,
      isError: checkOutError,
      error: checkOutErrorMessage,
    },
  ] = useCheckOutMutation();

  // staffs
  const { data: staffs } = useGetStaffsQuery({});

  const jobsOptions = staffs?.docs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  // default values
  const defaultValues = {
    staff: "",
    latitude: "",
    longitude: "",
    checkIn: "",
    checkOut: "",
  };

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    data.checkIn = new Date();
    data.checkOut = new Date();
    data.staff = data.staff.value;
    try {
      if (data.latitude && data.longitude) {
        if (check_in) {
          const res = await CheckIn({
            staff: data.staff,
            checkIn: data.checkIn,
            checkOut: null,
            geolocation: {
              type: "Point",
              coordinates: [data.latitude, data.longitude],
            },
          }).unwrap();
          if (res.status !== "fail") {
            toast.success("CheckIn Successful");
          }
        } else if (checkout) {
          const resData = await CheckOut({
            staff: data.staff,
            checkOut: data.checkOut,
            geolocation: {
              type: "Point",
              coordinates: [data.latitude, data.longitude],
            },
          }).unwrap();
          if (resData.status !== "fail") {
            toast.success("CheckOut Successful");
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message);
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    setShow(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  useEffect(() => {
    if (selectedRow) {
      reset({
        ...selectedRow,
        // staff: selectedRow.staff.
        staff: jobsOptions?.filter((item) =>
          selectedRow?.staff?._id.includes(item.value)
        ),
      });
    }
  }, [selectedRow, reset]);

  // geolocation
  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setValue("latitude", latitude);
        setValue("longitude", longitude);
      });
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setValue("latitude", latitude);
        setValue("longitude", longitude);
      });
    }
  }, []);

  // clock Design

  useEffect(() => {
    const interval = setInterval(() => setValues(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleCheckoutClick = () => {
    handleLocation();
    setCheckout(true);
    setCheck_in(false);
  };

  const handleCheckInClick = () => {
    handleLocation();
    setCheck_in(true);
    setCheckout(false);
  };

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className=""
    >
      <ModalHeader className="bg-transparent" toggle={() => setShow(!show)}>
        <span className="h3">
          {selectedRow ? "Edit Allocation" : "New Workplace Assign"}
        </span>
      </ModalHeader>
      <ModalBody className="py-2">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row
            className={
              "d-flex flex-column justify-content-center align-items-center"
            }
          >
            <Col sm={12} md={12} className={"mb-2"}>
              <Label className="form-label" for="job">
                Staff <span className="text-danger"> * </span>
              </Label>
              <Controller
                name="staff"
                control={control}
                rules={{ required: "Staff is required" }}
                render={({ field }) => (
                  <CreatableSelect
                    id="staff"
                    isDisabled={selectedRow ? true : false}
                    className={`react-select ${
                      errors.staff ? "is-invalid" : ""
                    }`}
                    classNamePrefix="select"
                    aria-invalid={errors.staff && true}
                    placeholder="Choose Staff"
                    options={jobsOptions}
                    {...field}
                    value={field.value}
                    onChange={(selected) => field.onChange(selected)}
                  />
                )}
              />
              {errors.staff && <FormFeedback>Please select Staff</FormFeedback>}
            </Col>

            <Col xs={12} className={"mb-2 d-flex justify-content-center"}>
              <Label className="form-label" for="reason"></Label>
              <Clock value={value} />
            </Col>

            <ModalFooter className="d-flex justify-content-center">
              <Button
                type="submit"
                className="me-1"
                color="primary"
                onClick={handleCheckInClick}
                disabled={checkInLoading || checkOutLoading}
              >
                <Spinner
                  color="light"
                  size="sm"
                  className="me-1"
                  hidden={!checkInLoading}
                />
                {checkInLoading ? "CheckIn..." : "Check-in"}
              </Button>
              <Button
                type="submit"
                className="me-1"
                color="primary"
                onClick={handleCheckoutClick}
                disabled={checkOutLoading || checkInLoading}
              >
                <Spinner
                  size="sm"
                  color="dark"
                  outline
                  className="me-1"
                  hidden={!checkOutLoading}
                />
                {checkOutLoading ? "CheckOut..." : "Check Out"}
              </Button>
            </ModalFooter>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AllocationModal;
