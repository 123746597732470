import React, { useState } from "react";

import moment from "moment";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";

import { Edit, Trash } from "react-feather";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CustomDataTable from "../../../../common/CustomDataTable";
import {
  useDeleteDepartmentMutation,
  useGetDepartmentsQuery,
} from "../../../../redux/departments";
import DepartmentModel from "./DepartmentModel";

const MySwal = withReactContent(Swal);

const Departments = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteDepartment] = useDeleteDepartmentMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Account ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteDepartment(id);
        if (responseStatus) {
          toast.success("Department has been Deleted.");
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Department Name",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div className="">
          <span className="fw-bolder">{row.name ?? ""}</span>
        </div>
      ),
    },
    {
      name: "User",
      minWidth: "100px",
      sortable: true,
      sortField: "user",
      selector: (row) => row.createdBy,
      cell: (row) => <span className="">{row.createdBy?.username}</span>,
    },
    {
      name: "Date",
      minWidth: "100px",
      sortable: true,
      sortField: "date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Departments"
        fetchData={useGetDepartmentsQuery}
        searchFields={["name"]}
        populate={[
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"Add New Department"}
        addButtonClick={() => setIsModalVisible(true)}
      />

      <DepartmentModel
        show={isModalVisible}
        ShowModal={setIsModalVisible}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default Departments;
