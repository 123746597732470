import { Badge, Card, CardTitle, CardHeader, CardBody } from "reactstrap";
import { Fragment } from "react";

const Overview = () => {
  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">
          <CardTitle> Property Overview</CardTitle>
          {/* <Button color="primary">New Agreement</Button> */}
        </CardHeader>
        <CardBody>
          <div className="react-dataTable user-view-account-projects"></div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Overview;
