import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const shiftApi = createApi({
  reducerPath: "shifts",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["shifts", "shiftsList"],
  endpoints: (builder) => ({
    // Read
    getShifts: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/shifts?${serializedParams}`;
      },
      providesTags: ["shifts"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getShiftsList: builder.query({
      query: () => {
        return `/hrm/shifts/list`;
      },
      providesTags: ["shiftsList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createShift: builder.mutation({
      query: (data) => ({
        url: "/hrm/shifts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["shifts", "shiftsList"],
    }),

    // Update
    updateShift: builder.mutation({
      query: ({ data: shifts, id }) => ({
        url: `/hrm/shifts/${id}`,
        method: "PATCH",
        body: shifts,
      }),
      invalidatesTags: ["shifts", "shiftsList"],
    }),

    // Delete
    deleteShift: builder.mutation({
      query: (id) => ({
        url: `/hrm/shifts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shifts", "shiftsList"],
    }),
  }),
});

export const {
  useGetShiftsQuery,
  useGetShiftsListQuery,
  useCreateShiftMutation,
  useUpdateShiftMutation,
  useDeleteShiftMutation,
} = shiftApi;
