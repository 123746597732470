import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import InputPasswordToggle from "@components/input-password-toggle";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../../common/utils/Error";
import HandleDispatch from "../../../../common/utils/HandledDispatch";

const Roles = () => {
  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">Staff Permissions</CardHeader>
        <CardBody></CardBody>
      </Card>
    </Fragment>
  );
};

export default Roles;
