import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createQueryString, getToken } from "../utility/helper";

export const attendanceApi = createApi({
  reducerPath: "attendance",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["attendance"],
  endpoints: (build) => ({
    // checkIn
    checkIn: build.mutation({
      query: (data) => ({
        url: "/attendance/checkIn",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["attendance"],
    }),
    // checkOut
    checkOut: build.mutation({
      query: (data) => ({
        url: "/attendance/checkOut",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["attendance"],
    }),
    // get Attendances
    getAttendances: build.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/attendance?${serializedParams}`;
      },
      providesTags: ["attendance"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),
    // delete Attendance
    deleteAttendance: build.mutation({
      query: (id) => ({
        url: `/attendance/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["attendance"],
    }),
  }),
});

export const {
  useCheckInMutation,
  useCheckOutMutation,
  useGetAttendancesQuery,
  useDeleteAttendanceMutation,
} = attendanceApi;
