import moment from "moment";
import React, { useState } from "react";
import { Edit, Trash } from "react-feather";
import toast from "react-hot-toast";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomDataTable from "../../../common/CustomDataTable/index.jsx";
import WorkplaceModal from "./WorkplaceModal.jsx";
import {
  useGetWorkplaceQuery,
  useDeleteWorkplaceMutation,
} from "../../../redux/workplace.js";

const MySwal = withReactContent(Swal);

const Workplace = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteWorkplace] = useDeleteWorkplaceMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Workplace ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const response = await deleteWorkplace(id);
        if (response?.data?.status == "success") {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.error?.data?.message);
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Name",
      sortField: "name",
      minWidth: "250px",
      sortable: true,
      cell: ({ name }) => name,
      selector: (row) => row.name,
    },
    {
      name: "Address",
      sortField: "address",
      minWidth: "250px",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "250px" }}>
          {row.address}
        </div>
      ),
    },
    {
      name: "Latitude",
      sortField: "latitude",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "250px" }}>
          {row.latitude}
        </div>
      ),
    },
    {
      name: "Longitude",
      sortField: "longitude",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "250px" }}>
          {row.longitude}
        </div>
      ),
    },

    {
      name: "Status",
      minWidth: "250px",
      sortable: true,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span>{row.status}</span>
        </Badge>
      ),
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Workplaces"
        fetchData={useGetWorkplaceQuery}
        query={{}}
        searchFields={["name"]}
        populate={[
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"New Workplace"}
        addButtonClick={() => setIsModalVisible(true)}
      />
      <WorkplaceModal
        selectedRow={selectedRow}
        show={isModalVisible}
        setShow={setIsModalVisible}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default Workplace;
