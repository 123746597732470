// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllinvoices = createAsyncThunk(
  "invoices/getAllData",
  async ({ view, status }, { getState }) => {
    let bodyContent = {
      params: {
        query: {
          type: view,
          status,
          building: getState().CurrentBuilding?.data?._id ?? undefined,
        },
        options: {
          sort: { createdAt: "desc" },
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
            // {
            //   path:"items.bookingId",
            //   dir:"bookings",
            //   select:"apartment",
            //   populate:{
            //     path:"apartment",
            //     dir:"sectionals",
            //     select:"name",

            //   }
            // }
          ],
        },
      },
    };
    const response = await axios.get("/accountings/invoices", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      total: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "invoices/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().invoices.params;
    var newparams = {
      ...strparams,
      query: {
        building: getState().CurrentBuilding?.data?._id ?? undefined,
      },
      options: { ...strparams.options, ...params },
    };
    const response = await axios.get("/accountings/invoices", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const createInvoice = createAsyncThunk(
  "invoices/create",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post("/accountings/invoices", formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(addInvoice(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateInvoice = createAsyncThunk(
  "invoices/update",
  async ({ data, id }, { dispatch, getState }) => {
    console.log("EDit booking", data);
    var response = await axios.patch(`/accountings/invoices/${id}`, data);
    // await dispatch(getData(getState().users.params));
    dispatch(editInvoice(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoices/delete",
  async (id, { dispatch }) => {
    const response = await axios.delete(`/accountings/invoices/${id}`);
    await dispatch(getAllinvoices());
    return response.status;
  }
);

//new api
export const getInvoicesFigures = createAsyncThunk(
  "invoices/getInvoicesFigures",
  async (view, { rejectWithValue, getState }) => {
    try {
      const params = { view };
      if (getState().CurrentBuilding) {
        params.building = getState().CurrentBuilding?.data?._id;
      }
      return await axios.get(`/accountings/invoices/figures`, {
        params,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const invoiceslice = createSlice({
  name: "invoices",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedInvoice: null,
    figuresLoading: false,
    figures: { total: 0, paid: 0, unpaid: 0 },
  },
  reducers: {
    addInvoice: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectInvoice: (state, { payload }) => {
      state.selectedInvoice = payload;
    },
    editInvoice: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedInvoice = null;
    },

    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs?.customer?.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.invoiceNo?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllinvoices.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllinvoices.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.total;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getInvoicesFigures.pending, (state) => {
        state.error = null;
        state.figuresLoading = true;
      })
      .addCase(getInvoicesFigures.fulfilled, (state, action) => {
        const { figures } = action.payload?.data;
        if (figures) state.figures = figures;
        state.figuresLoading = false;
        state.error = null;
      })
      .addCase(getInvoicesFigures.rejected, (state, action) => {
        console.log("ACTION PAYLOAD", action.payload);
        state.error = action.payload;
        state.figuresLoading = false;
      });
  },
});

export const { addInvoice, selectInvoice, searchFilter, editInvoice } =
  invoiceslice.actions;
export default invoiceslice.reducer;
