import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardText, Col, Row, Table } from "reactstrap";
import { getCustomerStatement } from "../../../redux/customer";
import { formatCurrency, getDateRangeByFilter } from "../../../utility/Utils";
import Logo from "../../../assets/images/logo/logo-icon.png";
const Preview = ({ statement, customer, filterDate }) => {
  return (
    <div>
      <Card className="invoice-preview-card p-1 ">
        <CardBody className="invoice-padding pb-0">
          {/* Header */}
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div className="my-auto">
              <img src={Logo} width={50} />
            </div>

            <div className="mt-md-0 mt-2">
              <div className="text-end my-3">
                <CardText className="mb-25">
                  <b>Jamhuriya Technology Solutions - Jtech</b>
                </CardText>
                <CardText className="mb-25">
                  Apartment 103, 1st Floor, Adani Tower 2, Makka Almukaramah
                  Street
                </CardText>
                <CardText className="mb-0">Mogadishu Benadir</CardText>
                <CardText className="mb-0">Somalia</CardText>
              </div>
            </div>
          </div>
          {/* /Header */}
        </CardBody>

        {/* Address and Contact */}
        <CardBody className="invoice-padding pt-0 mt-3">
          <Row className="invoice-spacing">
            <Col className="p-0" xl="8">
              <h6 className="mb-1">To</h6>
              <h5 className="mb-25"> {customer?.name}</h5>
            </Col>
            <Col className="p-0 mt-xl-0 mt-2 text-end" xl="4">
              <h4>Account Summary</h4>
              <p>
                {filterDate?.start} To {filterDate?.end}
              </p>
            </Col>
          </Row>
          <Row className="invoice-spacing ">
            <Col className="p-0" xl="7"></Col>
            <Col className="p-0 mt-xl-0 mt-2  " xl="5">
              <hr className="invoice-spacing" />
              <div className="d-flex justify-content-between">
                <div>
                  <p>Beginning Balance:</p>
                  <p>Invoiced Amount: </p>
                  <p>Amount Paid:</p>
                  <p>
                    <b>Balance Due:</b>
                  </p>
                </div>
                <div className="mb-0 text-end">
                  <p>{formatCurrency(statement?.summery?.beginningBalance)}</p>
                  <p>{formatCurrency(statement?.summery?.invoiceAmount)}</p>
                  <p>{formatCurrency(statement?.summery?.receiptAmount)}</p>
                  <p>
                    <b>{formatCurrency(statement?.summery?.balanceDue)}</b>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>

        <div className="text-center mb-2">
          <b>
            Showing all invoices and payments between {filterDate?.start} and{" "}
            {filterDate?.end}
          </b>
        </div>
        <h5></h5>

        {/* Invoice Description */}
        <Table responsive>
          <thead>
            <tr>
              <th className="py-1" width={"10%"}>
                Date
              </th>
              <th className="py-1" width={"45%"}>
                Details
              </th>
              <th className="py-1" width={"15%"}>
                Invoice
              </th>
              <th className="py-1" width={"15%"}>
                Receipt
              </th>
              <th className="py-1" width={"15%"}>
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-1">
                <p className="card-text text-nowrap">{filterDate?.start}</p>
              </td>
              <td className="py-1" width={"15%"}>
                <span className="fw-bold">Beginning Balance</span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>
              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(statement?.summery?.beginningBalance)}
                </span>
              </td>
            </tr>
            {/* {data.items.map((item, idx) => (
           ))}   */}

            {statement?.detail?.map((item) => (
              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap">{item.date}</p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span>{item.type}</span>
                  <span>
                    {" "}
                    (<a href="">#{item.id}</a>){" "}
                  </span>
                  <span>{item.detail}</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {item.type === "Invoice" && formatCurrency(item.amount)}
                  </span>
                </td>
                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {item.type === "Receipt" && formatCurrency(item.amount)}
                  </span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {formatCurrency(item.balance || 0)}
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <td className="py-1">
                <p className="card-text text-nowrap"> </p>
              </td>
              <td className="py-1" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>

              <td className="py-1  " width={"30%"} colSpan={2}>
                <span className="fw-bold"> Balance Due</span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(statement?.summery?.balanceDue)}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>

        <CardBody className="invoice-padding mt-2">
          <Row>
            <Col sm="12">
              <span className="fw-bold">Signature: </span>
            </Col>
            <Col sm="12" className="mt-1">
              <span>_________________________________</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default Preview;
