import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseQuery, createQueryString, getToken } from "../utility/helper";

export const vendorApi = createApi({
  reducerPath: "vendors",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Vendors"],
  endpoints: (builder) => ({
    createVendor: builder.mutation({
      query: (formData) => ({
        url: "/vendors",
        method: "POST",
        body: formData,
      }),
      // onQueryStarted: async (newVendor, { dispatch, queryFulfilled }) => {
      //   const patchResult = dispatch(
      //     vendorApi.util.updateQueryData("getVendors", undefined, (draft) => {
      //       draft.push(newVendor);
      //     })
      //   );
      //   try {
      //     await queryFulfilled;
      //   } catch {
      //     patchResult.undo();
      //   }
      // },
      invalidatesTags: ["Vendors"],
    }),

    updateVendor: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/vendors/${id}`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Vendors"],
    }),

    deleteVendor: builder.mutation({
      query: (id) => ({ url: `/vendors/${id}`, method: "DELETE" }),
      invalidatesTags: ["Vendors"],
    }),

    getVendors: builder.query({
      query: ({ page = 1, perPage = 10, keyword = "" }) => {
        const params = {
          options: {
            query: {},
            page,
            limit: perPage,
            populate: [
              {
                dir: "users",
                path: "createdBy",
                select: "_id username",
              },
            ],
          },
        };

        const serializedParams = createQueryString(params);

        return `/vendors?${serializedParams}`;
      },
      providesTags: ["Vendors"],
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useCreateVendorMutation,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
} = vendorApi;
