import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ChevronDown } from "react-feather";
import ReactPaginate from "react-paginate";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { formatCurrency } from "../../../utility/Utils";
import RentInvoiceModel from "../../invoices/components/generateInvoice";

const Invoice = ({ store }) => {
  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [modelShow, setModelShow] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [invoices, setInvoices] = useState([]);

  const dataToRender = () => {
    return invoices;
  };

  const columns = [
    {
      name: "#",
      sortable: true,

      selector: (row) => row.invoiceNo,
      cell: (row) => (
        <span className="fw-bolder text-primary">{row.invoiceNo ?? ""}</span>
      ),
    },
    {
      name: "Date",

      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD-MMM-YYYY")}
        </span>
      ),
    },
    {
      name: "Type",

      selector: (row) => row.type,
      cell: (row) => <span className="text-capitalize">{row.type}</span>,
    },

    {
      name: "Total",
      selector: (row) => row.total,
      cell: (row) => (
        <span color="primary" className="text-capitalize fw-bolder">
          {formatCurrency(row.grandTotal)}
        </span>
      ),
    },

    {
      name: "Balance",
      selector: (row) => row.balance,
      cell: (row) => (
        <span className="text-success fw-bolder">${row.balance ?? 0}</span>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.balance,
      cell: (row) => (
        <Badge
          color={`${
            row.status == "paid"
              ? "success"
              : row.status == "partial"
              ? "warning"
              : "danger"
          }`}
        >
          {row.status}
        </Badge>
      ),
    },

    {
      name: "User",

      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
    // {
    //   name: "Actions",
    //   minWidth: "100px",
    //   allowOverflow: true,
    //   cell: (row) => (
    //     <div className="column-action d-inline-flex align-items-center justify-content-center">
    //       {/* <Link
    //         to={`${row._id}` + (appView ? `?view=` + appView : ``)}
    //         className="mx-1"
    //         id={`pw-tooltip-${row._id}`}
    //       >
    //         <Eye size={17} />
    //       </Link> */}

    //       <UncontrolledTooltip placement="top" target={`pw-tooltip-${row._id}`}>
    //         Preview Invoice
    //       </UncontrolledTooltip>
    //       <UncontrolledDropdown>
    //         <DropdownToggle tag="div" className="btn btn-sm">
    //           <MoreVertical size={17} className="cursor-pointer" />
    //         </DropdownToggle>
    //         <DropdownMenu>
    //           {row.total == row.balance ? null : (
    //             <DropdownItem
    //               tag="a"
    //               href="/"
    //               className="w-100"
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 setRefundShow({ invoice: row._id, show: true });
    //               }}
    //             >
    //               <CashCoin size={14} className="me-50" />
    //               <span className="align-middle">Make Refund</span>
    //             </DropdownItem>
    //           )}
    //         </DropdownMenu>
    //       </UncontrolledDropdown>
    //     </div>
    //   ),
    // },
  ];

  const handlePagination = (page) => {
    const startIndex = page.selected * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageData = store.data.invoices.slice(startIndex, endIndex);

    setInvoices(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    setInvoices(store.data.invoices?.slice(0, rowsPerPage));
  }, [store]);

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.data.invoices.length / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const ToggleRentModal = (show) => {
    setModelShow(false);
  };
  return (
    <Fragment>
      <RentInvoiceModel show={modelShow} onClose={ToggleRentModal} />
      <Card>
        <CardHeader tag="h4">
          <CardTitle>Customers's Invoices List</CardTitle>
          <Button color="primary" onClick={() => setModelShow(true)}>
            New Invoice
          </Button>
        </CardHeader>
        <div className="react-dataTable user-view-account-projects">
          {/* <DataTable
            noHeader
            responsive
            columns={columns}
            paginationComponent={CustomPagination}
            data={store.data.invoices}
            className="react-dataTable"
            sortIcon={<ChevronDown size={10} />}
          /> */}

          <DataTable
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            striped
            columns={columns}
            sortIcon={<ChevronDown />}
            className="react-dataTable react-dataTable-selectable-rows"
            paginationComponent={CustomPagination}
            data={dataToRender()}
          />
        </div>
        <CardBody></CardBody>
      </Card>
    </Fragment>
  );
};

export default Invoice;
