import React, { useEffect, useRef, useState } from "react";
import { HotColumn, HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import { Button, Form } from "reactstrap";
import "handsontable/dist/handsontable.full.min.css";
import { useSaveEmployeeDeductionsMutation } from "../../../redux/deduction";
import { ExtractError } from "../../../common/utils/Error";
import toast from "react-hot-toast";

// Register Handsontable's modules
registerAllModules();

const DeductionsTable = ({ data: fetchedData }) => {
  const [rawData, setRawData] = useState([]);
  const hotRef = useRef(null);

  const [saveEmployeeDeductions, { isLoading }] =
    useSaveEmployeeDeductionsMutation();

  if (!rawData) return null;

  // Extract unique deduction types with their corresponding types
  const deductionTypes = [
    ...new Map(
      rawData
        .flatMap((staff) =>
          staff.deductions.map((deduction) => [
            deduction.description,
            deduction.type,
            deduction.id,
          ])
        )
        .map((item) => [item[0], item])
    ).values(),
  ];

  // Transform the data to fit the Handsontable structure
  const data = rawData.map((staff) => {
    const row = {
      name: staff.name,
      ...staff.deductions.reduce((acc, deduction) => {
        acc[deduction.description] = deduction.amount;
        return acc;
      }, {}),
    };

    // Ensure all deduction types are present in each row
    deductionTypes.forEach(([type]) => {
      if (!row.hasOwnProperty(type)) {
        row[type] = null; // Set to null instead of 0
      }
    });

    return row;
  });

  const customRenderer = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    td.innerHTML = value === 0 ? "" : `${value}`;
    td.style.padding = "10px";
    return td;
  };

  const percentageRenderer = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    const percentageValue = value === 0 ? "" : `${value}%`;
    td.innerHTML = percentageValue;
    td.style.padding = "10px";
    return td;
  };

  const afterGetColHeader = (col, TH) => {
    TH.style.padding = "7px";
    TH.style.fontWeight = "bold";
  };

  useEffect(() => {
    setRawData(fetchedData);
  }, [fetchedData]);

  const transformHotToData = (hotData) => {
    if (!Array.isArray(hotData)) {
      return [];
    }

    const flattenDeductions = (data) => {
      const staffId = data.id;
      return data.deductions.map((deduction) => ({
        staff: staffId,
        deductionType: deduction.id,
        amount: deduction.amount,
      }));
    };

    return hotData.reduce((acc, row, rowIndex) => {
      const convertedDate = {
        id: fetchedData[rowIndex]?._id,
        deductions: deductionTypes.map(([_, __, id], index) => ({
          id,
          amount: row[1 + index] || 0, // Adjusted for Payment Date and Name
        })),
      };
      return acc.concat(flattenDeductions(convertedDate));
    }, []);
  };

  const handleSave = async (event) => {
    try {
      event.preventDefault();
      const hot = hotRef.current?.hotInstance;

      if (hot) {
        const tableData = hot.getData();
        const formData = transformHotToData(tableData);

        const { data, error } = await saveEmployeeDeductions(formData);

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }

        toast.success("Saved Successfully");
      }
    } catch (error) {
      const errorMessage = ExtractError(error);
      toast.error(errorMessage);
    }
  };

  return (
    <Form onSubmit={handleSave}>
      <HotTable
        ref={hotRef}
        data={data}
        height="60vh"
        autoWrapRow={true}
        autoWrapCol={true}
        licenseKey="non-commercial-and-evaluation"
        width="100%"
        stretchH="all"
        style={{ width: "100%", height: "100vh" }}
        colHeaders={["Name", ...deductionTypes.map(([type]) => type)]}
        rowHeaders={true}
        search={true}
        columnSorting={true}
        filters={true}
        dropdownMenu={true}
        contextMenu={true}
        copyPaste={true}
        afterGetColHeader={afterGetColHeader}
      >
        <HotColumn data="name" renderer={customRenderer} />
        {deductionTypes.map(([type, colType]) => (
          <HotColumn
            key={type}
            data={type}
            type={colType === "percentage" ? "numeric" : "text"}
            renderer={
              colType === "percentage" ? percentageRenderer : customRenderer
            }
          />
        ))}
      </HotTable>
      <hr />
      <div className="d-flex justify-content-end gap-1">
        <Button
          color="primary"
          type="submit"
          className="mt-2 float-right"
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </Form>
  );
};

export default DeductionsTable;
