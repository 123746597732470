import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import customDispatch from "@commons/utils/HandledDispatch";
import Wizard from "@components/wizard";
import cities from "@src/data/cities";
import regions from "@src/data/regions";
import axios from "axios";
import { CardImage, LifePreserver, ListColumns } from "react-bootstrap-icons";
import { FileText, MapPin, UserPlus } from "react-feather";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ErrorHandler, ExtractError } from "../../../common/utils/Error";
import {
  createBuilding,
  useCreateBuildingsMutation,
  useGetSingleBuildingQuery,
  useUpdateBuildingsMutation,
} from "../../../redux/building";
import PropertyAmenity from "./steps/amenities";
import OwnerForm from "./steps/ownerform";
import PropertyAddress from "./steps/propertyAddress";
import PropertyInformation from "./steps/propertyInformation";
import PropertyMedia from "./steps/propertyMedia";
import { Card, Spinner } from "reactstrap";
import PropertyPricing from "./steps/propertyPricing";
import { useGetSingleContractQuery } from "../../../redux/contracts";
import moment from "moment";

// Define Yup validation schema
const validationSchema = (isNewOwner) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    slug: Yup.string().required("Slug is required"),
    description: Yup.string().required("Description is required"),
    floors: Yup.number()
      .required("Floors is required")
      .positive()
      .integer()
      .min(0, "Price cannot be negative"),

    //pricing
    price: Yup.number()
      .required("Price is required")
      .positive()
      .min(0, "Price cannot be negative"),
    reservationFee: Yup.number()
      .required("Reservation fee is required")
      .positive()
      .min(0, "Price cannot be negative"),
    advanceType: Yup.string().required("Advance type is required"),
    advance: Yup.number()
      .required("Advance fee is required")
      .positive()
      .min(0, "Price cannot be negative"),
    deposit: Yup.number()
      .required("Deposit fee is required")
      .positive()
      .min(0, "Price cannot be negative"),
    depositType: Yup.string().required("Deposit type is required"),

    //

    category: Yup.string().required("Property Type is required"),
    contacts: Yup.object().shape({
      phone: Yup.string().required("Phone is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    }),

    location: Yup.object().shape({
      coordinates: Yup.object().shape({
        latitude: Yup.number()
          .required("Latitude is required")
          .typeError("Latitude is required"),
        longitude: Yup.number()
          .required("Longitude is required")
          .typeError("Latitude is required"),
      }),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
    }),
    owner_id: Yup.string().when([], {
      is: () => !isNewOwner,
      then: Yup.string().required("Owner is required"),
    }),
    owner: Yup.object().when([], {
      is: () => isNewOwner,
      then: Yup.object().shape({
        name: Yup.string().required("Owner name is required"),
        phone: Yup.string().required("Owner phone is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Owner email is required"),
        emergency_contact: Yup.string().required(
          "Emergency contact is required"
        ),
        address: Yup.string().required("Owner address is required"),
      }),
      otherwise: Yup.object().shape({
        name: Yup.string(),
        phone: Yup.string(),
        email: Yup.string().email("Invalid email format"),
        emergency_contact: Yup.string(),
        address: Yup.string(),
      }),
    }),
    videoUrl: Yup.string().url("Invalid URL"),
    landArea: Yup.string().required("Land area is required"),
    garages: Yup.number()
      .required("Garages is required")
      .integer("Garages must be an integer")
      .min(0, "Garages cannot be negative"),
    garageSize: Yup.string().required("Garage size is required"),
    yearBuilt: Yup.number()
      .required("Year built is required")
      .positive()
      .integer(),
    propertyStatus: Yup.string().required("Property status is required"),

    amenities: Yup.array().of(Yup.string()).required("Amenities are required"),
  });

const defaultValues = {
  name: "",
  slug: "",
  description: "",
  floors: 0,
  price: 0,
  category: "",

  location: {
    coordinates: {
      latitude: "",
      longitude: "",
    },
    address: "",
    country: "",
    city: "",
    state: "",
  },
  owner_id: "",
  owner: {
    name: "",
    phone: "",
    email: "",
    emergency_contact: "",
    address: "",
  },
  videoUrl: "",
  landArea: "",
  garages: 0,
  garageSize: "0",
  yearBuilt: new Date().getFullYear(),
  propertyStatus: "",
  deposit: 0,
  reservationFee: 0,
  depositType: "value",
  advanceType: "value",
  advance: 0,
  amenities: [],

  //
  contacts: {
    phone: "61233870",
    email: "",
  },
};

export default function AddListing() {
  const [isNewOwner, setIsNewOwner] = useState(false);

  //* RTK query
  const [createBuilding, { isLoading: isCreating }] =
    useCreateBuildingsMutation();

  const [updateBuilding, { isLoading: isUpdating }] =
    useUpdateBuildingsMutation();

  const { id } = useParams();

  const { data: selectedBuilding, isLoading: loading } =
    useGetSingleBuildingQuery(id, {
      skip: !id,
    });

  const [categories, setCategories] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [owners, setOwners] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      var { status, data } = await axios.get("/categories/list");
      if (status == 200) {
        setCategories(data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getAllOwners = async () => {
    try {
      var { status, data } = await axios.get("/owners/list");
      if (status == 200) {
        setOwners(data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getAllAmenities = async () => {
    try {
      const { data, status } = await axios.get("/amenities/list");

      if (status == 200) {
        setAmenities(data.data);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);

  //*
  const defaultValues = {
    name: selectedBuilding?.name || "",
    slug: selectedBuilding?.slug || "",
    description: selectedBuilding?.description || "",
    floors: selectedBuilding?.floors || 0,
    price: selectedBuilding?.price || 0,
    category: selectedBuilding?.category || "",

    location: {
      coordinates: {
        latitude: selectedBuilding?.location?.coordinates?.latitude || "",
        longitude: selectedBuilding?.location?.coordinates?.longitude || "",
      },
      address: selectedBuilding?.location?.address || "",
      country: selectedBuilding?.location?.country || "",
      city: selectedBuilding?.location?.city || "",
      state: selectedBuilding?.location?.state || "",
    },
    owner_id: selectedBuilding?.owner_id || "",
    owner: {
      name: selectedBuilding?.owner?.name || "",
      phone: selectedBuilding?.owner?.phone || "",
      email: selectedBuilding?.owner?.email || "",
      emergency_contact: selectedBuilding?.owner?.emergency_contact || "",
      address: selectedBuilding?.owner?.address || "",
    },
    videoUrl: selectedBuilding?.videoUrl || "",
    landArea: selectedBuilding?.landArea || "",
    garages: selectedBuilding?.garages || 0,
    garageSize: selectedBuilding?.garageSize || "0",
    yearBuilt:
      moment(selectedBuilding?.yearBuilt).format("YYYY") ||
      new Date().getFullYear(),
    propertyStatus: selectedBuilding?.propertyStatus || "",
    deposit: selectedBuilding?.deposit || 0,
    reservationFee: selectedBuilding?.reservationFee || 0,
    depositType: selectedBuilding?.depositType || "value",
    advanceType: selectedBuilding?.advanceType || "value",
    advance: selectedBuilding?.advance || 0,
    amenities: selectedBuilding?.amenities || [],

    contacts: {
      phone: selectedBuilding?.contacts?.phone || "",
      email: selectedBuilding?.contacts?.email || "",
    },
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    getValues,

    setError,
    setValue,
    clearErrors,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(isNewOwner)),
  });

  useEffect(() => {
    getCategories();
    getAllAmenities();
    getAllOwners();
  }, []);

  const onSubmit = async (data) => {
    try {
      var formdata = new FormData();

      for (const key in data) {
        formdata.append(key, data[key]);
      }

      if (!selectedBuilding && !thumbnail.length) {
        toast.error("thumbnail image is required");
        return;
      }

      if (!selectedBuilding && !files.length) {
        toast.error("please add at least one image");
        return;
      }

      if (thumbnail.length)
        formdata.append(`thumbnail`, thumbnail[0], thumbnail[0].name);

      if (!files.length)
        files.forEach((file, i) => {
          formdata.append(`file`, file, file.name);
        });

      formdata.set(
        "location",
        JSON.stringify({
          coordinates: {
            latitude: data.location.coordinates.latitude,
            longitude: data.location.coordinates.longitude,
          },
          address: data.location.address ?? "",
          country: data.location.country,
          city: data.location.city,
          state: data.location.state,
        })
      );

      formdata.set("contacts", JSON.stringify(data.contacts));
      formdata.set("owner", JSON.stringify(data.owner));
      formdata.set("amenities", JSON.stringify(data.amenities));
      formdata.set("category", data.category);
      formdata.set("owner_id", data.owner_id);
      formdata.set("propertyStatus", data.propertyStatus ?? "For Rent");

      if (selectedBuilding) {
        const { data, error } = await updateBuilding({
          id: selectedBuilding._id,
          data: formdata,
        });

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }
        toast.success("Property updated Successfully");
        navigate(`/properties${appView ? `?view=${appView}` : ""}`);
      } else {
        const { data, error } = await createBuilding(formdata);

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }
        toast.success("New Property created Successfully");
        navigate(`/properties${appView ? `?view=${appView}` : ""}`);
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error);
    }
  };

  const ref = useRef(null);

  // ** State
  const [stepper, setStepper] = useState(null);

  const steps = [
    {
      id: "owner-details",
      title: "Owner",
      subtitle: "Owner Details.",
      inputs: [
        "owner_id",
        "owner.name",
        "owner.phone",
        "owner.email",
        "owner.emergency_contact",
        "owner.address",
      ],
      icon: <UserPlus size={18} />,
      content: (
        <OwnerForm
          stepper={stepper}
          control={control}
          errors={errors}
          setValue={setValue}
          register={register}
          owners={owners}
          appView={appView}
          setIsNewOwner={setIsNewOwner}
          isNewOwner={isNewOwner}
        />
      ),
    },
    {
      id: "property-details",
      title: "Property Details",
      subtitle: "Property Details.",
      inputs: [
        "description",
        "yearBuilt",
        "garageSize",
        "garages",
        "landArea",
        "floors",
        "email",
        "phone",
        "propertyStatus",
        "category",
        "name",
      ],
      icon: <FileText size={18} />,
      content: (
        <PropertyInformation
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          categories={categories}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          appView={appView}
        />
      ),
    },
    {
      id: "pricing-info",
      title: "Pricing",
      subtitle: "Property pricing",
      inputs: ["reservationFee", "advance", "deposit", "price", "landArea"],
      icon: <ListColumns size={18} />,
      content: (
        <PropertyPricing
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          getValues={getValues}
          categories={categories}
          appView={appView}
        />
      ),
    },
    {
      id: "property-amenities",
      title: "Amenities",
      subtitle: "Add Property Amenities",
      inputs: ["amenities"],
      icon: <LifePreserver size={18} />,
      content: (
        <PropertyAmenity
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          amenities={amenities}
          getValues={getValues}
          setValue={setValue}
          appView={appView}
        />
      ),
    },
    {
      id: "property-address",
      title: "Address",
      subtitle: "Add Address",
      inputs: [
        "location.coordinates.longitude",
        "location.coordinates.latitude",
        "location.address",
        "location.city",
        "location.state",
        "location.country",
      ],
      icon: <MapPin size={18} />,
      content: (
        <PropertyAddress
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
          regions={regions}
          cities={cities}
          appView={appView}
        />
      ),
    },
    {
      id: "property-media",
      title: "Property Media",
      subtitle: "Add Property Media",
      icon: <CardImage size={18} />,
      content: (
        <PropertyMedia
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          files={files}
          setFiles={setFiles}
          loading={isCreating || isUpdating}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
        />
      ),
    },
  ];

  useEffect(() => {
    console.log("Errors ", errors);
  }, [errors]);

  useEffect(() => {
    if (selectedBuilding) {
      reset(defaultValues);
    }
  }, [id, selectedBuilding]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <Card className="p-2 mb-5">
      <div>
        <h4 className="fw-bolder">Add New Property</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modern-horizontal-wizard">
          <Wizard
            type="modern-horizontal"
            ref={ref}
            steps={steps}
            options={{
              linear: false,
            }}
            instance={(el) => setStepper(el)}
            className="bg-white rounded-sm"
            contentWrapperClassName="shadow-none rounded-0"
            headerClassName="shadow-none rounded-0"
            errors={errors}
          />
        </div>
      </form>
    </Card>
  );
}
