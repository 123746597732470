import moment from "moment";
import React, { useState } from "react";
import { Edit, Trash } from "react-feather";
import toast from "react-hot-toast";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomDataTable from "../../../common/CustomDataTable";
import PayrollModel from "./PayrollModel";

import { Link } from "react-router-dom";
import {
  useDeletePayrollMutation,
  useGetAllPayrollsQuery,
} from "../../../redux/payroll";

const MySwal = withReactContent(Swal);

const ContractTypes = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [data, setData] = useState([]);

  const [deletePayroll] = useDeletePayrollMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Account ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deletePayroll(id);
        if (responseStatus) {
          toast.success("Payroll been Deleted.");
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Name",
      sortField: "name",
      sortable: true,
      selector: ({ name }) => name,
      cell: (row) => (
        <Link
          to={`view/${row._id}`}
          className="d-flex align-items-center cursor-pointer text-truncate"
        >
          <p className="fw-bolder text-truncate"> {row.name}</p>
        </Link>
      ),
    },
    {
      name: "Payment Month",
      sortField: "payMonth",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "250px" }}>
          {moment(row.payMonth).format("MMMM YYYY")}
        </div>
      ),
    },
    {
      name: "Time Range",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "350px" }}>
          {moment(row.payPeriodStartDate).format("DD MMMM") +
            " - " +
            moment(row.payPeriodEndDate).format("DD MMMM")}
        </div>
      ),
    },
    {
      sortable: true,
      name: "User",
      cell: ({ createdBy }) => createdBy?.username,
    },
    {
      sortable: true,
      name: "Created Date",
      selector: (row) => row.createdAt,
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY"),
      sortFunction: (rowA, rowB) =>
        new Date(rowB.createdAt) - new Date(rowA.createdAt),
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span>{row.status}</span>
        </Badge>
      ),
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Payroll"
        fetchData={useGetAllPayrollsQuery}
        searchFields={["name", "description"]}
        populate={[
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"Add New"}
        addButtonClick={() => setIsModalVisible(true)}
      />

      <PayrollModel
        selectedRow={selectedRow}
        show={isModalVisible}
        setShow={setIsModalVisible}
        setSelectedRow={setSelectedRow}
        setData={setData}
      />

      {/* <Test /> */}
      {/* <PayrollTable data={rows/>} /> */}
    </div>
  );
};

export default ContractTypes;
