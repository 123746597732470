import { useState } from "react";

const useTableData = (
  fetchData,
  initialSearchFields = [],
  query = {},
  initialPopulate = []
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortBy, setSortBy] = useState({ createdAt: "desc" });
  const [search, setSearch] = useState("");
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [populate, setPopulate] = useState(initialPopulate);

  const { data, isLoading, isFetching } = fetchData({
    search: { keyword: search, fields: searchFields },
    query,
    options: {
      limit: rowsPerPage,
      page: currentPage,
      populate: populate,
      sort: sortBy,
    },
  });

  return {
    data,
    isLoading,
    isFetching,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    search,
    setSearch,
    searchFields,
    setSearchFields,
    populate,
    setPopulate,
  };
};

export default useTableData;
