import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { AlertTriangle, Check, X } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import { formatCurrency } from "../../../utility/Utils";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import moment from "moment";
import { PlusCircleFill, Triangle } from "react-bootstrap-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import { TreeSelect } from "primereact/treeselect";
import ReactSelect from "react-select";

export default function JournalEntry({ row, show, ShowModal, loader }) {
  const defaultValues = {
    date: moment().format("YYYY-MM-DD"),
    lines: [
      { account: "", description: "", debit: 0, credit: 0, ref:{} },
      { account: "", description: "", debit: 0, credit: 0, ref:{} },
    ],
  };

  const [options, setOptions] =useState([]);

  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "lines",
  });

  const [accounts, setAccounts] = useState([]);

  const [error, setClientError] = useState(null);

  const dispatch = useDispatch();
  const [btnloading, setBtnloading] = useState(false);

  const onSubmit = async (data) => {
    setBtnloading(true);

    try {
      let totalDebit = calculateEntry("debit");
      let totalCredit = calculateEntry("credit");

      if (totalDebit != totalCredit) {
        setClientError("Total debit must be equal total credit");
        return;
      } else {
        setClientError(null);
      }

      data.lines = data.lines
        .filter((acc) => Number(acc.debit) != 0 || Number(acc.credit) != 0)
        .map((l) => ({
          ...l,
          ref: l.ref ? ({
            _id:l.ref?.value,
            name:l.ref?.label,
            type:l.ref?.type
          }) : null,
          account: l.account?.value,
          accountName: l.account?.label,
        }));


        // console.log(data)
        // return

      if (row) {
        const response = await axios.patch("/accountings/journal/entry", data);
        if (response.status == 200) {
          toast.success("Entry updated Succesfully");
          reset();
          ShowModal();
          if (loader) {
            loader();
          }
        }
      } else {
        const response = await axios.post("/accountings/journal/entry", data);
        if (response.status == 200) {
          toast.success("New Entry Registered");
          reset();
          ShowModal();
          if (loader) {
            loader();
          }
        }
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error);
    } finally {
      setBtnloading(false);
    }
  };

  const onDiscard = () => {
    ShowModal();
    reset(defaultValues);
  };

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/type/subaccounts"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const calculateEntry = (type) => {
    let lines = getValues().lines;
    let total = 0;
    if (type == "debit") {
      total = lines?.reduce((prev, curr) => prev + Number(curr.debit), 0);
    } else {
      total = lines?.reduce((prev, curr) => prev + Number(curr.credit), 0);
    }
    return total;
  };

  const formatTreeNodes = () => {
    let acc = [];
    accounts
      .filter((acc) => acc.type == null)
      .forEach((account) => {
        acc.push({
          key: account._id,
          label: account.name,
          data: account.name,
          selectable: !account.systemAccount,
          children: getSubAccounts(account._id),
        });
      });

    return acc;
  };

  const getSubAccounts = (parent) => {
    return accounts
      .filter((account) => {
        if (account.parentAccount == parent && account.type == parent) {
          return false;
        } else if (account.parentAccount != null && account.type == parent) {
          return false;
        } else if (account.parentAccount == parent || account.type == parent) {
          return true;
        }
      })
      .map((account) => ({
        key: account._id,
        label: account.name,
        data: account.name,
        selectable: !account.systemAccount,
        children: getSubAccounts(account._id),
      }));
  };

  const getReferences = async () => {
    try {
      var { status, data } = await axios.get( "/accountings/journal/references" );
      if (status === 200) {
        setOptions(data?.data ?? []);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(()=>{
    getReferences()
  },[])

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onDiscard}
        className="modal-dialog-centered modal-fullscreen"
        backdrop="static"
      >
        <ModalHeader toggle={onDiscard} className="py-1 bg-white">
          {row ? "Edit Entry" : "Make Journal Entry"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 mx-50">
            <Row className="mb-2">
              <Col className="mb-1" sm={12} md={6}>
                <Label className="form-label" for="date">
                  Entry Date
                </Label>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.date && true}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col className="mb-1" sm={12} md={6}>
                <Label className="form-label" for="reference">
                  Reference {}
                </Label>
                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="reference"
                      placeholder="Ex. 1"
                      invalid={errors.unit && true}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
            </Row>

            {fields.map((field, index) => (
              <Row key={field.id} className="mb-1">
                <Col xs={12} md={3}>
                  <Label className="form-label" for={`account_${field.id}`}>
                    Account
                  </Label>
                  <Controller
                    name={`lines.${index}.account`}
                    control={control}
                    rules={{ required: "Account is required." }}
                    render={({ field, fieldState }) => (
                      <TreeSelect
                        id={`account_${field.id}`}
                        value={field.value.value}
                        onChange={(val) => {
                          let accName =
                            accounts.find((acc) => acc._id == val.value).name ??
                            "";

                          field.onChange({
                            value: val.value,
                            label: accName,
                          });
                        }}
                        inputRef={field.ref}
                        filter
                        options={formatTreeNodes()}
                        placeholder="Select Item"
                        className={classNames("w-100", {
                          "p-invalid": fieldState.error,
                        })}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <Label className="form-label" for={`accountDes_${field.id}`}>
                    Description
                  </Label>
                  <Controller
                    name={`lines.${index}.description`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        id={`accountDes_${field.id}`}
                        // invalid={errors.lines![index].account && true}
                        {...field}
                        type="text"
                        options={[]}
                      />
                    )}
                  />
                  {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
                </Col>
                <Col xs={12} md={2}>
                  <Label className="form-label" for={`debit_acc_${field.id}`}>
                    Debit
                  </Label>
                  <Controller
                    name={`lines.${index}.debit`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id={`debit_acc_${field.id}`}
                        invalid={
                          errors.lines?.length &&
                          errors.lines[index]?.debit &&
                          true
                        }
                        {...field}
                        type="number"
                        step={"any"}
                        onChange={(v) => {
                          field.onChange(v);
                          if (v) {
                            setValue(`lines.${index}.credit`, 0);
                          }
                          trigger(`lines.${index}.debit`);
                        }}
                      />
                    )}
                  />
                  {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
                </Col>
                <Col xs={12} md={2}>
                  <Label className="form-label" for={`crr_account_${field.id}`}>
                    Credit
                  </Label>
                  <Controller
                    name={`lines.${index}.credit`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id={`crr_account_${field.id}`}
                        invalid={
                          errors.lines?.length &&
                          errors.lines[index]?.credit &&
                          true
                        }
                        {...field}
                        type="number"
                        step={"any"}
                        onChange={(v) => {
                          field.onChange(v);
                          if (v) {
                            setValue(`lines.${index}.debit`, 0);
                          }
                          trigger(`lines.${index}.credit`);
                        }}
                      />
                    )}
                  />
                  {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
                </Col>
                <Col xs={12} md={2}>
                  <Label className="form-label" for="owner_id">
                    Name
                  </Label>

                  <Controller
                    name={`lines.${index}.ref`}
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        id={`ref_${field.id}`}
                        // invalid={errors.owner_id && true}
                        {...field}
                        // value={buildingTypes.find(b=>b.value==field.value)}
                        options={options.map(item=>({
                          value:item._id,
                          label:item.name+" - "+ (item.phone || item.contacts)                       
                      }))}
                      />
                    )}
                  />
                </Col>
              </Row>
            ))}

            <Row className="justify-content-end mt-3">
              <Col sm={12} md={6}>
                <p
                  className=""
                  role={"button"}
                  onClick={() =>
                    append({
                      account: "",
                      description: "",
                      debit: 0,
                      credit: 0,
                    })
                  }
                >
                  <PlusCircleFill /> Add Line
                </p>
              </Col>
              <Col md={3}>
                <div className="">
                  <p className="mb-1">Total Debits: </p>
                  <h3 className="fw-bolder mb-0">
                    {formatCurrency(calculateEntry("debit"))}
                  </h3>
                </div>
              </Col>
              <Col md={3}>
                <div className="">
                  <p className="mb-1">Total Credits: </p>
                  <h3 className="fw-bolder mb-0">
                    {formatCurrency(calculateEntry("credit"))}
                  </h3>
                </div>
              </Col>
            </Row>
            {error && (
              <Alert color="danger" className="p-1 mt-3 ">
                <AlertTriangle size={15} className="align-middle" /> {error}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={btnloading}
            >
              <Spinner size={"sm"} hidden={!btnloading} className="me-1" />
              {btnloading ? "Loading.." : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
}
