import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Alert,
  ModalFooter,
  Spinner,
} from "reactstrap";

// ** Third Party Components
import * as yup from "yup";
import toast from "react-hot-toast";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateShiftMutation,
  useUpdateShiftMutation,
} from "../../../redux/shifts";

// ** Redux RTK Query Imports

const ShiftSchema = yup.object().shape({
  name: yup.string().required(),
});
const defaultValues = {
  name: "",
};

function ShiftModal({ show, ShowModal, selectedRow, setSelectedRow }) {
  // ** RTK Query Mutations
  const [createShift, { isLoading: isCreating }] = useCreateShiftMutation();
  const [updateShift, { isLoading: isUpdating }] = useUpdateShiftMutation();

  // ** Handles Save & Add New Shift
  const [isNew, setIsNew] = useState(false);

  // ** useForm hook vars
  const {
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(ShiftSchema),
  });

  // ** Handles form submission
  const onSubmit = async (data) => {
    try {
      if (selectedRow) {
        const resData = await updateShift({
          data,
          id: selectedRow._id,
        }).unwrap();
        if (resData) {
          toast.success("Shift Updated successfully!");
          onDiscard();
        }
      } else {
        const res = await createShift(data).unwrap();
        if (res) {
          toast.success("Shift added successfully!");
          onDiscard();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset(defaultValues);
    setSelectedRow(null);
  };

  useEffect(() => {
    reset({ ...selectedRow });
  }, [selectedRow]);

  return (
    <Modal isOpen={show} onClosed={onDiscard} toggle={() => ShowModal(!show)}>
      <ModalHeader toggle={onDiscard} className="bg-white">
        {selectedRow ? "Update Shift" : "Add New Shift"}
      </ModalHeader>

      <Row tag={Form} onSubmit={handleSubmit(onSubmit)} className="p-2">
        <ModalBody>
          <Col xs={12}>
            <Label className="form-label" for="name">
              Shift Name
            </Label>
            <Controller
              control={control}
              id="name"
              name="name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Shift Name"
                  invalid={errors?.name && true}
                />
              )}
            />
            {errors && errors?.name && (
              <FormFeedback>{errors?.name?.message}</FormFeedback>
            )}
          </Col>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button
            type="submit"
            className="me-1"
            color="primary"
            disabled={isCreating || isUpdating}
          >
            <Spinner
              color="light"
              size="sm"
              className="me-1"
              hidden={!isCreating || !isUpdating}
            />
            {isCreating || isUpdating ? "Submitting" : "Submit"}
          </Button>
          <Button
            type="reset"
            className=""
            color="dark"
            outline
            onClick={onDiscard}
          >
            Discard
          </Button>
        </ModalFooter>
      </Row>
    </Modal>
  );
}

export default ShiftModal;
