import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Map, Send } from "react-feather";
import Workplace from "../workplace";
import WorkplaceAllocation from "../allocation";

const WorkplaceTabs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      {/* <h3>Workplace</h3> */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            <Send width={17} height={17} className="me-50" />
            <span>Workplace Allocation</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            <Map width={17} height={17} className="me-50" />
            Workplace
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <WorkplaceAllocation />
        </TabPane>
        <TabPane tabId="2">
          <Workplace />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default WorkplaceTabs;
