import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { ListGroup, ListGroupItem } from "reactstrap";

const Summery = ({ unitData, financeData }) => {
  const getChartData = (dataEntries, backgroundColor, borderColor) => {
    return {
      labels: dataEntries.map((entry) => entry.label),
      datasets: [
        {
          label:
            dataEntries === unitData ? "Unit Metrics" : "Financial Metrics",
          data: dataEntries.map((entry) => {
            if (typeof entry.value === "string" && entry.value.includes("$")) {
              return parseInt(entry.value.replace(/[\$,%]/g, ""));
            } else if (typeof entry.value === "string") {
              return parseFloat(entry.value.replace(/[\$,%]/g, ""));
            }
            return entry.value;
          }),
          backgroundColor,
          borderColor,
          borderWidth: 1,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const unitColors = {
    backgroundColor: "rgba(54, 162, 235, 0.2)",
    borderColor: "rgba(54, 162, 235, 1)",
  };

  const financeColors = {
    backgroundColor: "rgba(255, 206, 86, 0.2)",
    borderColor: "rgba(255, 206, 86, 1)",
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-3">
        <div className="mt-md-0 mt-2">
          <h4 className="invoice-title">Summary</h4>
          <div className="invoice-date-wrapper">
            <ListGroup>
              {[...unitData, ...financeData].map((entry, index) => (
                <ListGroupItem key={index}>
                  {entry.label}: {entry.value}
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          height: "1000px",
        }}
      >
        <div style={{ height: "500px", width: "600px" }}>
          <Bar
            data={getChartData(
              unitData,
              unitColors.backgroundColor,
              unitColors.borderColor
            )}
            options={chartOptions}
          />
        </div>
        <div style={{ height: "500px", width: "600px" }}>
          <Bar
            data={getChartData(
              financeData,
              financeColors.backgroundColor,
              financeColors.borderColor
            )}
            options={chartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Summery;
