import React from "react";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";
import ReportLink from "./components/reportLink";
import AllReportsLinks from "./reports";

export default function Reports() {
  const [AllReports, setAllReports] = useState(AllReportsLinks);

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const getFavoriteReports = () => {
    let favour = [];
    for (const key in AllReportsLinks) {
      AllReportsLinks[key].map((link) => {
        if (link.isFavorite) favour.push(link);
      });
    }
    return favour;
  };

  return (
    <div>
      <Card>
        {/* <CardBody>
          <CardTitle>Favorites</CardTitle>
          <div className="row gap-1">
            {getFavoriteReports().map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>*/}
      </Card>

      {/* <Card>
        <CardBody>
          <CardTitle>Financial Reports</CardTitle>
          <div className="row gap-1">
            {AllReports.accounting?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>
      </Card> */}

      <Card>
        <CardBody>
          <CardTitle>Tenants Reports</CardTitle>
          <div className="row gap-1">
            {AllReports.tenant?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Units Reports</CardTitle>
          <div className="row gap-1">
            {AllReports.units?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Property Reports</CardTitle>
          <div className="row ">
            {AllReports.property?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
            {AllReports.lease?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}

            {AllReports.owner?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>
      </Card>

      {/* Services reports */}

      <Card>
        <CardBody>
          <CardTitle>Services Reports</CardTitle>
          <div className="row ">
            {AllReports.service?.map((report, idx) => (
              <ReportLink
                key={idx}
                link={report?.link + (appView ? `&view=${appView}` : "") ?? "#"}
                title={report?.title ?? ""}
                isFavorite={report?.isFavorite ?? false}
              />
            ))}
          </div>
        </CardBody>
      </Card>

      {/* <Card>
        <CardBody>
          <CardTitle>Leases Reports</CardTitle>
          <div className="row gap-1">
           
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle>Owners Reports</CardTitle>
          <div className="row gap-1">
            
          </div>
        </CardBody>
      </Card> */}
    </div>
  );
}
