import { createSlice } from "@reduxjs/toolkit";

export const HomeSlice = createSlice({
    name: "CurrentBuilding",
    initialState: {
      data: null,
    },
    reducers: {
      loginBuilding: (state, {payload}) => {
        state.data =payload;
      },

      exitBuilding: (state, {payload}) => {
        state.data =null;
      },
      
    }
  });

  export const {loginBuilding,exitBuilding} = HomeSlice.actions;
  export default  HomeSlice.reducer;