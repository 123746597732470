import React from "react";
import { useSearchParams } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledCollapse,
} from "reactstrap";
import { ErrorHandler } from "../../common/utils/Error";

import Logo from "@src/assets/images/logo/logo-icon.png";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FileExcel, Filter, Printer } from "react-bootstrap-icons";
import moment from "moment";
import axios from "axios";
import { format } from "prettier";
import {
  convertDaysToMonthsAndYears,
  formatCurrency,
} from "../../utility/Utils";
import classNames from "classnames";
import { AlertTriangle } from "react-feather";
import DataTable from "react-data-table-component";
import TableLoading from "../../@core/components/base/TableLoading";

export default function ServicesReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [reportDate, setReportDate] = useState(new Date().now);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Services Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const queryFields = {
    type: searchParams.get("type") ?? "",
  };

  const getServicesReport = async () => {
    try {
      const { status, data } = await axios.get("/reports/services", {
        params: queryFields,
      });
      if (status == 200) {
        setData(data.data);
      } else {
        toast.error("Error Detected");
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getServicesReport();
  }, []);

  console.log("defaultValues : ", queryFields);

  return (
    <div>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="text-capitalize">{queryFields.type} Report</h4>
        </CardHeader>
      </Card>
      <Card innerRef={printRef} dir="ltr">
        <CardHeader className="justify-content-end gap-1 mb-0 pb-0 no-print">
          <Printer
            size={23}
            className="cursor-pointer"
            title="Print"
            onClick={() => handlePrint()}
          />
          <FileExcel
            size={23}
            className="cursor-pointer"
            title="Export to Excell"
            onClick={() => {
              // beutify before print
              // downloadCSV(data)
            }}
          />
        </CardHeader>

        <CardHeader className="justify-content-between">
          <img src={Logo} alt="" width={60} />

          <div>
            <h3 className="text-capitalize">{queryFields.type} Report</h3>
            <p>{moment(reportDate).format("DD MMMM YYYY")}</p>
          </div>
        </CardHeader>
        <hr />
        <CardBody>
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Type</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Balance</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.invoiceNo}</td>
                  <td>{moment(item.createdAt).format("DD-MMM-YYYY")}</td>
                  <td>{item.type}</td>
                  <td>{item.customer?.name}</td>
                  <td>{formatCurrency(item.grandTotal)}</td>
                  <td>
                    <span className="text-success fw-bolder">
                      ${item.grandTotal - item.balance ?? 0}
                    </span>
                  </td>
                  <td>
                    {item.balance == 0 ? (
                      <Badge
                        color="light-success"
                        id={`status-tooltip-${item._id}`}
                        className="text-capitalize fw-bolder"
                      >
                        Paid
                      </Badge>
                    ) : item.balance == item.grandTotal ? (
                      <Badge
                        color="light-warning"
                        id={`status-tooltip-${item._id}`}
                        className="text-capitalize fw-bolder"
                      >
                        Unpaid
                      </Badge>
                    ) : (
                      <span
                        className="text-secondary fw-bolder"
                        id={`status-tooltip-${item._id}`}
                      >
                        ${item.balance}
                      </span>
                    )}
                  </td>
                  <td>{item.createdBy?.username}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"100%"} className="fw-bolder">
                  Total Entries : {data.length}
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
