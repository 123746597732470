import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const salaryAllowanceTypesApi = createApi({
  reducerPath: "SalaryAllowanceTypes",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SalaryAllowanceTypes", "SalaryAllowanceList"],
  endpoints: (builder) => ({
    // Read
    getSalaryAllowanceTypes: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/salary-allowance-types?${serializedParams}`;
      },
      providesTags: ["SalaryAllowanceTypes"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getSalaryAllowanceTypesList: builder.query({
      query: () => {
        return `/hrm/salary-allowance-types/list`;
      },
      providesTags: ["SalaryAllowanceList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createSalaryAllowanceType: builder.mutation({
      query: (data) => ({
        url: "/hrm/salary-allowance-types",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SalaryAllowanceTypes", "SalaryAllowanceList"],
    }),

    // Update
    updateSalaryAllowanceTypes: builder.mutation({
      query: ({ data: SalaryAllowanceTypes, id }) => ({
        url: `/hrm/salary-allowance-types/${id}`,
        method: "PATCH",
        body: SalaryAllowanceTypes,
      }),
      invalidatesTags: ["SalaryAllowanceTypes", "SalaryAllowanceList"],
    }),

    // Delete
    deleteSalaryAllowanceTypes: builder.mutation({
      query: (id) => ({
        url: `/hrm/salary-allowance-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SalaryAllowanceTypes", "SalaryAllowanceList"],
    }),
  }),
});

export const {
  useGetSalaryAllowanceTypesQuery,
  useGetSalaryAllowanceTypesListQuery,
  useCreateSalaryAllowanceTypeMutation,
  useUpdateSalaryAllowanceTypesMutation,
  useDeleteSalaryAllowanceTypesMutation,
} = salaryAllowanceTypesApi;
