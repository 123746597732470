import classNames from "classnames";
import React from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { ArrowLeft } from "react-feather";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select/creatable";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";

export default function OwnerForm({
  stepper,
  control,
  register,
  errors,
  setValue,
  owners,
  isNewOwner,
  setIsNewOwner,
}) {
  const ownersOptions = owners?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const nextClick = () => {
    stepper.next();
  };

  return (
    <>
      <div className="mb-2">
        <Label className="form-label" for="owner_id">
          Property Owner
        </Label>

        <Controller
          name="owner_id"
          control={control}
          rules={{ required: !isNewOwner }}
          render={({ field }) => (
            <ReactSelect
              id="owner_id"
              aria-invalid={errors.owner_id && true}
              className={`react-select ${errors.owner_id ? "is-invalid" : ""}`}
              classNamePrefix="select"
              {...field}
              options={ownersOptions}
              value={ownersOptions?.find(
                (option) => option.value === field.value
              )}
              onChange={(selected) => {
                field.onChange(selected.value);
                setIsNewOwner(false);
              }}
              onCreateOption={(value) => {
                if (value) {
                  field.onChange("");
                  setValue("owner_id", "");
                  setValue("owner.name", value);
                  setIsNewOwner(true);
                }
              }}
            />
          )}
        />
        {errors.owner_id && (
          <FormFeedback>{errors.owner_id.message}</FormFeedback>
        )}
      </div>

      <Row
        className={classNames("gy-1 gx-2 flex-column", {
          "d-none": !isNewOwner,
        })}
      >
        <Col xs={12}>
          <Label className="form-label" for="owner.name">
            Owner Name
          </Label>
          <Controller
            name="owner.name"
            control={control}
            rules={{ required: isNewOwner }}
            render={({ field }) => (
              <Input
                id="owner.name"
                placeholder="Owner name"
                invalid={errors?.owner?.name && true}
                {...field}
              />
            )}
          />
          {errors.owner?.name && (
            <FormFeedback>{errors.owner?.name.message}</FormFeedback>
          )}
        </Col>
        <Col xs={12}>
          <Label className="form-label" for="owner.phone">
            Phone
          </Label>
          <Controller
            name="owner.phone"
            control={control}
            rules={{ required: isNewOwner }}
            render={({ field }) => (
              <Input
                id="owner.phone"
                placeholder="+2526"
                invalid={errors?.owner?.phone && true}
                {...field}
              />
            )}
          />
          {errors.owner?.phone && (
            <FormFeedback>{errors.owner?.phone.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.email">
            Email
          </Label>
          <Controller
            name="owner.email"
            control={control}
            rules={{ required: isNewOwner }}
            render={({ field }) => (
              <Input
                id="owner.email"
                placeholder="admin@example.com"
                invalid={errors?.owner?.email && true}
                {...field}
              />
            )}
          />
          {errors.owner?.email && (
            <FormFeedback>{errors.owner?.email.message}</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.emergency_contact">
            Emergency Contact
          </Label>
          <Controller
            name="owner.emergency_contact"
            control={control}
            rules={{ required: isNewOwner }}
            render={({ field }) => (
              <Input
                id="owner.phone"
                placeholder="+2526"
                invalid={errors?.owner?.emergency_contact && true}
                {...field}
              />
            )}
          />
          {errors.owner?.emergency_contact && (
            <FormFeedback>
              {errors.owner?.emergency_contact.message}
            </FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.address">
            Address
          </Label>
          <Controller
            name="owner.address"
            control={control}
            rules={{ required: isNewOwner }}
            render={({ field }) => (
              <Input
                id="owner.address"
                placeholder="Taleex,Hodan Mogadishu Somalia"
                invalid={errors.owner?.address && true}
                {...field}
              />
            )}
          />
          {errors.owner?.address && (
            <FormFeedback>{errors.owner?.address.message}</FormFeedback>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button color="secondary" className="btn-prev" outline disabled>
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>

        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
