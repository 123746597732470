import React, { useState } from "react";
import CustomDataTable from "../../../common/CustomDataTable";
import {
  useDeleteContractTypesMutation,
  useGetContractTypesQuery,
} from "../../../redux/contractTypes";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import ContractTypeModal from "./ContractTypeModal";
import { Edit, Trash } from "react-feather";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const MySwal = withReactContent(Swal);

const ContractTypes = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteContractTypes] = useDeleteContractTypesMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Account ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteContractTypes(id);
        if (responseStatus) {
          toast.success("Type Has been Deleted.");
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Name",
      sortField: "name",
      sortable: true,
      cell: ({ name }) => name,
      selector: (row) => row.name,
    },
    {
      name: "Description",
      sortField: "description",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ maxWidth: "250px" }}>
          {row.description}
        </div>
      ),
    },
    {
      sortable: true,
      name: "User",
      cell: ({ createdBy }) => createdBy?.username,
    },
    {
      sortable: true,
      name: "Created Date",
      selector: (row) => row.createdAt,
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY"),
      sortFunction: (rowA, rowB) =>
        new Date(rowB.createdAt) - new Date(rowA.createdAt),
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span>{row.status}</span>
        </Badge>
      ),
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Contact Types"
        fetchData={useGetContractTypesQuery}
        searchFields={["name", "description"]}
        populate={[
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"Add New Type"}
        addButtonClick={() => setIsModalVisible(true)}
      />
      <ContractTypeModal
        selectedRow={selectedRow}
        show={isModalVisible}
        setShow={setIsModalVisible}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default ContractTypes;
