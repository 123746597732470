import React from "react";
import { useContext } from "react";
import { Fragment } from "react";
import { AbilityContext } from "./context/Can";
import { subject } from "@casl/ability";
import { CurrentBuildingContext } from "../contexts/currentBuilding";

const Can2 = ({ I, a, y, children }) => {
  const ability = useContext(AbilityContext);
  const { currentBuilding } = useContext(CurrentBuildingContext);

  if (
    !y &&
    currentBuilding &&
    !ability.can(I, subject(a, { view: currentBuilding._id }))
  )
    return;

  if (y && !ability.can(I, subject(a, { view: y }))) return;

  return <Fragment>{children}</Fragment>;
};

export default Can2;
