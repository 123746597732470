// ** React Imports
import { useState, Fragment } from "react";

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// ** Third Party Imports
import { useDropzone } from "react-dropzone";
import {
  FileText,
  X,
  DownloadCloud,
  Plus,
  Trash2,
  Upload,
} from "react-feather";

import "@styles/react/pages/page-profile.scss";
import { Trash2Fill, Trash3 } from "react-bootstrap-icons";

const MultipleImageUpload = ({ files, setFiles }) => {
  // ** State

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles.map((file) => Object.assign(file))]);
    },
  });

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          className="rounded img-fluid"
          alt={file.name}
          src={URL.createObjectURL(file)}
          // height="28"
          // width="28"
        />
      );
    } else {
      return <FileText size="28" />;
    }
  };

  const handleRemoveFile = (file) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const renderFileSize = (size) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
    }
  };

  const fileList = files.map((file, index) =>
    true ? (
      <div
        key={`${file.name}-${index}`}
        className="position-relative ms-1 mt-1"
      >
        <div className="media-img">{renderFilePreview(file)}</div>
        <div className="position-absolute top-0 end-0 mt-1 me-1">
          <Button
            color="danger"
            size="sm"
            className="btn-icon"
            onClick={() => handleRemoveFile(file)}
          >
            <Trash2 size={12} />
          </Button>
        </div>
      </div>
    ) : (
      <ListGroupItem
        key={`${file.name}-${index}`}
        className="d-flex align-items-center justify-content-between"
      >
        <div className="file-details d-flex align-items-center">
          <div className="file-preview me-1">{renderFilePreview(file)}</div>
          <div>
            <p className="file-name mb-0">{file.name}</p>
            <p className="file-size mb-0">{renderFileSize(file.size)}</p>
          </div>
        </div>
        <Button
          color="danger"
          outline
          size="sm"
          className="btn-icon mb-2"
          onClick={() => handleRemoveFile(file)}
        >
          <Trash2Fill size={14} />
        </Button>
      </ListGroupItem>
    )
  );

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <div>
      {files.length ? (
        <Fragment>
          <div className="my-2 d-flex gx-5 flex-wrap">{fileList}</div>
          {/* <ListGroup className='my-2 d-flex'>{fileList}</ListGroup> */}
          <div className="d-flex justify-content-start ms-1">
            <Button
              className="mb-2"
              color="danger"
              outline
              onClick={handleRemoveAllFiles}
            >
              <Trash3 size={15} className="me-1" />
              Remove All
            </Button>
            <Button
              {...getRootProps({ className: "dropzone" })}
              className="display-inline mb-2 ms-2"
              color="primary"
            >
              <input {...getInputProps()} />
              <Upload size={15} className="me-1" />
              Add New Image
            </Button>
          </div>
        </Fragment>
      ) : null}

      {files.length == 0 && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="d-flex align-items-center justify-content-center flex-column">
            <DownloadCloud size={64} />
            <h5>Drop Files here or click to upload</h5>
            <p className="text-secondary">
              Drop files here or click
              <a href="/" onClick={(e) => e.preventDefault()}>
                browse
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleImageUpload;
