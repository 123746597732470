import { useState } from "react";
import DataTable from "react-data-table-component";
import { ChevronDown, User } from "react-feather";
import defaultImg from "@src/assets/images/logo/logo.png";
import themeConfig from "../../../../configs/themeConfig";
import ReactPaginate from "react-paginate";
import TableLoading from "../../../../@core/components/base/TableLoading";
import { Badge, Card, CardTitle, CardHeader, CardBody } from "reactstrap";
import { Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const TenantsTab = ({ property }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tenants, setTenants] = useState(property?.tenants);
  const navigate = useNavigate();

  const columns = [
    {
      name: "Customer",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            navigate(`/customers/${row._id}?view=company`);
            // dispatch(selectCustomer(row));
          }}
        >
          <div className="avatar rounded">
            <div className="avatar-content">
              <User />
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.name}</div>
            <div className="font-small-2 text-muted">{row.username}</div>
          </div>
        </div>
      ),
    },

    {
      name: "Phone",
      sortable: true,
      sortField: "price",
      selector: (row) => row.building?.name ?? "",
      cell: (row) => <div className="">{row?.phone ?? 0}</div>,
    },
    {
      name: "Email",
      sortable: true,
      sortField: "price",
      selector: (row) => row?.status ?? "",
      cell: (row) => <div className="">{row?.email ?? ""}</div>,
    },
    {
      name: "Status",
      sortable: true,
      sortField: "price",
      selector: (row) => row?.status ?? "",
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
  ];

  const handlePagination = (page) => {
    console.log(page);
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentPageData = property.tenants.slice(startIndex, endIndex);

    setTenants(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(tenants / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const dataToRender = () => {
    return tenants;
  };

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">
          <CardTitle> Tenants List</CardTitle>
          {/* <Button color="primary">New Agreement</Button> */}
        </CardHeader>
        <CardBody>
          <div className="react-dataTable user-view-account-projects">
            {property?.tenants?.length === 0 ? (
              <h5>No Data</h5>
            ) : (
              <DataTable
                sortServer
                pagination
                responsive
                paginationServer
                striped
                columns={columns}
                // onSort={handleSort}
                sortIcon={<ChevronDown />}
                className="react-dataTable react-dataTable-selectable-rows"
                paginationComponent={CustomPagination}
                data={dataToRender()}
                progressPending={!property?.tenants?.length}
                progressComponent={<TableLoading rows={1} cols={7} />}
                // selectableRowsComponent={Boos}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TenantsTab;
