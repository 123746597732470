// ** Imports createContext function
import { createContext } from "react";
import { AbilityBuilder } from "@casl/ability";
// ** Imports createContextualCan function
import { createContextualCan } from "@casl/react";

// ** Create Context
export const AbilityContext = createContext();

export const updateAbility = (userAbility) => {
  const { rules, can } = new AbilityBuilder();

  userAbility.forEach((ability) => {
    can(ability.action, ability.subject, ability.condition);
  });

  return rules;
};

export const ability = (userAbility) => {
  const { can, build } = new AbilityBuilder();

  const abilities = build();
  const _ability = new Ability(abilities);

  // userAbility.forEach((ability) => {
  //   _ability.can(ability.action, ability.subject, ability.condition);
  // });

  return _ability;
};

//* ==============

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer);
