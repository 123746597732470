import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CreatableSelect from "react-select";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as yup from "yup";

import SingleFileUploader from "@commons/utils/components/SingleFileUploader";
import "@styles/react/libs/editor/editor.scss";
import axios from "axios";
import { toast } from "react-hot-toast";
import { ErrorHandler, ExtractError } from "../../../common/utils/Error";
import { useGetDepartmentsListQuery } from "../../../redux/departments";
import { useGetJobsListQuery } from "../../../redux/jobs";
import { useGetShiftsListQuery } from "../../../redux/shifts";
import {
  useCreateStaffMutation,
  useGetSingleStaffQuery,
  useUpdateStaffMutation,
} from "../../../redux/staff";
import MultipleFileUpload from "./components/MultipleFileUpload";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  placeOfBirth: yup.string().required("Place of Birth is required"),
  dateOfBirth: yup.string().required("Date of Birth is required"),
  contacts: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  motherName: yup.string().required("Mother Name is required"),
  emergencyContactName: yup
    .string()
    .required("Emergency Contact Name is required"),
  emergencyContact: yup.string().required("Emergency Phone is required"),
  job: yup.string().required("Job is required"),
  shift: yup.string().required("Shift is required"),
  department: yup.string().required("Department is required"),
  hireDate: yup.date().required("Hire Date is required"),
  status: yup.string().required("Status is required"),
  role: yup.string().required("Role is required"),
  username: yup.string().required("Username is required"),
  password: yup.string().when("staffId", {
    is: (staffId) => !staffId,
    then: yup.string().required("Password is required"),
    otherwise: yup.string(),
  }),
});

const initialValues = {
  name: "",
  email: "",
  job: "",
  shift: "",
  department: "",
  role: "",
  dateOfBirth: "",
  address: "",
  contacts: "",
  emergencyContactName: "",
  emergencyContact: "",
  motherName: "",
  placeOfBirth: "",
  hireDate: moment().format("YYYY-MM-DD"),
  username: "",
  password: "",
  status: "",
};

export default function AgreementForm() {
  //* Data Fetching
  const { data: shifts } = useGetShiftsListQuery();
  const { data: jobs } = useGetJobsListQuery();
  const { data: departments } = useGetDepartmentsListQuery();

  const jobsOptions = jobs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const shiftsOptions = shifts?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const departmentsOptions = departments?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  // ** RTK Query Mutations
  const [createStaff, { isLoading: isCreating }] = useCreateStaffMutation();
  const [updateStaff, { isLoading: isUpdating }] = useUpdateStaffMutation();

  //* get parames
  const [params] = useSearchParams();
  const staffId = params.get("staff");

  const { id } = useParams();

  //* get selected staff

  const queryObject = {
    populate: [
      {
        path: "user",
        dir: "users",
      },
    ],
  };
  const {
    data: selectedStaff,
    error,
    isLoading,
  } = useGetSingleStaffQuery(
    { id: id, params: queryObject },
    {
      skip: !id, // Skip the query if staffId doesn't exist
    }
  );

  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [image, setImage] = useState([]);
  const [roles, setRoles] = useState([]);

  const getAllRoles = async () => {
    try {
      const { data, status } = await axios.get("/roles", {
        params: {
          options: { select: "name" },
        },
      });

      if (status === 200) {
        const rolesList = data.data.docs.map((role) => ({
          label: role.name,
          value: role._id,
        }));
        setRoles(rolesList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const {
    reset,
    control,
    register,
    clearErrors,
    getValues,
    setValue,
    setFocus,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    try {
      values.user = {};
      values.user.role = values?.role;
      values.user.username = values?.username;
      if (values?.password) values.user.password = values?.password;

      const formData = new FormData();

      for (const key in values) {
        if (values[key]) {
          formData.append(key, values[key]);
        }
      }

      formData.set("user", values.user._id);

      documents.forEach((file) => {
        formData.append("documents", file, file.name);
      });

      if (image.length) {
        formData.append("image", image[0], image[0].name);
      }

      if (staffId) {
        if (!values.photo || values.photo === "no-image") {
          formData.delete("photo");
        }

        if (values.documents.length === 0) {
          formData.delete("documents");
        }

        if (Object.keys(values).length === 0) {
          toast.error("Please Update Data");
          return;
        }

        const { data, error } = await updateStaff({
          id: staffId,
          data: formData,
        });

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }

        toast.success("Staff Updated Successfully");
        navigate("/hrm/staff");
      } else {
        const { data, error } = await createStaff(formData);

        if (error) {
          const errorMessage = ExtractError(error?.data);
          toast.error(errorMessage);
          return;
        }

        reset();
        setImage([]);
        setDocuments([]);
        toast.success("New Staff Created Successfully");
        navigate("/hrm/staff");
      }
    } catch (error) {
      toast.error(ExtractError(error));
    }
  };

  useEffect(() => {
    if (id) {
      console.log(selectedStaff);
      reset({
        ...selectedStaff,
        role: selectedStaff?.user?.role,
        username: selectedStaff?.user?.username,
      });
    }
  }, [selectedStaff, isLoading]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb listClassName="breadcrumb-dashes">
        <BreadcrumbItem>
          <Link to="/"> Home </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/hrm/staff"> Staffs </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <Link to="/hrm/staff/create"> Create </Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <CardHeader>
          <CardTitle>Staffs Form</CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row className="gy-2">
              <Col sm={12} md={4}>
                <Label className="form-label" for="name">
                  Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Name"
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="email"
                      placeholder="Email"
                      invalid={errors.email && true}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <FormFeedback>{errors.email.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="placeOfBirth">
                  Place Of Birth
                </Label>
                <Controller
                  name="placeOfBirth"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="placeOfBirth"
                      placeholder="Place Of Birth"
                      invalid={errors.placeOfBirth && true}
                      {...field}
                    />
                  )}
                />
                {errors.placeOfBirth && (
                  <FormFeedback>{errors.placeOfBirth.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="dateOfBirth">
                  Date Of Birth
                </Label>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="dateOfBirth"
                      type="date"
                      placeholder="Date Of Birth"
                      invalid={errors.dateOfBirth && true}
                      {...field}
                    />
                  )}
                />
                {errors.dateOfBirth && (
                  <FormFeedback>{errors.dateOfBirth.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="contacts">
                  Phone
                </Label>
                <Controller
                  name="contacts"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="contacts"
                      type="text"
                      placeholder="Phone"
                      invalid={errors.contacts && true}
                      {...field}
                    />
                  )}
                />
                {errors.contacts && (
                  <FormFeedback>{errors.contacts.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="address">
                  Address
                </Label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="address"
                      type="text"
                      placeholder="Address"
                      invalid={errors.address && true}
                      {...field}
                    />
                  )}
                />
                {errors.address && (
                  <FormFeedback>{errors.address.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="motherName">
                  Mother Name
                </Label>
                <Controller
                  name="motherName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="motherName"
                      placeholder="Mother Name"
                      invalid={errors.motherName && true}
                      {...field}
                    />
                  )}
                />
                {errors.motherName && (
                  <FormFeedback>{errors.motherName.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="emergencyContactName">
                  Emergency Name
                </Label>
                <Controller
                  name="emergencyContactName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="emergencyContactName"
                      type="text"
                      placeholder="Emergency Name"
                      invalid={errors.emergencyContactName && true}
                      {...field}
                    />
                  )}
                />
                {errors.emergencyContactName && (
                  <FormFeedback>
                    {errors.emergencyContactName.message}
                  </FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="emergencyContact">
                  Emergency Phone
                </Label>
                <Controller
                  name="emergencyContact"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="emergencyContact"
                      type="text"
                      placeholder="Emergency Phone"
                      invalid={errors.emergencyContact && true}
                      {...field}
                    />
                  )}
                />
                {errors.emergencyContact && (
                  <FormFeedback>{errors.emergencyContact.message}</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="job">
                  Select Job
                </Label>
                <Controller
                  name="job"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="job"
                      className={`react-select ${
                        errors.job ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      aria-invalid={errors.job && true}
                      placeholder="Choose Job"
                      options={jobsOptions}
                      {...field}
                      value={jobsOptions?.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
                {errors.job && (
                  <FormFeedback>{errors.job.message}</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="shift">
                  Select Shift
                </Label>
                <Controller
                  name="shift"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="shift"
                      className={`react-select ${
                        errors.shift ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      aria-invalid={errors.shift && true}
                      placeholder="Choose shift"
                      options={shiftsOptions}
                      {...field}
                      value={shiftsOptions?.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
                {errors.shift && (
                  <FormFeedback>{errors.shift.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="department">
                  Select Department
                </Label>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="department"
                      className={`react-select ${
                        errors.department ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      aria-invalid={errors.department && true}
                      placeholder="Choose department"
                      options={departmentsOptions}
                      {...field}
                      value={departmentsOptions?.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
                {errors.department && (
                  <FormFeedback>{errors.department.message}</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="hireDate">
                  Hire Date
                </Label>
                <Controller
                  name="hireDate"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="hireDate"
                      type="date"
                      placeholder="Hire Date"
                      invalid={errors.hireDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.hireDate && (
                  <FormFeedback>{errors.hireDate.message}</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="status">
                  Select Status
                </Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="status"
                      className={`react-select ${
                        errors.status ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      aria-invalid={errors.status && true}
                      placeholder="Choose status"
                      options={statusOptions}
                      {...field}
                      value={statusOptions?.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
                {errors.status && (
                  <FormFeedback>{errors.status.message}</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="role">
                  Select Role
                </Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="role"
                      className={`react-select ${
                        errors.role ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      aria-invalid={errors.role && true}
                      placeholder="Choose Role"
                      options={roles}
                      {...field}
                      value={roles?.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
                {errors.role && (
                  <FormFeedback>{errors.role.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="username">
                  User Name
                </Label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="username"
                      placeholder="User Name"
                      invalid={errors.username && true}
                      {...field}
                    />
                  )}
                />
                {errors.username && (
                  <FormFeedback>{errors.username.message}</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                {!staffId && (
                  <>
                    <Label className="form-label" for="password">
                      Password
                    </Label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="password"
                          type="password"
                          placeholder="Password"
                          invalid={errors.password && true}
                          {...field}
                        />
                      )}
                    />
                    {!staffId && errors.password && (
                      <FormFeedback>{errors.password.message}</FormFeedback>
                    )}
                  </>
                )}
              </Col>

              <Col sm={12} md={6}>
                <Label className="form-label">Staff Photo</Label>
                <SingleFileUploader
                  title={"Staff Photo"}
                  files={image}
                  setFiles={setImage}
                />
              </Col>
              <Col sm={12} md={6}>
                <Label className="form-label">Documents</Label>
                <MultipleFileUpload
                  files={documents}
                  setFiles={setDocuments}
                  fileTypes={{ "application/pdf": [".pdf"] }}
                />
              </Col>
            </Row>

            <div className="text-center mt-3 mb-1">
              <Button
                color="success"
                type="submit"
                disabled={isUpdating || isCreating}
              >
                <Spinner
                  color="light"
                  size="sm"
                  className="me-1"
                  hidden={!(isUpdating || isCreating)}
                />
                {isUpdating || isCreating
                  ? "Submitting..."
                  : staffId
                  ? "Save Changes"
                  : "Submit"}
              </Button>
              <Button
                color="secondary"
                outline
                className="ms-1"
                type="reset"
                onClick={() => navigate("/hrm/staff")}
                disabled={isUpdating || isCreating}
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </form>
      </Card>
    </div>
  );
}
