import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { createQueryString, getToken } from "../utility/helper";

export const deductionsApi = createApi({
  reducerPath: "deductions",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["deductions"],
  endpoints: (builder) => ({
    // Read Deductions
    getEmployeeDeductions: builder.query({
      query: () => `/hrm/deductions/list`,
      providesTags: ["deductions"],
      transformErrorResponse: (response) => {
        return response?.data;
      },
      providesTags: ["deductions"],
    }),

    // save
    saveEmployeeDeductions: builder.mutation({
      query: (data) => ({
        url: "/hrm/deductions",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deductions"],
    }),
  }),
});

export const {
  useGetEmployeeDeductionsQuery,
  useSaveEmployeeDeductionsMutation,
} = deductionsApi;
