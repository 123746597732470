// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink";
import VerticalNavMenuGroup from "./VerticalNavMenuGroup";
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader";

// ** Utils
import {
  canViewMenuItem,
  canViewMenuGroup,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
} from "@layouts/utils";
import { useSearchParams } from "react-router-dom";

const VerticalMenuNavItems = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader,
  };
  // appView={searchParams.get("view")}

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)];
    if (item.children) {
      return (
        canViewMenuGroup(item) && (
          <TagName
            item={item}
            index={index}
            appView={searchParams.get("view")}
            key={item.id}
            {...props}
          />
        )
      );
    }
    return (
      canViewMenuItem(item, props.items, searchParams.get("view")) && (
        <TagName
          key={item.id || item.header}
          appView={searchParams.get("view")}
          item={item}
          {...props}
        />
      )
    );
  });

  return RenderNavItems;
};

export default VerticalMenuNavItems;
