import React from "react";
import { Badge, Button, Input, Label, UncontrolledTooltip } from "reactstrap";
// ** Icons Imports
import AvatarGroup from "@components/avatar-group";
import { useState } from "react";
import { Check, X } from "react-bootstrap-icons";
import { Edit, Eye, LogIn } from "react-feather";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ErrorHandler, ExtractError } from "../../common/utils/Error";
import themeConfig from "../../configs/themeConfig";
import {
  useDeleteBuildingsMutation,
  useGetBuildingsQuery,
  useUpdatePropertyStatusMutation,
} from "../../redux/building";

import { useContext } from "react";
import CustomDataTable from "../../common/CustomDataTable";
import { CurrentBuildingContext } from "../../contexts/currentBuilding";
import { loginBuilding } from "../../redux/home";
import Can2 from "../../utility/Can";
import { AbilityContext, Can } from "../../utility/context/Can";
import { getFirstAvailableRoute } from "../../utility/Utils";

const MySwal = withReactContent(Swal);

const CustomLabel = ({ htmlFor }) => {
  return (
    <Label className="form-check-label" htmlFor={htmlFor}>
      <span className="switch-icon-left">
        <Check size={14} />
      </span>
      <span className="switch-icon-right">
        <X size={14} />
      </span>
    </Label>
  );
};

export default function BuildingList() {
  //* RTk query
  const [updateStatusProperty, { isLoading: statusLoading }] =
    useUpdatePropertyStatusMutation();
  const [deleteBuilding] = useDeleteBuildingsMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState("list");
  const ability = useContext(AbilityContext);
  const { logInBuilding } = useContext(CurrentBuildingContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");
  const status = searchParams.get("status");

  const handleFeatured = async (id, checked) => {
    try {
      const { data, error } = await updateStatusProperty({
        id,
        type: "featured",
        status: checked,
      });

      if (error) {
        const errorMessage = ExtractError(error?.data);
        toast.error(errorMessage);
        return;
      }

      toast.success(
        checked
          ? "Property Added to Featured List"
          : "Property Removed From Featured List"
      );
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const handlePublished = async (id, checked) => {
    try {
      const { data, error } = await updateStatusProperty({
        id,
        type: "published",
        status: checked,
      });

      if (error) {
        const errorMessage = ExtractError(error?.data);
        toast.error(errorMessage);
        return;
      }

      toast.success(checked ? "Property Published" : "Property Unpublished");
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const loginIntoBuilding = (buildings) => {
    logInBuilding(buildings);
    dispatch(loginBuilding(buildings));
    navigate(getFirstAvailableRoute(ability, buildings?._id, "building"));
  };

  const handleConfirmDelete = (id, name) => {
    MySwal.fire({
      title: `Delete Building ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-danger me-1",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data, error } = await deleteBuilding(id);

          if (error) {
            const errorMessage = ExtractError(error?.data);
            toast.error(errorMessage);
            return;
          }

          toast.success("Owner Has been Deleted.");
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message ??
            error?.response?.data ??
            error.message ??
            "Something went wrong, please try again";

          const hasUnits = error?.response?.data?.hasUnits ?? false;

          if (hasUnits) {
            Swal.showValidationMessage(errorMessage);
          } else {
            Swal.showValidationMessage(errorMessage);
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const columns = [
    {
      name: "Property",
      sortable: true,
      sortField: "name",
      width: "15%",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex align-items-start text-truncate">
          {row.thumbnail ? (
            <img
              src={themeConfig.serverUrl + "uploads/" + row.thumbnail}
              className="img-fluid featured-img"
            />
          ) : null}

          <Link
            to={`/properties/${row._id}?view=${appView}`}
            className="text-body text-truncate"
          >
            <span className="fw-bolder">{row.name}</span>
          </Link>
        </div>
      ),
    },
    {
      name: "Units",
      sortable: true,
      sortField: "units",
      selector: (row) => row.units,
      cell: (row) => <span>{row.units?.length ?? 0}</span>,
    },
    {
      name: "Deposit",
      sortable: true,
      sortField: "deposit",
      selector: (row) => row.deposit,
      cell: (row) => <span>{row.deposit}</span>,
    },
    {
      name: "Advance",
      sortable: true,
      sortField: "advance",
      selector: (row) => row.advance,
      cell: (row) => <span>{row.advance}</span>,
    },
    {
      name: "Floors",
      sortable: true,
      sortField: "floors",
      selector: (row) => row.floors,
      cell: (row) => <span>{row.floors}</span>,
    },

    {
      name: "Photos",
      sortable: true,
      sortField: "photos",
      wrap: true,
      selector: (row) => row.photos,
      cell: (row) => {
        const images = (row.photos || []).slice(0, 3).map((img) => {
          return { img: `${themeConfig.serverUrl}uploads/${img}` };
        });

        return <AvatarGroup data={images} />;
      },
    },
    {
      name: "Published",
      sortable: true,
      sortField: "published",
      selector: (row) => row.published,
      cell: (row) => (
        <>
          <div className="form-switch form-check-success">
            <Input
              type="switch"
              checked={row.published}
              disabled={statusLoading}
              id={`published_${row._id}`}
              name={`published_${row._id}`}
              onChange={(event) => {
                handlePublished(row._id, event.target.checked);
              }}
            />
            <CustomLabel htmlFor={`published_${row._id}`} />
          </div>
        </>
      ),
    },

    {
      name: "Featured",
      sortable: true,
      sortField: "featured",
      selector: (row) => row.featured,
      cell: (row) => (
        <>
          <div className="form-switch form-check-success">
            <Input
              type="switch"
              checked={row.featured}
              disabled={statusLoading}
              id={`featured_${row._id}`}
              name={`featured_${row._id}`}
              onChange={(event) => {
                handleFeatured(row._id, event.target.checked);
              }}
            />
            <CustomLabel htmlFor={`featured_${row._id}`} />
          </div>
        </>
      ),
    },
    {
      name: "Status",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <Badge
            color={
              row.status == "Available"
                ? "light-success"
                : row.status == "Booked"
                ? "light-warning"
                : "light-danger"
            }
          >
            {row.status}
          </Badge>
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex  ">
          <Can I="read" a={row._id}>
            <Button
              id="select-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => {
                if (ability.can("read", row?._id)) {
                  loginIntoBuilding(row);
                }
              }}
            >
              <LogIn className="font-medium-2" />
              <UncontrolledTooltip placement="top" target="select-btn">
                Select
              </UncontrolledTooltip>
            </Button>
          </Can>

          <Can2 I="update" a="Building" y={appView}>
            <Button
              id="update-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() =>
                navigate(`/properties/update/${row?._id}?view=company`)
              }
            >
              <Edit className="font-medium-2" color="green" />
              <UncontrolledTooltip placement="top" target="update-btn">
                Edit
              </UncontrolledTooltip>
            </Button>
          </Can2>

          <Can2 I="delete" a="Building" y={appView}>
            <Button
              id="delete-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={(e) => {
                e.preventDefault();
                // handleConfirmDelete(row._id, row.name);
                navigate(`/properties/${row._id}?view=${appView}`);
              }}
            >
              <Eye className="font-medium-2" color="green" />
              <UncontrolledTooltip placement="top" target="delete-btn">
                View
              </UncontrolledTooltip>
            </Button>
          </Can2>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Properties"
        buttonLabel={"New Property"}
        addButtonClick={() => navigate("/properties/create?view=company")}
        fetchData={useGetBuildingsQuery}
        query={{
          propertyStatus:
            appView === "salesAndBroker"
              ? ["For Sale", "Broker Rent"]
              : ["For Rent"],
          status: status || undefined,
        }}
        searchFields={["name"]}
        populate={[
          {
            path: "category",
            dir: "categories",
            select: "name",
          },
        ]}
      />
    </div>
  );
}
