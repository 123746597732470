import { useState, useEffect } from "react";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  const getGeolocation = () => {
    if (!navigator.geolocation) {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by your browser",
      });
      return;
    }

    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude, error: null });
    };

    const handleError = (error) => {
      setLocation({ latitude: null, longitude: null, error: error.message });
    };

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  };

  return { location, getGeolocation };
};

export default useGeolocation;
