const DividerWithText = ({
  // Default text
  component = "h6", // Default text size
  color = "bg-primary", // Default border color
  borderHeight = "2px", // Default border height

  children,
}) => {
  const TextTag = component;

  return (
    <div className="my-2">
      <TextTag>{children}</TextTag>
      <div className={`w-100 ${color}`} style={{ height: borderHeight }}></div>
    </div>
  );
};

export default DividerWithText;
