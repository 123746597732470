import { HyperFormula } from "hyperformula";
import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";
import { registerAllModules } from "handsontable/registry";
import moment from "moment";
import { useSavePayrollDetailsMutation } from "../../../../redux/payroll";
import { ExtractError } from "../../../../common/utils/Error";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

registerAllModules();

const schema = yup.object().shape({
  payroll: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Employee name is required"),
        salaryAllowances: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Allowance name is required"),
            amount: yup
              .number()
              .required("Allowance amount is required")
              .min(0, "Amount must be positive"),
          })
        ),
        deductions: yup.array().of(
          yup.object().shape({
            description: yup
              .string()
              .required("Deduction description is required"),
            amount: yup
              .number()
              .required("Deduction amount is required")
              .min(0, "Amount must be positive"),
          })
        ),
      })
    )
    .required(),
});

const spreadsheetColumnLabel = (index) => {
  let columnLabel = "";
  let tempIndex = index;

  while (tempIndex > 0) {
    const modulo = (tempIndex - 1) % 26;
    columnLabel = String.fromCharCode(65 + modulo) + columnLabel;
    tempIndex = Math.floor((tempIndex - 1) / 26);
  }

  return columnLabel;
};

const PayrollTable = ({ data }) => {
  const navigate = useNavigate();

  const { handleSubmit, reset } = useForm({
    defaultValues: { payroll: data },
    resolver: yupResolver(schema),
  });

  const hotTableRef = useRef(null);

  const [savePayrollDetails, { isLoading: isSaving }] =
    useSavePayrollDetailsMutation();

  const onSubmit = async (formData) => {
    try {
      console.log("Submitted Data:", formData);
      const { data, error } = await savePayrollDetails(formData);

      if (error) {
        const errorMessage = ExtractError(error?.data);
        toast.error(errorMessage);
        return;
      }

      toast.success("Saved Successfully");
    } catch (error) {
      const errorMessage = ExtractError(error);
      toast.error(errorMessage);
    }
  };

  const onCancel = () => {
    reset({ payroll: data });
    const hot = hotTableRef.current.hotInstance;
    hot.loadData(transformDataToHot(data));

    navigate("/hrm/payroll");
  };

  const extractHeaders = (key, subKey) => {
    return Array.from(
      new Set(
        data?.flatMap((employee) => employee[key].map((item) => item[subKey]))
      )
    );
  };

  const salaryHeaders = extractHeaders("salaryAllowances", "name");
  const deductionHeaders = extractHeaders("deductions", "description");

  const transformDataToHot = (data) => {
    return data.map((employee, rowIndex) => {
      const paymentDate = employee.paymentDate || moment().format("YYYY-MM-DD");

      const salaryAmounts = salaryHeaders.map(
        (header) =>
          employee.salaryAllowances.find((sa) => sa.name === header)?.amount ||
          ""
      );

      const deductionAmounts = deductionHeaders.map(
        (header) =>
          employee.deductions.find((d) => d.description === header)?.amount ||
          ""
      );

      const startSalaryColumn = 3; // Payment Date, Employee Name, SalaryAllowance start at index 3
      const endSalaryColumn = startSalaryColumn + salaryHeaders.length - 1;

      const startDeductionColumn = endSalaryColumn + 2; // After Gross Pay column
      const endDeductionColumn =
        startDeductionColumn + deductionHeaders.length - 1;

      // calculate gross pay cell dynamically
      const grossPayCell = `=SUM(${spreadsheetColumnLabel(startSalaryColumn)}${
        rowIndex + 1
      }:${spreadsheetColumnLabel(endSalaryColumn)}${rowIndex + 1})`;

      // calculate total deduction cell dynamically
      const totalDeductionCell = `=SUM(${spreadsheetColumnLabel(
        startDeductionColumn
      )}${rowIndex + 1}:${spreadsheetColumnLabel(endDeductionColumn)}${
        rowIndex + 1
      })`;

      // calculate net pay cell
      const netPayCell = `=${spreadsheetColumnLabel(endSalaryColumn + 1)}${
        rowIndex + 1
      }-${spreadsheetColumnLabel(endDeductionColumn + 1)}${rowIndex + 1}`;

      return [
        paymentDate,
        employee.name,
        ...salaryAmounts,
        grossPayCell,
        ...deductionAmounts,
        totalDeductionCell,
        netPayCell,
      ];
    });
  };

  const transformHotToData = (hotData) => {
    if (!Array.isArray(hotData)) {
      return [];
    }
    return hotData.map((row, rowIndex) => {
      const employee = {
        payroll: data[rowIndex]?.payroll,
        staff: data[rowIndex]?._id,
        paymentDate: row[0], // Payment Date column
        name: row[1], // Employee Name column
        salaryAllowances: salaryHeaders.map((header, index) => ({
          name: header,
          amount: row[2 + index] || 0, // Adjusted for Payment Date and Name
        })),
        deductions: deductionHeaders.map((header, index) => ({
          description: header,
          amount: row[2 + salaryHeaders.length + 1 + index] || 0, // Adjusted for Salary Allowances and additional columns
        })),
        grossPay: row[2 + salaryHeaders.length] || 0, // Gross Pay column
        totalDeductions:
          row[2 + salaryHeaders.length + deductionHeaders.length + 1] || 0, // Total Deductions column
        netPay:
          row[2 + salaryHeaders.length + deductionHeaders.length + 2] || 0, // Net Pay column
      };
      return employee;
    });
  };

  useEffect(() => {
    reset({ payroll: data });
    const hot = hotTableRef.current.hotInstance;
    hot.loadData(transformDataToHot(data));
  }, [data, reset]);

  return (
    <Card className="pb-2">
      <CardBody>
        <form
          onSubmit={handleSubmit(() =>
            onSubmit(
              transformHotToData(hotTableRef.current.hotInstance.getData())
            )
          )}
        >
          <CardHeader className="d-flex justify-content-end gap-1">
            <Button
              outline
              color="danger"
              type="button"
              onClick={onCancel}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSaving}>
              {isSaving ? "Saving..." : "Save"}
            </Button>

            <Button color="warning" disabled={isSaving}>
              Close Payroll
            </Button>
          </CardHeader>
          <HotTable
            ref={hotTableRef}
            data={transformDataToHot(data)}
            formulas={{
              engine: HyperFormula,
            }}
            colHeaders={[
              "Payment Date",
              "Employee Name",
              ...salaryHeaders,
              "Gross Pay",
              ...deductionHeaders,
              "Total Deduction",
              "Net Pay",
            ]}
            nestedHeaders={[
              [
                { label: "Payment Date", rowspan: 2 },
                { label: "Employee Name", rowspan: 2 },
                {
                  label: "Salary and Allowances",
                  colspan: salaryHeaders.length + 1,
                },
                { label: "Deductions", colspan: deductionHeaders.length + 1 },
                { label: "Net Pay", rowspan: 2 },
              ],
              [
                "",
                "",
                ...salaryHeaders,
                "Gross Pay",
                ...deductionHeaders,
                "Total Deduction",
                "",
              ],
            ]}
            rowHeaders={true}
            width="100%"
            height="80vh"
            search={true}
            columnSorting={true}
            filters={true}
            dropdownMenu={true}
            contextMenu={true}
            copyPaste={true}
            stretchH="all"
            licenseKey="non-commercial-and-evaluation"
            style={{ width: "100%", height: "100vh" }}
          />
        </form>
      </CardBody>
    </Card>
  );
};

export default PayrollTable;
