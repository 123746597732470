import { useParams } from "react-router-dom";
import { useGetPayrollDetailsQuery } from "../../../../redux/payroll";
import { Spinner } from "reactstrap";
import PayrollTable from "./PayrollTable";

const PayrollView = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useGetPayrollDetailsQuery(id, {
    skip: !id, // Skip the query if staffId doesn't exist
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return <PayrollTable payroll={id} data={data} />;
};

export default PayrollView;
