// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// ** Icons Imports
import { User, Lock, Bookmark, Bell, Link } from "react-feather";

// ** User Components

import StaffDetail from "./StaffDetail";
import Security from "./Security";
import { UiChecks } from "react-bootstrap-icons";
import Roles from "./Roles";
// import Invoice from "./Invoices";
// import Receipt from "./Receipts";
// import Agreements from "./Agreements";

const UserTabs = ({ active, toggleTab, data }) => {
  return (
    <Fragment>
      <Nav pills className="mb-2">
        <NavItem>
          <NavLink active={active === "1"} onClick={() => toggleTab("1")}>
            <User className="font-medium-3 me-50" />
            <span className="fw-bold">Profile</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "2"} onClick={() => toggleTab("2")}>
            <Lock className="font-medium-3 me-50" />
            <span className="fw-bold">Security</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "3"} onClick={() => toggleTab("3")}>
            <UiChecks className="font-medium-3 me-50" />
            <span className="fw-bold">Roles</span>
          </NavLink>
        </NavItem>
        {/*<NavItem>
          <NavLink active={active === "4"} onClick={() => toggleTab("4")}>
            <Bell className="font-medium-3 me-50" />
            <span className="fw-bold">Agreements</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "5"} onClick={() => toggleTab("5")}>
            <Link className="font-medium-3 me-50" />
            <span className="fw-bold">Statement</span>
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={active}>
        <TabPane tabId="1">
          <StaffDetail data={data} />
        </TabPane>
        <TabPane tabId="2">
          <Security data={data} />
        </TabPane>
        <TabPane tabId="3">
          <Roles />
        </TabPane>
        <TabPane tabId="4">{/* <Agreements store={store} /> */}</TabPane>
        <TabPane tabId="5">{/* <Connections /> */}</TabPane>
      </TabContent>
    </Fragment>
  );
};
export default UserTabs;
