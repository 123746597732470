import moment from "moment";
import React, { useState } from "react";
import { Edit, Eye, Trash } from "react-feather";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomDataTable from "../../../common/CustomDataTable";
import {
  useDeleteContractMutation,
  useGetContractsQuery,
} from "../../../redux/contracts";

const MySwal = withReactContent(Swal);

const Contracts = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const [deleteContract] = useDeleteContractMutation();

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Sure to delete contract?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteContract(id);
        if (responseStatus) {
          toast.success("Type Has been Deleted.");
        }
      }
    });
  };

  const columns = [
    {
      name: "Contract Type",
      sortField: "contractType",
      sortable: true,
      cell: ({ contractType }) => contractType?.name,
      selector: (row) => row.contractType?.name,
    },
    {
      name: "Staff",
      sortable: true,
      sortField: "staff",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <Link
          className="d-flex align-items-center cursor-pointer text-truncate"
          to={
            `/hrm/staff/${row.staff?._id}` + (appView ? "?view=" + appView : "")
          }
        >
          <p className="fw-bolder text-truncate">{row.staff?.name}</p>
        </Link>
      ),
    },
    {
      name: "Effective Date",
      sortable: true,
      selector: (row) => row.effectiveDate,
      cell: ({ effectiveDate }) => moment(effectiveDate).format("DD/MM/YYYY"),
    },
    {
      name: "Expiration Date",
      sortField: "expirationDate",
      sortable: true,
      selector: (row) => row.expirationDate,
      cell: ({ expirationDate }) => moment(expirationDate).format("DD/MM/YYYY"),
    },

    {
      name: "Status",
      sortable: true,

      sortField: "status",
      cell: (row) => (
        <Badge
          color={
            row.status.toLowerCase() === "valid"
              ? "success"
              : row.status.toLowerCase() === "draft"
              ? "warning"
              : "danger"
          }
          className="text-capitalize"
        >
          <span>{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Signature Date",
      sortField: "signatureDate",
      minWidth: "250px",
      sortable: true,
      selector: (row) => row.signatureDate,
      cell: ({ signatureDate }) => moment(signatureDate).format("DD/MM/YYYY"),
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
          >
            <Link
              to={`/hrm/contracts/update/${row._id}`}
              size="sm"
              color="flat-primary"
            >
              <Edit className="font-medium-2" />
            </Link>
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>

          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Link
              to={`/hrm/contacts/update/${row._id}`}
              size="sm"
              color="flat-primary"
            >
              <Eye className="font-medium-2 " />
            </Link>
            <UncontrolledTooltip placement="top" target="delete-btn">
              View
            </UncontrolledTooltip>
          </Button>

          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Contracts"
        buttonLabel={"New Contract"}
        addButtonClick={() => navigate("/hrm/contracts/new")}
        fetchData={useGetContractsQuery}
        searchFields={["name", "description"]}
        populate={[
          {
            path: "staff",
            dir: "hrm/staffs",
            select: "name ",
          },
          {
            path: "contractType",
            dir: "hrm/contractTypes",
            select: "name",
          },
        ]}
      />
    </div>
  );
};

export default Contracts;
