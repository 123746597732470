import moment from "moment";
import { DefaultRoute } from "../router/routes";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { useContext } from "react";
import { AbilityContext, ability, updateAbility } from "./context/Can";
import general from "../navigation/general";
import { useNavigate } from "react-router-dom";
import vertical from "../navigation/vertical";
import { subject } from "@casl/ability";
import { CurrentBuildingContext } from "../contexts/currentBuilding";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
// export const BASE_URL = "https://server.rukun.so/api/v1";
export const BASE_URL = "http://10:500/api/v1";

export const getToken = () => JSON.parse(localStorage.getItem("accessToken"));

export const fetchBaseQueryFunction = () =>
  fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

export const isBuilding = (id) => {
  const ObjectIdRegex = /^[0-9a-fA-F]{24}$/;
  return ObjectIdRegex.test(id);
};

export const getFirstAvailableRoute = (ability, view, type = "") => {
  if (type === "building") {
    const firstAvailableRoute = vertical
      .filter((item) => !item.header && !item?.view?.includes("!default"))
      .find((nav) =>
        ability.can("read", subject(nav.resource, { view: view }))
      );

    return firstAvailableRoute?.navLink ?? "/dashboard";
  }

  const firstAvailableRoute = vertical
    .filter((item) => !item.header && item?.view?.includes(view))
    .find((nav) => ability.can("read", subject(nav.resource, { view: view })));

  return firstAvailableRoute?.navLink
    ? `${firstAvailableRoute?.navLink}?view=${view}`
    : `/dashboard?view=${view}`;
};

const getUserMenus = (userRole, view) => {
  return vertical
    .filter((item) => !item.header && item?.view?.includes(view))
    .filter((nav) =>
      userRole.ability.some(
        (a) =>
          a.subject === nav.resource &&
          a.action.includes("read") &&
          a?.condition?.view === view
      )
    );
};

export const getHomeRouteForLoggedInUser = (userRole, ability) => {
  if (!ability) return "/dashboard?view=company";

  // const userGeneral = general.filter((role) => ability.can("read", role.id));

  // if (userGeneral.length == 1) {
  //   const view =
  //     userGeneral[0].id == "properties" ? "company" : userGeneral[0].id;

  //   if (view === "company") {
  //     const userMenus = getUserMenus(userRole, view);

  //     if (!userMenus.length) {
  //       return "/buildings?view=company";
  //     }

  //     return getFirstAvailableRoute(ability, view);
  //   }

  //   return getFirstAvailableRoute(ability, view);
  // }

  const userMenus = vertical.filter(
    (item) =>
      !item.header &&
      item?.view?.includes("company") &&
      ability.can("read", subject(item.resource, { view: "company" }))
  );

  if (!userMenus.length) {
    return "/buildings?view=company";
  }

  return getFirstAvailableRoute(ability, "company");
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function convertDaysToMonthsAndYears(days) {
  const duration = moment.duration(days, "days");
  const months = Math.floor(duration.asMonths());
  const years = Math.floor(duration.asYears());

  if (years > 0) {
    return `${years} Years`;
  }
  if (months > 0) {
    return `${months} Months`;
  }

  return `${days} Days`;
}

export const formatCurrency = (currency) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    currency
  );

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}
// ** Downloads CSV
export function downloadCSV(array, filename = "export.csv") {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const getDateRangeByFilter = (filter) => {
  let start, end;

  switch (filter) {
    case "today":
      start = moment().startOf("day");
      end = moment().endOf("day");
      break;
    case "this week":
      start = moment().startOf("week");
      end = moment().endOf("week");
      break;
    case "this month":
      start = moment().startOf("month");
      end = moment().endOf("month");
      break;
    case "last month":
      start = moment().subtract(1, "months").startOf("month");
      end = moment().subtract(1, "months").endOf("month");
      break;
    case "this year":
      start = moment().startOf("year");
      end = moment().endOf("year");
      break;
    case "last year":
      start = moment().subtract(1, "years").startOf("year");
      end = moment().subtract(1, "years").endOf("year");
      break;
    default:
      console.error("Invalid value");
      return;
  }

  return { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") };
};

export const formatTreeNodes = (accounts = []) => {
  let acc = [];
  accounts
    .filter((acc) => acc.parentAccount == null)
    .forEach((account) => {
      let accChildren = getSubAccounts(account._id, accounts);
      acc.push({
        key: account._id,
        label: account.name,
        data: account.name,
        selectable: !accChildren.length,
        children: accChildren,
      });
    });

  return acc;
};

const getSubAccounts = (parent, accounts) => {
  return accounts
    .filter((account) => {
      if (account.parentAccount == parent && account.type == parent) {
        return false;
      } else if (account.parentAccount != null && account.type == parent) {
        return false;
      } else if (account.parentAccount == parent || account.type == parent) {
        return true;
      }
    })
    .map((account) => {
      let accChildren = getSubAccounts(account._id, accounts);
      return {
        key: account._id,
        label: account.name,
        data: account.name,
        selectable: !accChildren.length,
        children: accChildren,
      };
    });
};
