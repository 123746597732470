// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllData = createAsyncThunk(
  "users/getAllData",
  async (_, thunkApi) => {
    try {
      let bodyContent = {
        options: {
          query: {},
          populate: [
            {
              path: "role",
              dir: "roles",
            },
            {
              path: "company",
              dir: "companies",
            },
          ],
        },
      };

      const response = await axios.get("/users", {
        params: bodyContent,
      });

      const docs = response.data?.data?.docs || [];
      return docs;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkApi.rejectWithValue(err.response.data.message);
    }
  }
);

export const getData = createAsyncThunk("users/getData", async (params) => {
  const response = await axios.get("/users", params);
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total,
  };
});

export const getUser = createAsyncThunk("users/getUser", async (id) => {
  const response = await axios.get(`/users/${id}`);
  return response.data.data;
});

export const getUserSummery = () => {};

export const addUser = createAsyncThunk(
  "users/addUser",
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post("/users", user);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ id, user }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(`/users/${id}`, user);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUser = getState()?.authentication?.userData;

      console.log(id == currentUser._id);
      if (id == currentUser._id) {
        return rejectWithValue("Cannot delete your Logged In User");
      }

      const response = await axios.delete(`/users/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserFigures = createAsyncThunk(
  "users/getUSersFigures",
  async (_, { rejectWithValue, getState }) => {
    try {
      return await axios.get(`/users/figures`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false,
    selectedUser: null,
    figuresLoading: false,
    figures: { total: 0, paid: 0, unpaid: 0 },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload;
        state.loading = false;
      })
      .addCase(getAllData.rejected, (state, action) => {
        console.log("ERROR", action.payload);
        state.loading = false;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedUser = null;
        state.allData.push(payload);
        // state.data.push(payload);
      })

      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.data = state.data.map((d) => {
        //   if (d._id == payload._id) {
        //     return payload;
        //   }
        //   return d;
        // });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedUser = null;
      })
      .addCase(deleteUser.fulfilled, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
        const deletedUser = payload;
        state.allData = state.allData.filter((d) => d._id !== deletedUser);
      })
      .addCase(getUserFigures.pending, (state, action) => {
        state.figuresLoading = true;
        state.error = null;
      })
      .addCase(getUserFigures.fulfilled, (state, action) => {
        const { figures } = action.payload?.data;
        if (figures) state.figures = figures;
        state.figuresLoading = false;
        state.error = null;
      })
      .addCase(getUserFigures.rejected, (state, action) => {
        state.figuresLoading = false;
        state.error = null;
      });
  },
});

export default usersSlice.reducer;
