import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { useDispatch } from "react-redux";

import moment from "moment";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../../common/utils/Error";
import HandleDispatch from "../../../../common/utils/HandledDispatch";
import { useGetDepartmentsListQuery } from "../../../../redux/departments";
import { useGetJobsListQuery } from "../../../../redux/jobs";
import { useGetShiftsListQuery } from "../../../../redux/shifts";
import { useUpdateStaffMutation } from "../../../../redux/staff";

const Detail = ({ data }) => {
  //* Data Fetching
  const { data: shifts } = useGetShiftsListQuery();
  const { data: jobs } = useGetJobsListQuery();
  const { data: departments } = useGetDepartmentsListQuery();

  const jobsOptions = jobs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const shiftsOptions = shifts?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const departmentsOptions = departments?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const [updateStaff, { isLoading }] = useUpdateStaffMutation();

  const defaultValues = {
    id: data?.staffInfo?._id || "",
    name: data?.staffInfo?.name || "",
    gender: data?.staffInfo?.gender || "",
    dateOfBirth:
      moment(data?.staffInfo?.dateOfBirth).format("YYYY-MM-DD") || "",
    email: data?.staffInfo?.email || "",
    contacts: data?.staffInfo?.contacts || "",
    shift: data?.staffInfo?.shift || "",
    emergencyContactName: data?.staffInfo?.emergencyContactName || "",
    motherName: data?.staffInfo?.motherName || "",
    placeOfBirth: data?.staffInfo?.placeOfBirth || "",
    address: data?.staffInfo?.address || "",
    job: data?.staffInfo?.job || "",
    emergencyContact: data?.staffInfo?.emergencyContact || "",
    photo: data?.staffInfo?.photo || "",
    documents: data?.staffInfo?.documents || "",
    department: data?.staffInfo?.department || "",
    hireDate: moment(data?.staffInfo?.hireDate).format("YYYY-MM-DD") || "",
    user: data?.staffInfo?.user || "",
    status: data?.staffInfo?.status || "",
  };

  const {
    register,
    reset,
    getValues,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    try {
      const { data: resData, error } = await updateStaff({
        id: data.id,
        data: data,
      });

      if (error) {
        const errorMessage = ExtractError(error?.data);
        toast.error(errorMessage);
        return;
      }

      toast.success("Staff Updated Successfully");
    } catch (error) {
      ErrorHandler(error);
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">Staff's Detail</CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Row className="gy-2">
                <Col sm={12} md={12}>
                  <Label className="form-label" for="name">
                    Name
                  </Label>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="name"
                        placeholder="Name"
                        invalid={errors.name && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={12}>
                  <Label className="form-label" for="motherName">
                    Mother Name
                  </Label>
                  <Controller
                    name="motherName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="motherName"
                        placeholder="Mother Name"
                        invalid={errors.motherName && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.motherName && (
                    <FormFeedback>Please enter a valid Email</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="email"
                        placeholder="Email"
                        invalid={errors.email && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormFeedback>Please enter a valid Email</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="contacts">
                    Phone
                  </Label>
                  <Controller
                    name="contacts"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="contacts"
                        type="text"
                        placeholder="Phone"
                        invalid={errors.contacts && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.contacts && (
                    <FormFeedback>Please enter a valid Phone</FormFeedback>
                  )}
                </Col>{" "}
                <Col sm={12} md={6}>
                  <Label className="form-label" for="placeOfBirth">
                    Place Of Birth
                  </Label>
                  <Controller
                    name="placeOfBirth"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="placeOfBirth"
                        placeholder="Place Of Birth"
                        invalid={errors.placeOfBirth && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.placeOfBirth && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="dateOfBirth">
                    Date Of Birth
                  </Label>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="dateOfBirth"
                        type="date"
                        placeholder="Date Of Birth"
                        invalid={errors.dateOfBirth && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.dateOfBirth && (
                    <FormFeedback>Please enter a valid Date</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={12}>
                  <Label className="form-label" for="address">
                    Address
                  </Label>
                  <Controller
                    name="address"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="address"
                        type="text"
                        placeholder="Address"
                        invalid={errors.address && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.address && (
                    <FormFeedback>Please enter a valid Address</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="emergencyContactName">
                    Emergency Name
                  </Label>
                  <Controller
                    name="emergencyContactName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="emergencyContactName"
                        type="text"
                        placeholder="Emergency Name"
                        invalid={errors.emergencyContactName && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.emergencyContactName && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="emergencyContact">
                    Emergency Phone
                  </Label>
                  <Controller
                    name="emergencyContact"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="emergencyContact"
                        type="text"
                        placeholder="Emergency Phone"
                        invalid={errors.emergencyContact && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.emergencyContact && (
                    <FormFeedback>Please enter a valid Phone</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="job">
                    Select Job
                  </Label>
                  <Controller
                    name="job"
                    control={control}
                    render={({ field }) => (
                      <CreatableSelect
                        id="job"
                        className={`react-select ${
                          errors.job ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        aria-invalid={errors.job && true}
                        placeholder="Choose Job"
                        options={jobsOptions}
                        {...field}
                        value={jobsOptions?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selected) => field.onChange(selected.value)}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="shift">
                    Select Shift
                  </Label>
                  <Controller
                    name="shift"
                    control={control}
                    render={({ field }) => (
                      <CreatableSelect
                        id="shift"
                        className={`react-select ${
                          errors.shift ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        aria-invalid={errors.shift && true}
                        placeholder="Choose shift"
                        options={shiftsOptions}
                        {...field}
                        value={shiftsOptions?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selected) => field.onChange(selected.value)}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="department">
                    Select Department
                  </Label>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                      <CreatableSelect
                        id="department"
                        className={`react-select ${
                          errors.department ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        aria-invalid={errors.department && true}
                        placeholder="Choose department"
                        options={departmentsOptions}
                        {...field}
                        value={departmentsOptions?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selected) => field.onChange(selected.value)}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="hireDate">
                    Hire Date
                  </Label>
                  <Controller
                    name="hireDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="hireDate"
                        type="date"
                        placeholder="Hire Date"
                        invalid={errors.hireDate && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.hireDate && (
                    <FormFeedback>Please enter a valid Date</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="status">
                    Select Status
                  </Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <CreatableSelect
                        id="status"
                        className={`react-select ${
                          errors.status ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        aria-invalid={errors.status && true}
                        placeholder="Choose status"
                        options={statusOptions}
                        {...field}
                        value={statusOptions?.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selected) => field.onChange(selected.value)}
                      />
                    )}
                  />
                </Col>
              </Row>

              <div className="text-center mt-3 mb-1">
                <Button color="success" type="submit" disabled={isLoading}>
                  <Spinner
                    color="light"
                    size="sm"
                    className="me-1"
                    hidden={!isLoading}
                  />
                  {isLoading ? "Saving..." : "Save Changes"}
                </Button>
              </div>
            </CardBody>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Detail;
