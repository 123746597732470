import React from "react";
import Skeleton from "react-loading-skeleton";
import { Table } from "reactstrap";
const Loading = ({ cols }) => {
  return (
    <div className="m-2 w-100">
      <Table>
        <thead>
          <tr>
            {cols.map((col, index) => (
              <th key={index} style={{ width: `${col.width}` }}>
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill()
            .map((item, index) => (
              <tr key={index}>
                {cols.map((col, index) => (
                  <td key={index} style={{ width: `${col.width}` }}>
                    <Skeleton height={30} />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Loading;
