// ** React Imports
import { Fragment } from "react";

// ** Custom Components
import NavbarUser from "./NavbarUser";

// ** Third Party Components
import { Sun, Moon, Menu } from "react-feather";

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap";
import { useSelector } from "react-redux";
import IonIcon from "@reacticons/ionicons";
import { exitBuilding } from "../../../../redux/home";
import { CurrentBuildingContext } from "../../../../contexts/currentBuilding";
import { useContext } from "react";

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;

  const { currentBuilding } = useContext(CurrentBuildingContext);

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return (
        <Sun color="white" className="ficon" onClick={() => setSkin("light")} />
      );
    } else {
      return (
        <Moon color="white" className="ficon" onClick={() => setSkin("dark")} />
      );
    }
  };

  const handleExitBuilding = (e) => {
    e.preventDefault();
    dispatch(exitBuilding());
    navigate("/home");
  };

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <NavItem className="d-none d-lg-block">
          <NavLink className="nav-link-style">
            <ThemeToggler />
          </NavLink>
        </NavItem>
        {currentBuilding ? (
          <>
            <NavItem className="d-block ms-2">
              <h4 className="fw-bolderer mb-0 text-white">
                {" "}
                <IonIcon name="business-outline" className="me-1" />
                {currentBuilding.name}{" "}
              </h4>
            </NavItem>
            {/* <NavItem className="d-block ms-2">
        <IonIcon name="exit-outline" className="text-white" size="medium"/>
        </NavItem> */}
          </>
        ) : null}
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
