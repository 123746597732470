import moment from "moment";
import React, { useState } from "react";
import { Edit, Trash } from "react-feather";
import toast from "react-hot-toast";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomDataTable from "../../../common/CustomDataTable/index.jsx";
import AllocationModal from "./AllocationModal.jsx";
import {
  useGetAllocationsQuery,
  useDeleteAllocationMutation,
} from "../../../redux/allocation.js";
import { useGetWorkplaceQuery } from "../../../redux/workplace.js";
import { useGetStaffsQuery } from "../../../redux/staff.js";

const MySwal = withReactContent(Swal);

const Allocation = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteAllocation] = useDeleteAllocationMutation();

  // workplace
  const { data: workplaces } = useGetWorkplaceQuery({});

  // staff
  const { data: staffs } = useGetStaffsQuery({});

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Allocation ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await deleteAllocation(id);
        if (responseStatus) {
          toast.success("Allocation Has been Deleted.");
        }
      }
    });
  };

  const handleEditClick = (data) => {
    setSelectedRow(data);
    setIsModalVisible(true);
  };

  const columns = [
    {
      name: "Staff",
      sortField: "staff",
      minWidth: "370px",
      sortable: true,
      selector: (row) => row.staff?.name,
    },
    {
      name: "Workplace",
      sortField: "workplace",
      minWidth: "800px",
      sortable: true,
      selector: (row) => (
        <div className="text-truncate" style={{ minWidth: "500px" }}>
          {workplaces?.docs.map(
            (item) => item._id === row.workplace && item.address
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center permissions-actions">
          <Button
            id="update-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleEditClick(row)}
          >
            <Edit className="font-medium-2" />
            <UncontrolledTooltip placement="top" target="update-btn">
              Update
            </UncontrolledTooltip>
          </Button>
          <Button
            id="delete-btn"
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => handleConfirmDelete(row._id, row.name)}
          >
            <Trash className="font-medium-2 text-danger" />
            <UncontrolledTooltip placement="top" target="delete-btn">
              Delete
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <CustomDataTable
        columns={columns}
        title="Workplace Allocation"
        fetchData={useGetAllocationsQuery}
        query={{}}
        searchFields={["name"]}
        populate={[
          {
            path: "staff",
            dir: "hrm/staffs",
            select: "name email address department",
          },
          {
            path: "createdBy",
            dir: "users",
            select: "username name",
          },
        ]}
        buttonLabel={"New Allocation"}
        addButtonClick={() => setIsModalVisible(true)}
      />
      <AllocationModal
        selectedRow={selectedRow}
        show={isModalVisible}
        setShow={setIsModalVisible}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default Allocation;
