import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import StatsVertical from "@components/widgets/stats/StatsVertical";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import classNames from "classnames";

import { ThemeColors } from "@src/utility/context/ThemeColors";
import { useContext } from "react";
import {
  Building,
  CircleFill,
  EnvelopeAt,
  EnvelopeExclamation,
  Files,
  ReceiptCutoff,
} from "react-bootstrap-icons";
import RevenueReport from "./revenueReport";
import { formatCurrency } from "../../utility/Utils";
import Skeleton from "react-loading-skeleton";

const Loading = () => {
  return (
    <>
      <Row>
        <Col sm={12} md={8}>
          {" "}
          <Row>
            {/* Stats With Icons */}
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Building size={21} />}
                color="info"
                stats={<Spinner />}
                statTitle="Units"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Building size={21} />}
                color="success"
                stats={<Spinner />}
                statTitle="Available Units"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Building size={21} />}
                color="warning"
                stats={<Spinner />}
                statTitle="Booked Units"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Building size={21} />}
                color="danger"
                stats={<Spinner />}
                statTitle="Reserved Units"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Files size={21} />}
                color="info"
                stats={<Spinner />}
                statTitle="All Leases"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Files size={21} />}
                color="success"
                stats={<Spinner />}
                statTitle="Active Leases"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<Files size={21} />}
                color="danger"
                stats={<Spinner />}
                statTitle="Expired Leases"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<EnvelopeAt size={21} />}
                color="info"
                stats={<Spinner />}
                statTitle="All Rental Applications"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<EnvelopeExclamation size={21} />}
                color="danger"
                stats={<Spinner />}
                statTitle="Pending Applications"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="info"
                stats={<Spinner />}
                statTitle="Total Invoices"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="success"
                stats={<Spinner />}
                statTitle="Paid Invoices"
              />
            </Col>
            <Col md="4" sm="6">
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="danger"
                stats={<Spinner />}
                statTitle="Outstanding Invoices"
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <CardBody>
              <CardTitle className="mb-2">Rent Collection</CardTitle>
              <div className="d-flex gap-5 align-items-center">
                <div>
                  <div className="font-small-3">Today</div>
                  <h4 className="mb-2 mt-1 text-primary">
                    <Spinner />
                  </h4>
                </div>

                <div>
                  <div className="font-small-3">This Month</div>
                  <h4 className="mb-2 mt-1 text-primary">
                    <Spinner />
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col sm={12} md={12}>
              <Card>
                <CardHeader className="align-items-end">
                  <CardTitle tag="h4">Invoices</CardTitle>
                </CardHeader>
                <CardBody>
                  <Skeleton height={330} />
                </CardBody>
              </Card>
            </Col>

            <Col sm={12} md={12}>
              <Card>
                <CardHeader className="align-items-end">
                  <CardTitle tag="h4">Units</CardTitle>
                </CardHeader>
                <CardBody>
                  <Skeleton height={330} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Loading;
