import React from "react";

import { Col, Row, Spinner } from "reactstrap";

// ** Icons Imports
import { FileText } from "react-feather";

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal";
import { useGetStaffFiguresQuery } from "../../../redux/staff";

const Statistics = () => {
  const { data, isLoading } = useGetStaffFiguresQuery();
  return (
    <div>
      <Row>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="primary"
            statTitle="All Staff"
            icon={<FileText size={20} />}
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {data.figures.total} </h3>
              )
            }
          />
        </Col>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="success"
            statTitle="Active Staff"
            icon={<FileText size={20} />}
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {data.figures.active} </h3>
              )
            }
          />
        </Col>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="danger"
            statTitle="InActive Staff"
            icon={<FileText size={20} />}
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {data.figures.inactive} </h3>
              )
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
