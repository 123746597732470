// ** React Imports
import { Fragment, useEffect } from "react";

// ** Roles Components
import Table from "./components/Table";
import RoleCards from "./components/RoleCards";
import { useDispatch } from "react-redux";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { getAllRoles } from "../../redux/roles";
import { getAllBuildings } from "../../redux/building";
import { useSearchParams } from "react-router-dom";

const Roles = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const getRoles = async () => {
    await HandleDispatch(dispatch, getAllRoles());
  };
  const getBuildings = async () => {
    await HandleDispatch(
      dispatch,
      getAllBuildings({ appView: searchParams.get("view") })
    );
  };

  useEffect(() => {
    getRoles();
    getBuildings();
  }, []);

  return (
    <Fragment>
      <h3>Roles List</h3>
      <p className="mb-2">
        A role provides access to predefined menus and features depending on the
        assigned role to an administrator that can have access to what he needs.
      </p>

      <RoleCards />
    </Fragment>
  );
};

export default Roles;
