import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utility/Utils";
import { createQueryString } from "../utility/helper";

export const staffApi = createApi({
  reducerPath: "staffs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["staffs", "staffsList", "StaffFigures"],
  endpoints: (builder) => ({
    // Read
    getStaffs: builder.query({
      query: (params) => {
        const serializedParams = createQueryString(params);
        return `/hrm/staff?${serializedParams}`;
      },
      providesTags: ["staffs"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getStaffsList: builder.query({
      query: () => `/hrm/staff/list`,
      providesTags: ["staffsList"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    //summery
    getStaffFigures: builder.query({
      query: () => "/hrm/staff/figures",
      providesTags: ["StaffFigures"],
    }),

    //single staff
    getSingleStaff: builder.query({
      query: ({ id, params }) => {
        const serializedParams = createQueryString(params);
        return `/hrm/staff/${id}?${serializedParams}`;
      },
      transformResponse: (response) => {
        return response?.data;
      },
      keepUnusedDataFor: 0,
    }),

    //staff detail
    getStaffDetail: builder.query({
      query: (id) => `/hrm/staff/detail/${id}`,
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    // Create
    createStaff: builder.mutation({
      query: (data) => ({
        url: "/hrm/staff",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["staffs", "staffsList", "StaffFigures"],
    }),

    // Update
    updateStaff: builder.mutation({
      query: ({ data: staffs, id }) => ({
        url: `/hrm/staff/${id}`,
        method: "PATCH",
        body: staffs,
      }),
      invalidatesTags: ["staffs", "staffsList", "StaffFigures"],
    }),

    // Delete
    deleteStaff: builder.mutation({
      query: (id) => ({
        url: `/hrm/staff/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["staffs", "staffsList", "StaffFigures"],
    }),

    //summery
  }),
});

export const {
  useGetStaffsQuery,
  useGetStaffsListQuery,
  useGetStaffFiguresQuery,
  useGetStaffDetailQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
  useGetSingleStaffQuery,
} = staffApi;
